import React from 'react'
import {is} from "../../../../../../library/functions";

export const BlockImage = (spec, params, classes, styles) => {
    if (!spec) return null;

    let classNames = ['c-image'];
    if (is(classes)) classNames.push(...classes);

    let props = {};
    if (is(styles)) props['style'] = styles;

    return <img className={classNames.join(' ')} {...props} src={spec.photo_url} alt={spec.alt ?? ''}/>
}