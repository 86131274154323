import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import classes from "./Flow.module.scss";
import ReactFlow, {
    useNodesState,
    useEdgesState,
    useStoreApi,
    useReactFlow,
    ReactFlowProvider
} from 'react-flow-renderer';
import CustomEdge from "./components/Edge/Edge";
import Node from "./components/Node/Node";
import Note from "./components/Node/Note";
import FlowContext from "./FlowContext";
import {
    arrayMove,
    decrypt,
    deepCopyObject,
    downloadFileFromText,
    getUtcDateTime,
    getObjectFromStorage,
    setObjectToStorage,
    deepGet,
    getRandomInt,
    inArray,
    isObjectsEqual,
    objectLength,
    isQuotaExceededError,
} from "../../library/functions";
import {$, $$} from "../../library/dom";
import {
    addGraphEdges,
    addGraphActionToNode,
    changeGraphEdge,
    composeFlowFromGraph,
    composeNodeBaseObj,
    createGraphNodeAndReturnIds,
    deleteGraphElements,
    copyGraphElements,
    getNodeTypes,
    updateGraphNode,
    updateGraphNodesCoords,
    findIndexByLocalId,
    createGraphEffect,
    getElementByLocalId,
    removeElementsFromArray,
    addGraphEventToNode,
    addGraphConditionToEffect,
    composePort,
    getElementByFlowId,
    findRelatedNodeData,
    createConditionEffect, isNodeIgnored,
} from "./library/flowFunctions";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import {dotenv, routes} from "../../config/config";
import {useTranslation} from "react-i18next";
import AddNodeMenu from "./components/Desktop/AddNodeMenu/AddNodeMenu";
import ButtonsZoom from "./components/Desktop/ButtonsZoom/ButtonsZoom";
import ButtonsPublishExit from "./components/Desktop/ButtonsPublishExit/ButtonsPublishExit";
import ButtonsAddControl from "./components/Desktop/ButtonsAddControl/ButtonsAddControl";
import {useHotkeys} from "react-hotkeys-hook";
import Preloader from "../System/Preloader";
import {setLoadingStatus} from "../../redux/reducers/StatusReducer";
import {second_graph} from "../../schemas/backend/second_graph";
import {notice} from "../../library/notice";
import {Io, upd} from "../../library/immutable";
import {copyToClipboard, readFromClipboard} from "../../library/clipboard";
import {condition_jump_effect, graph_jump_effect} from "../../schemas/frontend/effects";
import ControlButtons from "./components/ControlPanel/components/ControlButtons/ControlButtons";
import {changeGraphStatus, patchGraphContainer} from "../../redux/reducers/GraphReducer";
import {specialEventTypes} from "../../schemas/frontend/events";
import {popularConditionTypes} from "../../schemas/frontend/conditions";
import InputModal from "../../components/Modal/InputModal";

let graphParams = {
    onload: false,
    nextLocalId: 1,
    nextStepId: 1,
    connecting: 0,
    reconnecting: 0,
    localUpdatesCount: 0,
    portSelectionForClick: false,
    portSelectionForSelect: false,
    doNotRerenderFlow: false,
    connectingSource: null,
    mouse: {
        x: 160,
        y: 160,
    },
    position: {
        x: 0,
        y: 0,
        zoom: 1,
    }
};

// const connectionLineStyle = {stroke: '#ccc'};

const storageName = dotenv.app_name + ':graphs';
const edgeTypes = {
    smooth: CustomEdge,
    // smoothstep: // TODO: is it possible to add this also?
}
const nodeTypes = {
    event: Node,
    action: Node,
    message: Node,
    timer: Node,
    condition: Node,
    note: Note,
};

const GraphFlow = ({graph, project, admin}) => {
    // common hooks
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // react flow states
    const {setViewport} = useReactFlow();
    const flowStore = useStoreApi();
    const flowRef = useRef(null);
    const [flowNodes, setFlowNodes, onFlowNodesChange] = useNodesState([]);
    const [flowEdges, setFlowEdges, onFlowEdgesChange] = useEdgesState([]);
    const [blockDraggable, setBlockDraggable] = useState(true);
    const [edgesParams, setEdgesParams] = useState({
        animated: false,
        type: 'smooth', // values: smooth (custom), default, straight, step, smoothstep,
    });

    // graph flow states
    const [nodesState, setNodesState] = useState([]);
    const [edgesState, setEdgesState] = useState([]);
    const [chosenNode, setChosenNode] = useState({});
    const [isVisiblePasteModal, setVisiblePasteModal] = useState(false);
    const [selectionMode, setSelectionMode] = useState(false);
    const [movingMode, setMovingMode] = useState(false);
    const [isPanelFixed, onPanelFixedChange] = useState(false);
    const [creatingMenuProps, setCreatingMenuProps] = useState({isVisible: false, x: 0, y: 0});
    const [selected, setSelectedElements] = useState({nodes: [], edges: [], ports: []});
    const [autoSave, setAutoSave] = useState('');
    const [controlPanel, setControlPanel] = useState('default');
    const [chosenContainerId, setChosenContainerId] = useState(0);
    const [graphUpdateIndex, setGraphUpdateIndex] = useState(0);

    // window vars
    window.graph = graphParams;
    window.flow = {nodes: flowNodes, edges: flowEdges};
    window.nodes = nodesState;
    window.edges = edgesState;

    // store vars
    const graphItem = graph.item;
    const graphList = graph.list;

    // init graph
    useLayoutEffect(() => {
        // restore default on another scheme
        graphParams.nextStepId = 1;
        let redrawnStatus = false;

        // set vars
        let graphItemLogic = graphItem.logic;

        // check local updates
        const savedGraphs = getObjectFromStorage(storageName);
        const localSave = deepGet(savedGraphs, graphItem.project_id + '.' + graphItem.id + '.0');

        let localSaveMoment = localSave ? localSave.updated_at : 0;
        if (!localSaveMoment) localSaveMoment = graphItem.updated_at;

        if (localSave && localSaveMoment > graphItem.updated_at && !isObjectsEqual(graphItemLogic, localSave.graph.logic)) {
            // restore local logic if unsaved changes found
            console.log('localSave loaded', localSave.updated_at, '>', graphItem.updated_at);
            // console.log('server graph', graphItemLogic);
            // console.log('local graph', localSave.graph.logic);

            graphItemLogic = localSave.graph.logic;
            redrawnStatus = true; // set edited (not saved) status
        }

        if (graphItemLogic && objectLength(graphItemLogic)) {
            if (graphItemLogic.localIdNext) graphParams.nextLocalId = graphItemLogic.localIdNext; // Deprecated
            else if (graphItemLogic.nextLocalId) graphParams.nextLocalId = graphItemLogic.nextLocalId;

            // for UI numbers
            if (graphItemLogic.nextStepId) graphParams.nextStepId = graphItemLogic.nextStepId;

            // if (graphItemLogic.ui) {
            //     if (graphItemLogic.ui.position) {
            //         graphParams.position = {...graphItemLogic.ui.position};
            //         // console.log('setViewport', graphParams.position)
            //         // setViewport(graphParams.position);
            //     }
            //
            //     // I think it is a bad idea ↓
            //     // if (graphItemLogic.ui.chosenNodeId && graphItemLogic.nodes.length) {
            //     //     const chosenNodeItem = getElementByLocalId(graphItemLogic.nodes, graphItemLogic.ui.chosenNodeId)
            //     //     console.log('===== Restore chosenNodeItem ====', chosenNodeItem)
            //     //     setChosenNode(chosenNodeItem);
            //     // }
            // }

            setEdgesState(graphItemLogic.edges); // first: try to avoid rerender
            setNodesState(graphItemLogic.nodes);

            if (!redrawnStatus) {
                // && graphItem.updated_at > localSaveMoment
                setAutoSave('local:open');
            }
        }

        // reset edited status on new graph opening
        dispatch(changeGraphStatus({edited: false, redrawn: redrawnStatus}));
    }, [graphItem.logic]);

    // store graph changes to state
    useEffect(() => {
        // do not update Flow when just cPanel content changed
        if (!graphParams.doNotRerenderFlow) {
            const startTime = performance.now();
            const graph = composeFlowFromGraph(nodesState, edgesState, selected, edgesParams);
            const finishTime = performance.now();

            // log performance
            console.log('Graph composed within', (finishTime - startTime), 'ms');

            if (graph) {
                setFlowNodes(graph.nodes);
                setFlowEdges(graph.edges);

                // reselect selected elements - variant 2
                // onSelectionChange(selected);
            } else {
                notice.error(t('graph.flow.error.render'))
            }
        }
        // else console.log('Flow useEffect condition', !graphParams.doNotRerenderFlow);

        // set global vars
        graphParams.doNotRerenderFlow = false; // console.log('doNotRerenderFlow', false);

        // save if allowed
        if (autoSave) saveGraph();

    }, [nodesState, edgesState]);

    const saveGraph = (isManual = false) => {
        // autoSave values: db:manual, db:auto, local: ... [activity type name]
        /* activity names:
            * open - graph just opened (for canceling fist operation)
            * ...
        */

        const autoSaveMode = autoSave;
        if (autoSaveMode === 'db:manual') isManual = true;
        const autoSaveParams = autoSaveMode.split(':');
        const autoSaveStore = autoSaveParams[0];
        const data = composeGraphDataForSave();
        // if (!isManual && isObjectsEqual(graphItem.logic, data.logic)) return true;

        // reset to defaults = turn off
        setAutoSave('');

        if (isManual) {
            dispatch({type: 'updateGraph', admin, data});
            console.log('Saved Graph:', data.logic);
        } else if (autoSaveMode === 'db:auto') {
            console.log('TODO: Autosave Graph:', data);
        } else if (autoSaveStore === 'local') {
            console.info('autoSave', autoSaveMode);

            const autoSaveAction = autoSaveParams[1] ? autoSaveParams[1] : 'auto';
            const graphObject = {action: autoSaveAction, updated_at: getUtcDateTime(), graph: data}
            let allGraphs = {[graphItem.project_id]: {[graphItem.id]: [graphObject]}} // {[projectId]: {[graphId]: [graphObject]}}
            let savedGraphs = getObjectFromStorage(storageName);
            if (savedGraphs) {
                allGraphs = savedGraphs;
                if (!allGraphs[graphItem.project_id]) allGraphs[graphItem.project_id] = {};
                if (!allGraphs[graphItem.project_id][graphItem.id]) allGraphs[graphItem.project_id][graphItem.id] = [];
                const graphUpdates = allGraphs[graphItem.project_id][graphItem.id].slice(0, 10); // not more 12 items in saved array
                graphParams.localUpdatesCount = graphUpdates.length; // save count for buttons

                const lastUpdate = graphUpdates[0]; // do not save already saved data
                if (lastUpdate && isObjectsEqual(graphObject.graph.logic, lastUpdate.graph.logic)) return true;

                // add new update version to storage
                graphUpdates.unshift(graphObject);
                allGraphs[graphItem.project_id][graphItem.id] = graphUpdates;
            }

            // console.log('Autosave Graph To LocalStorage:', autoSaveMode, nodesState, allGraphs[graphItem.project_id][graphItem.id]);
            try {
                setObjectToStorage(storageName, allGraphs);
            } catch (err) {
                if (isQuotaExceededError(err)) {
                    clearLocalUpdates(false);
                } else {
                    console.error('Error saving to localStorage:', err);
                }
            }

            setGraphUpdateIndex(-1);
        }

        if (isManual) notice.success(t('graph.item.saved'), 1);
        return true;
    }

    const createNewGraph = () => {
        let nextGraphNum = graphList.length + 1;

        const data = {
            project_id: graphItem.project_id,
            folder_id: graphItem.folder_id,
            title: t('graph.item.title') + ' ' + nextGraphNum,
            icon_name: 'square-terminal',
            logic: second_graph,
            is_on: false,
        }
        dispatch({type: 'createGraph', admin, data});
    }

    const composeGraphDataForSave = () => {
        return {
            id: graphItem.id,
            project_id: graphItem.project_id,
            logic: {
                nextLocalId: graphParams.nextLocalId,
                nextStepId: graphParams.nextStepId,
                nodes: nodesState,
                edges: edgesState,
                ui: {
                    position: graphParams.position,
                    chosenNodeId: chosenNode && chosenNode.localId ? chosenNode.localId : 0,
                }
            },
        };
    }

    const newLocalId = () => {
        const localId = graphParams.nextLocalId;
        graphParams.nextLocalId = localId + 1;
        // console.log('new local ID:', localId, 'next local ID:', graphParams.nextLocalId);
        return localId;
    }

    const newStepId = () => {
        const stepId = graphParams.nextStepId;
        graphParams.nextStepId = stepId + 1;
        return stepId;
    }

    const getChosenNode = () => {
        return getElementByLocalId(nodesState, chosenNode.localId)
    }

    // ============= selection ============

    const onSelectionChange = ({nodes, edges}) => {
        // console.log('onSelectionChange', {nodes, edges});
        const isMultiSelection = flowStore.getState().multiSelectionActive || flowStore.getState().userSelectionActive;
        // console.log('isMultiSelection', isMultiSelection);

        // cleanup nodes selection styles
        const domNodes = $$('.react-flow__node');
        domNodes.forEach(domNode => {
            domNode.setAttribute('selected', 'false');
        });

        // store setSelectedElements to state
        setSelectedElements({...selected, nodes, edges});

        // set selection status style for selected nodes
        if (nodes.length && (isMultiSelection || nodes.length > 1)) {
            // react-flow__node selected
            for (const node of nodes) {
                const domNode = $('[data-id="' + node.id + '"]');
                domNode.setAttribute('selected', 'true');
            }
        }
        // set chosen node if selected just one element
        else if (nodesState.length > 0 && nodes.length === 1) {
            // console.log('graphParams.portSelectionForSelect', graphParams.portSelectionForSelect)
            if (!graphParams.portSelectionForSelect) {
                const chosenNodeId = nodes[0] ? nodes[0].id : 0;
                const chosenNodeItem = getElementByLocalId(nodesState, chosenNodeId);
                setChosenNode(chosenNodeItem);
                setControlPanel('default');
                // console.log('chosenNode onSelectionChange', chosenNodeItem.localId);
            } else {
                setSelected('nodes', null, []);
            }
        } else {
            // close control panel
            setChosenNode({});
            setControlPanel('default');
        }
        // reset global vars
        // console.log('portSelectionForSelect', graphParams.portSelectionForSelect);
        graphParams.portSelectionForSelect = false;
    }

    // run resetSelectedElements on multiSelect Button Second Click
    useEffect(() => {
        // This is the only correct working way
        flowStore.setState({multiSelectionActive: selectionMode});

        if (!selectionMode) {
            flowStore.getState().resetSelectedElements();
        }
        //eslint-disable-next-line
    }, [selectionMode]);

    const selectAllElements = () => {
        // console.log('selectAllElements');
        setSelectionMode(true);
        flowStore.setState({multiSelectionActive: true});

        const selectedNodesIds = flowNodes.map((node) => node.id);
        const selectedEdgesIds = flowEdges.map((edge) => edge.id);

        // console.log('selectedNodesIds', selectedNodesIds);
        // console.log('selectedEdgesIds', selectedEdgesIds);

        const flowState = flowStore.getState();
        flowState.addSelectedEdges(selectedEdgesIds);
        flowState.addSelectedNodes(selectedNodesIds);
    }

    const resetSelectedElements = () => {
        setChosenNode({});
        setSelectionMode(false);
        flowStore.getState().resetSelectedElements();
    }

    const selectNodesByIds = (ids = ['0']) => {
        const flowState = flowStore.getState();
        flowState.addSelectedNodes(ids);
    }

    const selectEdgesByIds = (ids = ['0']) => {
        const flowState = flowStore.getState();
        flowState.addSelectedEdges(ids);
    }

    const setSelected = (type, element, elementList = null) => {
        let idParam = 'id';
        if (type === 'ports') idParam = 'handleId';

        let selectedElements = elementList;
        if (elementList === null) {
            selectedElements = [...selected[type]];
            const existedElementIndex = selectedElements.findIndex(port => port[idParam] === element[idParam]);
            if (existedElementIndex >= 0) {
                selectedElements.splice(existedElementIndex, 1);
            } else {
                selectedElements.push(element);
            }
        }

        // console.log('==== setSelected ports ====', selectedElements);
        setSelectedElements({...selected, [type]: selectedElements});
    }

    // ============= nodes ============

    const onNodeClick = (e, node) => {
        // console.log('onNodeClick');
        const isMultiSelection = flowStore.getState().multiSelectionActive || flowStore.getState().userSelectionActive;

        // for clicking on already selected node // console.log('graphParams.portSelectionForClick CHECK', graphParams.portSelectionForClick);
        if (!isMultiSelection && selected.nodes.length === 1 && chosenNode.localId !== parseInt(node.id) && !graphParams.portSelectionForClick) {
            // console.log('setChosenNode onNodeClick', node.id);
            const chosenNodeItem = getElementByLocalId(nodesState, node.id);
            setChosenNode(chosenNodeItem);
        }

        graphParams.portSelectionForClick = false;
        // console.log('portSelectionForClick', graphParams.portSelectionForClick);
    }

    const onGraphNodeUpdate = (node, ui = {}) => {
        const updatedNode = updateGraphNode(node, ui, nodesState, setNodesState, setChosenNode);
        // set new save button status - REDRAWN
        setRedrawnStatus(updatedNode, true, 'Node was not updated');
        // console.log('updatedNode', updatedNode);
    }

    const updateGraphNodeText = (localId, text) => {
        setAutoSave('local:node_renamed');
        onGraphNodeUpdate({localId, text});
    }

    const onGraphNodesDuplicate = () => {
        // console.log('onGraphNodesDuplicate', selected);
        const sel = {...selected};
        if (sel.nodes.length) {
            setAutoSave('local:nodes_cloned');

            setSelectedElements({nodes: [], edges: [], ports: []}); // avoid automatic reselection
            const result = copyGraphElements(sel.nodes, sel.edges, nodesState, setNodesState, edgesState, setEdgesState, newLocalId, newStepId);

            // set new nodes
            const clonedNodes = result.nodes.map(item => {
                return {id: String(item.localId)}
            });

            // set new edges
            const clonedEdges = result.edges.map(item => {
                return {id: String(item.localId)}
            });

            // reselect new elements
            setSelectedElements({nodes: clonedNodes, edges: clonedEdges, ports: sel.ports});

            // set new save button status - REDRAWN
            setRedrawnStatus(clonedNodes.length || clonedEdges.length, true, 'Nodes was not cloned');

            // console.log('Duplicating result', result);
        } else notice.warning(t('graph.flow.error.duplicate.zero_nodes'));
    }

    const onGraphCopy = () => {
        let copiedData = {
            nodes: [],
            edges: [],
        };
        for (const flowNode of selected.nodes) {
            const nodeIndex = findIndexByLocalId(nodesState, flowNode.id);
            if (nodeIndex < 0 || !nodesState[nodeIndex]) continue
            // console.log('nodesState[nodeIndex]', nodeIndex, nodesState[nodeIndex], nodesState)
            const node = deepCopyObject(nodesState[nodeIndex]);
            copiedData.nodes.push(node);

            if (node.ui.related && node.ui.related.length) {
                for (const relatedNodeInfo of node.ui.related) {
                    // add related nodes to copy loop (for localId update)
                    let relatedNodeIndex = findIndexByLocalId(nodesState, relatedNodeInfo.nodeLocalId);
                    // console.log('relatedNodeIndex', relatedNodeIndex, relatedNodeInfo)
                    if (relatedNodeIndex < 0) continue;

                    const relatedNode = deepCopyObject(nodesState[relatedNodeIndex])
                    copiedData.nodes.push(relatedNode);

                    // add related edges
                    const relatedEdge = edgesState.find(e => e.srcNode === node.localId && e.dstNode === relatedNode.localId)
                    if (relatedEdge) copiedData.edges.push(relatedEdge);
                }
            }
        }
        for (const flowEdge of selected.edges) {
            const edgeIndex = findIndexByLocalId(edgesState, flowEdge.id);
            // console.log('edgeIndex', edgeIndex, flowEdge.id)
            if (edgeIndex >= 0) {
                const edge = deepCopyObject(edgesState[edgeIndex]);
                copiedData.edges.push(edge);
            }
        }
        // console.log('onGraphCopy', copiedData);
        const copiedDataText = JSON.stringify(copiedData);
        copyToClipboard(copiedDataText);
    }

    const insertCopiedData = (copiedDataText) => {
        const copiedData = JSON.parse(copiedDataText);
        if (copiedData && typeof copiedData === 'object' && copiedData['nodes']) {
            // console.log('onGraphPaste', copiedData);
            const result = copyGraphElements(
                copiedData.nodes, copiedData.edges,
                nodesState, setNodesState,
                edgesState, setEdgesState,
                newLocalId, newStepId
            );
            // console.log('Paste result', result);

            // set new save button status - REDRAWN
            setRedrawnStatus(result.nodes.length || result.edges.length, true, 'Elements was not pasted');
        }
    }

    const onGraphPaste = () => {
        try {
            readFromClipboard((copiedDataText) => {
                insertCopiedData(copiedDataText);
            });
        } catch (e) {
            setVisiblePasteModal(true);
            // notice.error('Firefox browser is not supported');
        }
    }

    // delete nodes (and edges) by graph button
    const onGraphElementsDelete = () => {
        // console.log('onGraphElementsDelete', selected);
        setAutoSave('local:elements_deleted');
        let delNodesIndexes = [], delEdgesIndexes = [];
        let deletedEdges = [];
        if (selected.nodes.length) {
            delNodesIndexes = deleteGraphElements(selected.nodes, nodesState, setNodesState);

            for (const deletedNode of selected.nodes) {
                // also delete connected edges
                const deletedEdgesTemp = flowEdges.filter(ed => inArray(deletedNode.id, [ed.source, ed.target]));
                if (deletedEdgesTemp.length) deletedEdges = [...deletedEdges, ...deletedEdgesTemp];

                // delete related edges to
                if (deletedNode.data && deletedNode.data.related && deletedNode.data.related.length) {
                    for (const related of deletedNode.data.related) {
                        // skip for not node
                        if (!related.nodeLocalId) continue
                        // add all related node GRAPH edges
                        const relatedEdges = edgesState.filter(ed => inArray(related.nodeLocalId, [ed.dstNode, ed.srcNode]));
                        if (relatedEdges.length) deletedEdges = [...deletedEdges, ...relatedEdges]
                    }
                }
            }
            if (selected.edges.length) {
                for (const selectedEdge of selected.edges) {
                    const existedEdgeIndex = deletedEdges.findIndex(ed => ed.id === selectedEdge.id);
                    if (existedEdgeIndex < 0) {
                        deletedEdges.push(selectedEdge);
                    }
                }
            }
        } else deletedEdges = selected.edges;

        //srcNode dstNode
        if (deletedEdges.length) delEdgesIndexes = deleteGraphElements(deletedEdges, edgesState, setEdgesState);

        // set new save button status - REDRAWN
        setRedrawnStatus(delNodesIndexes.length || delEdgesIndexes.length, true, 'Elements was not deleted');
    }

    // delete by flow internal hotkey
    const onNodesDelete = (nodes) => {
        // console.log('onNodesDelete', nodes);
        setAutoSave('local:nodes_deleted');

        // check related edges
        let relatedEdges = [];
        // loop FLOW nodes
        for (const node of nodes) {
            if (node.data && node.data.related && node.data.related.length) {
                for (const related of node.data.related) {
                    // skip for not node
                    if (!related.nodeLocalId) continue
                    // add all related node FLOW edges
                    const edges = edgesState.filter(ed => inArray(related.nodeLocalId, [ed.dstNode, ed.srcNode]));
                    if (edges.length) relatedEdges = [...relatedEdges, ...edges]
                }
            }
        }
        // delete related edges
        if (relatedEdges.length) {
            // console.log('delete related edges', relatedEdges)
            deleteGraphElements(relatedEdges, edgesState, setEdgesState);
        }

        const deletedIndexes = deleteGraphElements(nodes, nodesState, setNodesState);
        // set new save button status - REDRAWN
        setRedrawnStatus(deletedIndexes, true, 'Nodes was not deleted');
    }

    const onNodeDragStop = (event, node) => {
        // turn off flow updating
        graphParams.doNotRerenderFlow = true;

        // console.log('onNodeDragStop', node.id);
        if (selected.nodes.length > 1) updateGraphNodesCoords(selected.nodes, nodesState, setNodesState, flowNodes);
        else updateGraphNodesCoords([node], nodesState, setNodesState);

        // set new save button status - REDRAWN
        // setRedrawnStatus(true, true, 'Node was not moved');
    }

    const onSelectionDragStop = (event, nodes) => {
        // turn off flow updating
        graphParams.doNotRerenderFlow = true;

        // console.log('onSelectionDragStop', nodes);
        updateGraphNodesCoords(nodes, nodesState, setNodesState);

        // set new save button status - REDRAWN
        // setRedrawnStatus(true, true, 'Nodes was not moved');
    }

    const onGraphNodeIgnoreChange = () => {
        // console.log('onGraphNodeIgnoreChange', selected);
        let nodes = [...nodesState];

        for (const selectedNode of selected.nodes) {
            const nodeIndex = findIndexByLocalId(nodes, selectedNode.id);
            if (nodeIndex < 0) continue;

            let ignored = false;
            let node = deepCopyObject(nodes[nodeIndex]);
            if (!node.effects) node['effects'] = [];

            if (isNodeIgnored(node)) ignored = true;
            node['ignore'] = !ignored;

            if (node.type !== 'event') {
                if (ignored) {
                    node.effects.shift();
                } else {
                    const defaultOutPort = node.ports.find(port => port.name === 'defaultOutput');
                    const newEffect = createGraphEffect(graph_jump_effect.type, {
                        localIds: [defaultOutPort.localId],
                        type: 'ignore',
                        ignore_next: true,
                    }, newLocalId);
                    node.effects = [newEffect, ...node.effects];
                }
            }

            // store result to state
            nodes[nodeIndex] = node;
            setChosenNode(node)
        }
        setNodesState(nodes);

        // set new save button status - REDRAWN
        setRedrawnStatus(true, true, 'Node was not changed');
    }

    const onNodeContextMenu = (event, node) => {
        console.log('onNodeContextMenu node:', node);
    }

    const onNodeDoubleClick = (event, node) => {
        console.log('onNodeDoubleClick node:', node);
    }

    const onClickLeftButtons = (e, type) => {
        if (type) {
            createNewGraphNode(type, e.clientX + getRandomInt(150, 250), e.clientY - getRandomInt(50, 150));
        }
    }

    const onDrop = (e) => {
        e.preventDefault();
        const type = e.dataTransfer.getData("Type");
        // console.log('onDrop', type);
        createNewGraphNode(type, e.clientX, e.clientY);
    }

    const onDragOver = (event) => {
        event.preventDefault();
    }

    // ============= edges and ports ============

    // just for draw port selection styles
    useEffect(() => {
        // console.log('useEffect ports', selected.ports);

        // react-flow__handle connecting
        const domPorts = $$('.react-flow__handle');
        // console.log('useEffect domPorts', domPorts);
        domPorts.forEach(port => {
            port.setAttribute('selected', 'false');
            // port.classList.remove('connecting'); // this is a bad idea because of flow state/store
        });

        for (const port of selected.ports) {
            const domPort = $('[data-handleid="' + port.handleId + '"]');
            domPort.setAttribute('selected', 'true');
            //domPort.classList.add('connecting'); // короче работает не по классам, а по каким-то другим вещам, которые хранятся внутри flow (не пойми где)
        }

        // if (!selected.ports.length) {
        //     // prevent control panel opening after edge creating (and flow updating)
        //     graphParams.portSelectionForSelect = true;
        //     // console.log('portSelection', graphParams.portSelection);
        // }
        //eslint-disable-next-line
    }, [selected.ports]);

    const createEdgesOrSelectPort = (portInfoInput, isSetSelectedPorts = false) => {
        let portInfo = {...portInfoInput};
        const selectedHandlesType = (selected.ports.length > 0) ? selected.ports[0].handleType : 'none';

        if (!portInfo.handleId) {
            // newIds example => { nodeId: "517", targetId: "518", sourceId: "519" }
            if (selectedHandlesType === 'source') {
                portInfo['handleId'] = portInfo.targetId;
                portInfo['handleType'] = 'target';
            } else if (selectedHandlesType === 'target') {
                portInfo['handleId'] = portInfo.sourceId;
                portInfo['handleType'] = 'source';
            }
        }

        if (selectedHandlesType !== 'none' && selectedHandlesType !== portInfo.handleType) {
            // console.log('createEdgesOrSelectPort 1', selectedHandlesType, portInfo.handleType);

            let edges = [];
            for (const port of selected.ports) {
                let edge = null;
                if (portInfo.handleType === 'source') {
                    edge = {
                        srcNode: portInfo.nodeId,
                        srcPort: portInfo.handleId,
                        dstNode: port.nodeId,
                        dstPort: port.handleId,
                    }
                } else if (portInfo.handleType === 'target') {
                    edge = {
                        srcNode: port.nodeId,
                        srcPort: port.handleId,
                        dstNode: portInfo.nodeId,
                        dstPort: portInfo.handleId
                    }
                }
                if (edge && edge.srcNode !== edge.dstNode) {
                    edges.push(edge);
                }
            }

            if (edges.length > 0) {
                // console.log('useEffect add Edges', edges)
                setAutoSave('local:edges_created');
                addGraphEdges(edges, edgesState, setEdgesState, newLocalId);
                // set new save button status - REDRAWN
                setRedrawnStatus(true, true, 'Edges was not created');
            }

            setSelected('ports', null, []);
            graphParams.connecting = 0;
        }
        // adding selected ports
        else if (isSetSelectedPorts) {
            // console.log('createEdgesOrSelectPort 2')
            setSelected('ports', portInfo);
        }
    }

    const getPortInfo = (event) => {
        const nodeId = event.target.getAttribute("data-nodeid");
        const handleId = event.target.getAttribute("data-handleid");
        const handleType = event.target.getAttribute("data-handletype");

        return {nodeId, handleId, handleType}
    }

    const onPortClick = (event) => {
        // get port params
        const {nodeId, handleId, handleType} = getPortInfo(event);
        // console.log('onPortClick portInfo:', nodeId, handleType, handleId);

        // prevent control panel opening after port clicking
        graphParams.portSelectionForClick = true;
        // console.log('portSelectionForClick', graphParams.portSelectionForClick);
        graphParams.portSelectionForSelect = true;
        // console.log('portSelectionForSelect', graphParams.portSelectionForSelect);

        // it is definitely not port dragging => reset to defaults
        graphParams.connectingSource = null;

        // create edges
        const portInfo = {nodeId, handleId, handleType}  // like onConnectStart second param
        createEdgesOrSelectPort(portInfo, true);
    }

    const onClickConnectStart = (event, clickParams) => {
        // console.log('onClickConnectStart');
        onPortClick(event);
    }

    const onClickConnectEnd = (event) => {
        // console.log('onClickConnectEnd');
        onPortClick(event);
    }

    // reset graphParams.connectingSource to defaults on creating menu hide
    useEffect(() => {
        if (!creatingMenuProps.isVisible) graphParams.connectingSource = null;
        //eslint-disable-next-line
    }, [creatingMenuProps.isVisible]);

    // start creating edge by handle dragging
    const onConnectStart = (event, portInfo) => {
        graphParams.connecting = 1;
        // console.log('onConnectStart. Connecting:', graphParams.connecting,
        //     'Port Info:', portInfo // { nodeId: "208", handleId: "209", handleType: "target" }
        // );

        if (!graphParams.reconnecting) {
            graphParams.connectingSource = portInfo;
            // setSelected('ports', portInfo); // bad way
            // because then is impossible to select single port: unselect on select happens
            // and ports selecting on edge click!
        }

        let timer = setInterval(() => {
            if (!graphParams.connecting || graphParams.connecting > 10) clearInterval(timer);
            else graphParams.connecting += 1;
        }, 100)
    }

    const onConnect = (edge) => {
        // console.log('onConnect', edge);
        graphParams.connecting = 0;
        setSelected('ports', null, []);
    }

    // end creating edge by handle dragging => create node (show Menu)
    const onConnectEnd = (event) => {
        // console.log('onConnectEnd. Connecting:', graphParams.connecting,
        //     // event
        // );

        // console.log('graphParams.connectingSource', graphParams.connectingSource)
        // console.log('target.classList', event.target.classList)

        if (event.target.classList.contains('react-flow__handle') && graphParams.connectingSource && !graphParams.connecting) {
            const source = graphParams.connectingSource;
            const target = getPortInfo(event);
            // console.log('target', target)

            let newEdge = {
                srcNode: source.nodeId,
                srcPort: source.handleId,
                dstNode: target.nodeId,
                dstPort: target.handleId,
            }

            if (target.handleType === 'source') {
                newEdge = {
                    srcNode: target.nodeId,
                    srcPort: target.handleId,
                    dstNode: source.nodeId,
                    dstPort: source.handleId,
                }
            }

            // console.log('newEdge', newEdge)

            setAutoSave('local:edges_created');
            // console.log('onConnectEnd add Edges', newEdge)
            addGraphEdges([newEdge], edgesState, setEdgesState, newLocalId, t('graph.flow.error.self_connect'));

            // reset connectingSource after edge creation
            graphParams.connectingSource = null

            // set new save button status - REDRAWN
            setRedrawnStatus(true, true, 'Edge was not created');
        } else if (graphParams.reconnecting === 0 && graphParams.connecting >= 3) {
            // for prevent create on click - run after 300ms dragging
            showNodeCreatingMenu(event);
        } else {
            // reset connectingSource on miss click too
            graphParams.connectingSource = null
        }
        graphParams.connecting = 0;
    }

    // ========= edge updating =========

    const onEdgeUpdateStart = () => {
        graphParams.reconnecting = 1;
        // console.log('onEdgeUpdateStart. Reconnecting:', graphParams.reconnecting);
        let timer = setInterval(() => {
            if (!graphParams.reconnecting || graphParams.connecting > 10) clearInterval(timer);
            else graphParams.reconnecting += 1;
        }, 100)
    }

    const onEdgeUpdate = (oldEdge, newEdge) => {
        if (graphParams.connecting) graphParams.reconnecting = 1;
        else graphParams.reconnecting = 0;
        // console.log('onEdgeUpdate. Reconnecting:', graphParams.reconnecting,
        //     // oldEdge, newEdge
        // );
        setAutoSave('local:edge_move');
        const updatedEdge = changeGraphEdge(oldEdge, newEdge, edgesState, setEdgesState);
        // set new save button status - REDRAWN
        setRedrawnStatus(updatedEdge, true, 'Edge was not changed');
    }

    const onEdgeUpdateEnd = (event, edge, handleType) => {
        // console.log('onEdgeUpdateEnd. Reconnecting:', graphParams.reconnecting);
        // delete edge on edge marker drop
        if (graphParams.reconnecting >= 3) { // for prevent delete on click - run after 300ms dragging
            onEdgesDelete([edge]);
        }
        // back status to defaults
        graphParams.reconnecting = 0;

        // setSelected('ports', null, []); Bad way!
        // prevent port selection on edge click.
    }

    const onEdgesDelete = (edges) => {
        // console.log('onEdgesDelete', edges);
        setAutoSave('local:edges_deleted');
        const deletedIndexes = deleteGraphElements(edges, edgesState, setEdgesState);
        // set new save button status - REDRAWN
        setRedrawnStatus(deletedIndexes, true, 'Edge was not deleted');
    }

    // ============= flow ============

    const setDraggable = () => {
        setBlockDraggable(draggable => !draggable);
    }

    const onFlowInit = () => {
        // onMoveEnd(); // не работает
    }

    const onMoveEnd = (e) => {
        if (e) e.target.classList.remove("cursor-grabbing");

        const transform = flowStore.getState().transform;
        // console.log('onMoveEnd', transform);
        graphParams.position = {
            x: transform[0],
            y: transform[1],
            zoom: transform[2],
        }
    }

    const showNodeCreatingMenu = (e) => {
        // console.log('showNodeCreatingMenu');
        setCreatingMenuProps({isVisible: true, x: e.clientX, y: e.clientY});
        // console.log('lastClickCoords', e.clientX, e.clientY);
    }

    const createNewGraphNode = (type, x = graphParams.mouse.x, y = graphParams.mouse.y) => {
        if (type) {
            setAutoSave('local:node_created');

            let newNode = composeNodeBaseObj(type, x, y, graphParams);
            if (type === 'event') newNode.start_mode = 'reopen';
            const newIds = createGraphNodeAndReturnIds(newNode, nodesState, setNodesState, newLocalId, newStepId);
            // console.log('createNewGraphNode newIds', newIds); // { nodeId: "517", targetId: "518", sourceId: "519" }

            // for creating by buttons // selected.ports = [{nodeId: "459", handleId: "461", handleType: "source"}]
            if (selected.ports.length > 0 && newIds && newIds.targetId && newIds.sourceId) { // NOTE block type has NO ports!
                createEdgesOrSelectPort(newIds);
            }

            // for creating by port-edge dragging
            if (graphParams.connectingSource && newIds && newIds.targetId && newIds.sourceId) { // NOTE block type has NO ports!
                let newEdge = null;
                // graphParams.connectingSource = { nodeId: "208", handleId: "209", handleType: "target" }
                const handle = graphParams.connectingSource;
                if (handle.handleType === 'source') {
                    newEdge = {
                        srcNode: handle.nodeId,
                        srcPort: handle.handleId,
                        dstNode: newIds.nodeId,
                        dstPort: newIds.targetId,
                    }
                } else if (handle.handleType === 'target') {
                    newEdge = {
                        srcNode: newIds.nodeId,
                        srcPort: newIds.sourceId,
                        dstNode: handle.nodeId,
                        dstPort: handle.handleId,
                    }
                }
                // final newEdge check
                if (newEdge) {
                    // console.log('createNewGraphNode add Edges', newEdge, selected.ports);
                    addGraphEdges([newEdge], edgesState, setEdgesState, newLocalId, t('graph.flow.error.self_connect'));
                    // reset connectingSource after edge creation
                    graphParams.connectingSource = null
                }
            }

            // reset system params
            setCreatingMenuProps({...creatingMenuProps, isVisible: false});
            // set new save button status - REDRAWN
            setRedrawnStatus(newIds, true, 'Node was not created');
        }
    }

    const onPaneContextMenu = (e) => {
        // console.log('onPaneContextMenu');
        if (e.shiftKey && !e.ctrlKey && !e.metaKey && !e.altKey) return;

        e.preventDefault();
        e.stopPropagation();

        if (!e.shiftKey && !(e.ctrlKey || e.metaKey) && !e.altKey) {
            // при выбранном (не выделенном) блоке ПКМ не копирует блок, а открывает модальное меню
            showNodeCreatingMenu(e);
        }

        if ((e.ctrlKey || e.metaKey) && !e.shiftKey && !e.altKey) {
            createNewGraphNode('event', e.clientX, e.clientY);
        } else if (!(e.ctrlKey || e.metaKey) && !e.shiftKey && e.altKey) {
            createNewGraphNode('action', e.clientX, e.clientY);
        }
    }

    // ============= system & hotkeys ============

    const onMouseMove = (e) => {
        graphParams.mouse = {x: e.clientX, y: e.clientY}
    }

    const downloadGraph = () => {
        const graphData = composeGraphDataForSave();
        const graphDataText = JSON.stringify({title: graphItem.title, logic: graphData.logic});
        const fileName = 'graph-' + graphItem.id + '-' + Date.now();
        downloadFileFromText(graphDataText, fileName, 'json');
    }

    const clearLocalUpdates = (showNotice = true) => {
        // let allLocalGraphs = getObjectFromStorage(storageName);
        // allLocalGraphs[graphItem.project_id][graphItem.id] = [];
        // setObjectToStorage(storageName, allLocalGraphs);
        setObjectToStorage(storageName, {});
        if (showNotice) notice.success('Local history is clean')
        else console.info('Local history is clean')

        // turn off buttons
        setGraphUpdateIndex(0)
        // console.log('clearLocalUpdates', allLocalGraphs[graphItem.project_id][graphItem.id]);
    }

    const restoreLocalUpdate = (index) => {
        const allLocalGraphs = getObjectFromStorage(storageName);
        const savedUpdates = allLocalGraphs[graphItem.project_id][graphItem.id]; // must be already not empty
        graphParams.localUpdatesCount = savedUpdates.length; // save count for buttons

        if (!savedUpdates.length) {
            notice.warning(t('graph.flow.error.restore.empty'));
            return false;
        } else if (index < 0) {
            setGraphUpdateIndex(-1);
            notice.warning(t('graph.flow.error.restore.last'));
            return false;
        } else if (index >= savedUpdates.length) {
            setGraphUpdateIndex(savedUpdates.length);
            notice.warning(t('graph.flow.error.restore.first'));
            return false;
        }

        const lastGraphUpdate = savedUpdates[index];
        // console.log('restoreLocalUpdate', index, lastGraphUpdate);

        if (!lastGraphUpdate) {
            notice.warning(t('graph.flow.error.restore.undefined'));
            console.log('graph.flow.error.restore', savedUpdates, [graphItem.project_id, graphItem.id, graphUpdateIndex, 'graph']);
            return false;
        }

        const restoredNodes = deepGet(lastGraphUpdate, 'graph.logic.nodes', [])
        const chosenNodeId = deepGet(lastGraphUpdate, 'graph.logic.ui.chosenNodeId', 0)
        if (chosenNodeId && restoredNodes.length) {
            const chosenNodeItem = getElementByLocalId(restoredNodes, chosenNodeId);
            setChosenNode({});
            setTimeout(() => {
                // crutch for textarea and buttons fields
                setChosenNode(chosenNodeItem);
            }, 100)
            setControlPanel('default');

            const chosenNodeFlowItem = getElementByFlowId(flowNodes, chosenNodeId);
            setSelectedElements({nodes: [chosenNodeFlowItem], edges: [], ports: []});
        } else {
            // reset ChosenNode to defaults
            setSelectedElements({nodes: [], edges: [], ports: []});
            setChosenNode({});
        }
        setNodesState(restoredNodes);
        setEdgesState(deepGet(lastGraphUpdate, 'graph.logic.edges', []));

        setGraphUpdateIndex(index);
        setRedrawnStatus(true);
        setEditedStatusDirect(true);
    }

    const onGraphUndo = () => {
        restoreLocalUpdate(graphUpdateIndex + 1);
    }

    const onGraphRedo = () => {
        restoreLocalUpdate(graphUpdateIndex - 1);
    }

    useHotkeys([
        'a', 'ctrl+a', 'command+a', // выделить все блоки и стрелки
        'e', 'ctrl+e', 'command+e', // снять выделение из выделенных блоков
        'd', 'ctrl+d', 'command+d', // дублировать выделенные блоки
        'q', 'ctrl+q', 'command+q', // переключение режима выделения
        'w', 'ctrl+w', 'command+w', // переключение режима перетаскивания схемы
        'n', 'ctrl+n', 'command+n', // создать новую схему
        's', 'ctrl+s', 'command+s', // сохранить схему на сервер
        'p', 'ctrl+p', 'command+p', // сохранить схему в файл
        'ctrl+c', 'command+c', // скопировать выделенные блоки
        'ctrl+v', 'command+v', // вставить выделенные блоки
        'ctrl+z', 'command+z', // отменить последнюю операцию
        'ctrl+y', 'command+y', // применить повторно следующую операцию
        'ctrl+shift+z', 'command+shift+z', // применить повторно следующую операцию
        'ctrl+alt+y', // очистить локальную историю изменений
        '0', '1', '2', '3', '4', '5', '6' // создать блок соответствующего типа
    ].join(','), e => {
        e.preventDefault();
        // console.log('useHotkeys', e.code);

        // run key code actions
        switch (e.code) {
            case 'KeyA':
                selectAllElements();
                break;
            case 'KeyE':
                resetSelectedElements();
                break;
            case 'KeyD':
                onGraphNodesDuplicate();
                break;
            case 'KeyC':
                onGraphCopy();
                break;
            case 'KeyV':
                onGraphPaste();
                break;
            case 'KeyS':
                saveGraph(true);
                break;
            case 'KeyP':
                downloadGraph();
                break;
            case 'KeyQ':
                setSelectionMode(!selectionMode);
                break;
            case 'KeyW':
                setDraggable();
                break;
            case 'KeyN':
                createNewGraph();
                break;
            case 'KeyZ':
                e.shiftKey ? onGraphRedo() : onGraphUndo();
                break;
            case 'KeyY':
                e.altKey ? clearLocalUpdates() : onGraphRedo();
                break;
            default:
                break;
        }

        const nodeTypes = getNodeTypes(); // for creating
        switch (e.key) {
            case '0':
                createNewGraphNode(nodeTypes[0]);
                break;
            case '1':
                createNewGraphNode(nodeTypes[1]);
                break;
            case '2':
                createNewGraphNode(nodeTypes[2]);
                break;
            case '3':
                createNewGraphNode(nodeTypes[3]);
                break;
            case '4':
                createNewGraphNode(nodeTypes[4]);
                break;
            case '5':
                createNewGraphNode(nodeTypes[5]);
                break;
            case '6':
                createNewGraphNode('edit');
                break;
            default:
                break;
        }
    }, [nodesState, edgesState, selected, selectionMode, setSelectionMode]);

    // This is not useful for long press ↓
    useHotkeys('*', e => { // Keys Example: ctrl+s,s,ArrowUp,ArrowDown,ArrowRight,ArrowLeft (but Arrows is not work)
        // console.log('Pressed alt modifier', e.altKey);
        // console.log('Pressed key', e.key, e.code);

        const moveStep = 100;
        const duration = 300;

        if (graphParams.position.y !== null && graphParams.position.x !== null) {
            switch (e.key) {
                case 'ArrowUp':
                    graphParams.position.y -= moveStep;
                    setViewport(graphParams.position, {duration})
                    break;
                case 'ArrowDown':
                    graphParams.position.y += moveStep;
                    setViewport(graphParams.position, {duration})
                    break;
                case 'ArrowLeft':
                    graphParams.position.x -= moveStep;
                    setViewport(graphParams.position, {duration})
                    break;
                case 'ArrowRight':
                    graphParams.position.x += moveStep;
                    setViewport(graphParams.position, {duration})
                    break;
                default:
                    break;
            }
        }
    }, [nodesState, edgesState, graphParams])

    const onKeyDown = /*useCallback(*/(e) => {
        // console.log('onKeyDown', e.key);
        if (e.key === 'Alt') {
            setMovingMode(true);
        }
    }
    //,[setMovingMode, setViewport]);

    const onKeyUp = (e) => {
        // console.log('onKeyUp', e);
        if (e.key === 'Alt') {
            setMovingMode(false);
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        }
        // eslint-disable-next-line
    }, [setMovingMode, setViewport])

    const onWheel = (e) => {
        const delta = e.nativeEvent.wheelDelta;
        if (e.shiftKey) {
            // console.log('onWheel shift delta', delta)
            graphParams.position.x += delta;
            setViewport(graphParams.position, {duration: 0});
        }
    }

    const onMoveStart = (e) => {
        // console.log('onMoveStart', e.target);
        if (e) e.target.classList.add("cursor-grabbing");
    }

    // ============= effects containers ============

    const setRedrawnStatus = (updatedNode, status = true, errorMessage = 'Node was not updated') => {
        if (!updatedNode) notice.error(errorMessage);
        else dispatch(changeGraphStatus({redrawn: status}));
    }

    const setEditedStatus = (node, updatedNode, status = true, errorMessage = 'Node was not updated') => {
        if (!updatedNode) {
            notice.error(errorMessage);
        } else if (!isObjectsEqual(node, updatedNode)) {
            dispatch(changeGraphStatus({edited: status, redrawn: status}));
        }
    }

    const setEditedStatusDirect = (updatedNode, status = true, errorMessage = 'Node was not updated') => {
        if (!updatedNode) notice.error(errorMessage);
        else dispatch(changeGraphStatus({edited: status, redrawn: status}));
    }

    const setEditedAndRedrawnStatus = (updatedNode, status = true, addRedrawnCond = true, errorMessage = 'Node was not updated') => {
        if (!updatedNode) notice.error(errorMessage);
        else {
            let newStore = {edited: status};
            if (addRedrawnCond) newStore['redrawn'] = status; // for conditions but not actions
            dispatch(changeGraphStatus(newStore));
        }
    }

    const addNodeEffect = (type) => {
        if (chosenNode.localId) {
            setAutoSave('local:container_added');

            const updatedNode = addGraphActionToNode(type, {}, chosenNode.localId, nodesState, setNodesState, newLocalId, setChosenNode);
            // console.log('Effect added to Node', updatedNode);
            setEditedAndRedrawnStatus(updatedNode, true, chosenNode.type === 'condition', 'Node Effect was not added');
            if (updatedNode) setControlPanel('default');
            else notice.error('Effect not added by unexpected error');
        }
    }

    useEffect(() => {
        const containerData = graph.container;
        if (containerData.graphId === graphItem.id && containerData.localId) {
            const listName = containerData.listName
            let nodes = [...nodesState]
            const nodeIndex = findIndexByLocalId(nodes, chosenNode.localId)
            if (nodeIndex >= 0) {
                let node = {...nodes[nodeIndex]}
                let list = [...node[containerData.listName]]
                const containerIndex = findIndexByLocalId(chosenNode[listName], containerData.localId)
                if (nodeIndex >= 0) {
                    let container = {...list[containerIndex]}
                    container['spec'] = container.spec ? {...container.spec, ...containerData.specValues} : containerData.specValues
                    list[containerIndex] = container
                    node[listName] = list
                }
                // save changes
                nodes[nodeIndex] = node
                setChosenNode(node)
                setNodesState(nodes)
                // console.log('Graph node Update', graph.node, node)
            }
        }
        // reset node editing
        dispatch(patchGraphContainer({}))
    }, [graph.container.localId])

    const createWebhook = (localId) => {
        const data = {
            project_id: graphItem.project_id,
            process_id: graphItem.id,
            entity_local_id: localId,
            is_on: true,
            name: 'Webhook for Event #' + graphItem.id
        }
        dispatch({type: 'createWebhook', admin, data, updateGraph: true});
    }

    const addNodeEvent = (type) => {
        if (chosenNode.type === 'event') {
            // console.log('addNodeEvent', type);
            setAutoSave('local:event_added');

            const updatedNode = addGraphEventToNode(type, chosenNode.localId, nodesState, newLocalId, true, setNodesState, setChosenNode);
            // console.log('Event added to Node', updatedNode);
            setEditedStatusDirect(updatedNode, true, 'Node Event was not added');
            if (updatedNode) {
                setControlPanel('default');
                // do not forget add webhook to new event
                // if (type === specialEventTypes.webhook) createWebhook(updatedNode.events[updatedNode.events.length - 1].localId)
            } else notice.error('Event not added by unexpected error');
        }
    }

    const addNodeEffectCondition = (type) => {
        // console.log('Condition added to NodeEffect', chosenContainerId, type);
        setAutoSave('local:condition_added');
        const updatedNode = addGraphConditionToEffect(type, chosenNode.localId, chosenContainerId, nodesState, setNodesState, newLocalId, setChosenNode);
        // console.log('Result', updatedNode);
        if (updatedNode === undefined) {
            notice.error('Condition effect elements is undefined. You found a miracle!');
        } else {
            setControlPanel('default');
            setEditedStatusDirect(updatedNode, true, 'Node Condition was not added');
        }
    }

    const cloneNodeEffect = (nodeLocalId, effectLocalId) => {
        const node = getElementByLocalId(nodesState, nodeLocalId);
        let origEvent = null;
        let origAction = getElementByLocalId(node.effects, effectLocalId);
        if (!origAction && node.events) {
            // then it can be event
            origEvent = getElementByLocalId(node.events, effectLocalId);
            if (origEvent) origAction = getElementByLocalId(node.effects, origEvent.filterEffect);
        }
        if (origEvent) {
            setAutoSave('local:event_cloned');
            const updatedNode = addGraphEventToNode(
                origEvent.type,
                chosenNode.localId,
                nodesState,
                newLocalId,
                true,
                setNodesState,
                setChosenNode,
                null,
                origAction.spec,
            );
            setEditedStatusDirect(updatedNode, true, 'Node Event was not added');
        } else if (origAction) {
            // console.log('cloneNodeEffect origEffect:', origAction);
            setAutoSave('local:container_cloned');

            const updatedNode = addGraphActionToNode(origAction.type, origAction.spec, nodeLocalId, nodesState, setNodesState, newLocalId, setChosenNode);
            setEditedAndRedrawnStatus(updatedNode, true, node.type === 'condition', 'Node Effect was not cloned');

            // console.log('cloneNodeEffect newEffect:', updatedNode);
        } else notice.error('Can not get effect for clone. You found a miracle!');
    }

    const deleteNodeEffect = (nodeLocalId, localId) => {
        const node = getElementByLocalId(nodesState, nodeLocalId);
        let effectLocalId = localId;

        let nodeEvents = null; // init false value before check
        if (node.type === 'event' && node.events && node.events.length) {
            const eventIndex = findIndexByLocalId(node.events, localId);
            effectLocalId = node.events[eventIndex].filterEffect;

            // remove event
            nodeEvents = removeElementsFromArray([eventIndex], node.events);
        }

        // find effect or filterEffect index
        const effectIndex = findIndexByLocalId(node.effects, effectLocalId);

        // get effect by index
        const effect = node.effects[effectIndex];
        let nodePorts = node.ports;
        let removePorts = []; // and delete ports on the way
        let removePortsIds = []; // for delete edges later
        let relatedNodeInfo = {}

        // delete condition ports
        if (node.type !== 'event' && effect.type === condition_jump_effect.type) {
            for (const truePortLocalId of effect.spec['truePorts']) {
                const portIndex = findIndexByLocalId(nodePorts, truePortLocalId);
                if (portIndex >= 0) {
                    removePorts.push(portIndex);
                    removePortsIds.push(truePortLocalId);
                }
            }
        }

        // delete message ports
        if (node.type === 'message' && effect.spec && effect.spec.keyboard && effect.spec.keyboard.length) {
            for (const buttonsRow of effect.spec.keyboard) {
                for (const button of buttonsRow) {
                    const portIndex = findIndexByLocalId(nodePorts, button.id);
                    if (portIndex >= 0) {
                        removePorts.push(portIndex);
                        removePortsIds.push(button.id);
                    }
                }
            }

            relatedNodeInfo = findRelatedNodeData(node, 'reactions', 'event')
            // if (relatedNodeInfo && removePortsIds.length) {}
        }

        // remove edges to
        let edges = [...edgesState]

        if (removePorts.length) {
            nodePorts = removeElementsFromArray(removePorts, nodePorts);

            // remove edges of removed ports
            edges = edges.filter(edge => !inArray(edge.srcPort, removePortsIds))
        }

        // remove effect
        const nodeEffects = removeElementsFromArray([effectIndex], node.effects);

        // set new params
        let nodeNewParams = {effects: nodeEffects, ports: nodePorts};
        if (nodeEvents) nodeNewParams['events'] = nodeEvents;

        setAutoSave('local:container_deleted');

        // update curren node params
        let nds = new Io(nodesState);
        nds.remember('nodes')
        nds.find(['localId', nodeLocalId])
        // update node in nodesState list
        nds.set(nodeNewParams)
        // update chosenNode
        setChosenNode(nds.space)

        // store result
        let nodes = nds.data

        // for messages keyboards
        if (relatedNodeInfo.nodeLocalId && removePortsIds.length) {
            // check buttons length
            const messagePorts = nodePorts.filter(p => p.group === 'buttons')
            if (messagePorts.length) {
                nds.restore('nodes')
                nds.find(['localId', relatedNodeInfo.nodeLocalId])

                // remove condition
                if (nds.space.effects) {
                    let removedFiltersLocalIds = []
                    // remove conditions
                    const eventEffects = nds.space.effects.filter(el => () => {
                        const res = !inArray(deepGet(el, 'spec.truePorts.0'), removePortsIds)
                        if (res) removedFiltersLocalIds.push(el.localId)
                        return res
                    })
                    nds.set({effects: eventEffects})

                    nds.go(['effects'])
                    nds.find(['type', graph_jump_effect.type])

                    nds.go(['spec'])
                    const localIds = nds.space.localIds.filter(el => !inArray(el, removedFiltersLocalIds))
                    nds.set({'localIds': localIds})

                    // store result
                    nodes = nds.data
                }
            }
            // remove event if last port deleted
            else {
                nds.go(['ui'])
                nds.remove({from: 'related', by: 'nodeLocalId', value: relatedNodeInfo.nodeLocalId})

                // remove event node and store result
                nodes = nds.data.filter(node => node.localId !== relatedNodeInfo.nodeLocalId)
                // nds.remove({by: 'localId', value: relatedNodeInfo.nodeLocalId})
                edges = edges.filter(edge => edge.dstNode !== relatedNodeInfo.nodeLocalId)
            }
        }

        // save edges
        if (edges.length !== edgesState.length) setEdgesState(edges)
        // save nodes
        const updatingResult = (nodes !== undefined)
        if (updatingResult) setNodesState(nodes)

        // store params to node
        // const updatedNode = updateGraphNode(nodeNewParams, null, nodesState, setNodesState, setChosenNode);
        setEditedAndRedrawnStatus(updatingResult, true, inArray(node.type, ['condition', 'message']), 'Node Effect was not deleted');
    }

    const batchNodeEffectsUpdate = (nodeLocalId, effectsParams) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        let effects = [];
        for (const effect of node.effects) {
            if (effect.type === graph_jump_effect.type) continue;
            const newEffect = {...effect, ...effectsParams};
            effects.push(newEffect);
        }

        const updatedNode = updateGraphNode({
            localId: nodeLocalId,
            effects: effects
        }, null, nodesState, setNodesState, setChosenNode);
        // do not change edited status for container collapsing
    }

    const updateNodeContainerParams = (nodeLocalId, containerLocalId, effectParams = {}, changeStatus = false, updateFlow = false) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        const containerType = (node.type === 'event') ? 'events' : 'effects';

        // console.log('updateNodeContainerParams', containerLocalId, node[containerType])
        const containerIndex = findIndexByLocalId(node[containerType], containerLocalId);
        let affectedNode = deepCopyObject(node); // Is it possible to optimize?
        const container = affectedNode[containerType][containerIndex];

        for (const effectParam in effectParams) {
            if (inArray(effectParam, ['spec', 'ui'])) {
                container[effectParam] = {...container[effectParam], ...effectParams[effectParam]};
            } else {
                container[effectParam] = effectParams[effectParam];
            }
        }

        // set new params
        let newNodeParams = {localId: nodeLocalId, [containerType]: affectedNode[containerType]};

        // rename node ports also
        if ('title' in effectParams && containerType !== 'events' && container.spec && container.spec.truePorts) {
            let nodePorts = deepCopyObject(node.ports);
            for (const truePortLocalId of container.spec.truePorts) {
                const portIndex = findIndexByLocalId(nodePorts, truePortLocalId);
                if (portIndex >= 0) {
                    if (!nodePorts[portIndex]['ui']) nodePorts[portIndex]['ui'] = {};
                    nodePorts[portIndex]['ui']['title'] = effectParams.title;
                }
            }
            newNodeParams['ports'] = nodePorts;
        }

        // turn off flow updating
        if (!updateFlow) graphParams.doNotRerenderFlow = true; // console.log('doNotRerenderFlow', true);

        // and update node
        const updatedNode = updateGraphNode(newNodeParams, null, nodesState, setNodesState, setChosenNode);
        if (changeStatus) setEditedStatus(node, updatedNode, true, 'Node Container was not updated');
    }

    const updateNodeEffectParams = (nodeLocalId, containerLocalId, effectParams = {}, changeStatus = false, updateFlow = false) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);

        // console.log('updateNodeEffectParams', containerLocalId, node)
        const containerIndex = findIndexByLocalId(node['effects'], containerLocalId);
        let affectedNode = deepCopyObject(node); // Is it possible to optimize?
        const container = affectedNode['effects'][containerIndex];

        for (const effectParam in effectParams) {
            if (inArray(effectParam, ['spec', 'ui'])) {
                container[effectParam] = {...container[effectParam], ...effectParams[effectParam]};
            } else {
                container[effectParam] = effectParams[effectParam];
            }
        }

        // set new params
        let newNodeParams = {localId: nodeLocalId, effects: affectedNode.effects};

        // turn off flow updating
        if (!updateFlow) graphParams.doNotRerenderFlow = true; // console.log('doNotRerenderFlow', true);

        // and update node
        const updatedNode = updateGraphNode(newNodeParams, null, nodesState, setNodesState, setChosenNode);
        if (changeStatus) setEditedStatus(node, updatedNode, true, 'Node Effect was not updated');
    }

    const updateNodeEffect = (nodeLocalId, effectLocalId, spec = {}) => {
        setAutoSave('local:container_updated');
        // console.log('updateNodeEffect', 'node', nodeLocalId, 'effect', effectLocalId, spec)
        updateNodeEffectParams(nodeLocalId, effectLocalId, {spec: spec}, true);
    }

    const moveNodeContainer = (nodeLocalId, containerLocalId, forward = true) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        const containerType = (node.type === 'event') ? 'events' : 'effects';
        let containerList = [...node[containerType]]; // «let» for moving items later

        const containerCurrentIndex = findIndexByLocalId(containerList, containerLocalId);
        const containerNewIndex = forward ? containerCurrentIndex + 1 : containerCurrentIndex - 1;

        if (containerList[containerNewIndex] === undefined) {
            notice.warning(t('graph.flow.error.move.last'));
            return false;
        }

        let newNodeParams = {
            localId: nodeLocalId,
            [containerType]: arrayMove(containerList, containerCurrentIndex, containerNewIndex),
        }

        // turn off flow updating
        graphParams.doNotRerenderFlow = true;

        const updatedNode = updateGraphNode(newNodeParams, null, nodesState, setNodesState, setChosenNode);
        setEditedStatus(node, updatedNode, true, 'Node Effect was not updated');
    }

    const updateNodeEffectCondition = (nodeLocalId, effectLocalId, conditionLocalId, condParams) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        const effectIndex = findIndexByLocalId(node.effects, effectLocalId);
        let affectedNode = deepCopyObject(node);

        const affectedEffect = affectedNode.effects[effectIndex];
        const conditions = affectedEffect.spec.conditions;
        const condIndex = findIndexByLocalId(conditions, conditionLocalId);
        if (condIndex >= 0) conditions[condIndex] = {...conditions[condIndex], ...condParams};

        // turn off flow updating
        graphParams.doNotRerenderFlow = true; // console.log('doNotRerenderFlow', true);
        // but turn on autosave
        setAutoSave('local:condition_updated');

        // and save node to state
        const updatedNode = updateGraphNode({
            localId: nodeLocalId,
            effects: affectedNode.effects
        }, null, nodesState, setNodesState, setChosenNode);

        // set new save button status
        setEditedStatus(node, updatedNode, true, 'Node Condition was not updated');
    }

    const deleteEffectCondition = (nodeLocalId, effectLocalId, conditionLocalId) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        const effectIndex = findIndexByLocalId(node.effects, effectLocalId);
        let affectedNode = deepCopyObject(node);

        const affectedEffect = affectedNode.effects[effectIndex];
        const conditions = affectedEffect.spec.conditions;

        const condIndex = findIndexByLocalId(conditions, conditionLocalId);
        if (condIndex > -1) conditions.splice(condIndex, 1); // delete item by index

        setAutoSave('local:condition_deleted');

        const updatedNode = updateGraphNode({
            localId: nodeLocalId,
            effects: affectedNode.effects
        }, null, nodesState, setNodesState, setChosenNode);

        // set new save button status
        setEditedStatus(node, updatedNode, true, 'Node Condition was not deleted');
    }

    // ============= keyboard ============

    const createButtonSystemNodes = (localId, button) => {
        // console.log('create hidden nodes')
        if (!localId || !chosenNode || !chosenNode.ports) return false

        // system ports
        const successPortLocalId = localId; // button id = button port localId = condition true port localId
        const messageSourcePort = chosenNode.ports.find(port => port.name === 'defaultOutput');

        // event node
        const eventInputLocalId = newLocalId();
        const eventNodeLocalId = newLocalId();
        const eventOutputLocalId = newLocalId();
        // event filters
        const filterEffectLocalId = newLocalId(); // graph/jump/to effect
        const firstConditionLocalId = newLocalId(); // condition/jump effect

        // console.log('createButtonSystemNodes', {
        //     successPortLocalId,
        //     eventNodeLocalId,
        //     eventOutputLocalId,
        //     filterEffectLocalId,
        //     firstConditionLocalId
        // })

        // TODO: move to external function
        let conditionType = popularConditionTypes.object_text;
        let conditionSpec = {values: [button.label]};

        if (button.type === 'url') {
            if (!button.spec) notice.error('URL Button spec is undefined!')
            else conditionSpec = {values: [button.spec.url]};
        } else if (button.type === 'request') {
            conditionType = popularConditionTypes.object_attachment;
            conditionSpec = {values: [deepGet(button, 'spec.object', 'contact')]};
        }
        // TODO: end move part

        let eventNode = {
            type: 'event',
            localId: eventNodeLocalId,
            events: [
                {
                    localId: newLocalId(),
                    type: specialEventTypes.message,
                    filterEffect: filterEffectLocalId,
                },
                {
                    localId: newLocalId(),
                    type: specialEventTypes.button,
                    filterEffect: filterEffectLocalId,
                },
                {
                    localId: newLocalId(),
                    type: specialEventTypes.url,
                    filterEffect: filterEffectLocalId,
                },
            ],
            effects: [
                // pseudo filter for event => jump to all filters in one step
                createGraphEffect(
                    graph_jump_effect.type,
                    {localIds: [firstConditionLocalId]},
                    newLocalId,
                    null,
                    filterEffectLocalId
                ),
                // first (keyword) filter
                createConditionEffect(
                    conditionType,
                    conditionSpec,
                    {truePorts: [successPortLocalId, eventOutputLocalId]},
                    firstConditionLocalId,
                    newLocalId,
                )
            ],
            ports: [
                composePort('defaultInput', eventInputLocalId, 'input'),
                composePort('defaultOutput', eventOutputLocalId, 'output'), // for backend state deleting
            ],
            ui: {hidden: true},
        }
        // console.log('Button: New event', eventNode)

        // setAutoSave('local:keyboard_created'); // TODO: replace it to Keyboard component!

        // set related nodes to chosenNode
        let nodes = upd(nodesState, [
            // push hidden node
            {push: [eventNode]},
            // find and open chosenNode
            {find: ['localId', chosenNode.localId]},
            {remember: 'chosenNode'},
            // save (related) hidden nodes IDs in chosenNode
            {go: {path: 'ui.related', empty: []}},
            {push: [{nodeLocalId: eventNodeLocalId, group: 'reactions', type: "event"}]},
            // add related port to chosenNode
            {restore: 'chosenNode'},
            {go: {path: 'ports', empty: []}},
            {
                push: [{
                    ...composePort('button', successPortLocalId, 'output', 'buttons'),
                    ui: {type: button.color, title: button.label}
                }]
            },
        ], 'Chosen Node or it Params is undefined. Graph is broken')

        // save everything to state
        setNodesState(nodes)

        // and finally connect them all by edges
        const newEdges = [
            { // from message to event. This hidden arrow is necessary for _event_waiting_ logic
                srcNode: chosenNode.localId,
                srcPort: messageSourcePort.localId,
                dstNode: eventNodeLocalId,
                dstPort: eventInputLocalId,
                ui: {hidden: true},
            },
        ]
        // console.log('Button: New edges', newEdges)
        addGraphEdges(newEdges, edgesState, setEdgesState, newLocalId, 'System edges self connect')
    }

    const addKeyboardButton = (localId, button) => {
        if (!localId) return

        // const relatedNodeLocalId = deepGet(getChosenNode(), 'ui.relative.reactions.event', 0);
        const relatedNodeInfo = findRelatedNodeData(getChosenNode(), 'reactions', 'event')
        if (!relatedNodeInfo) {
            notice.error('Related Node undefined!')
            return;
        }

        const relatedNodeLocalId = relatedNodeInfo.nodeLocalId;
        const relatedNode = getElementByLocalId(nodesState, relatedNodeLocalId);
        const eventOutput = relatedNode.ports.find(port => port.name === 'defaultOutput');

        const effectLocalId = newLocalId();

        let conditionType = popularConditionTypes.object_text;
        let conditionSpec = {values: [button.label]};

        if (button.type === 'url') {
            if (!button.spec) notice.error('URL Button spec is undefined!')
            else conditionSpec = {values: [button.spec.url]};
        }
        // all request button types
        else if (button.type === 'request') {
            conditionType = popularConditionTypes.object_attachment;
            conditionSpec = {values: [deepGet(button, 'spec.object', 'contact')]};
        }

        let relatedConditionEffect = createConditionEffect(
            conditionType,
            conditionSpec,
            {truePorts: [localId, eventOutput.localId]},
            effectLocalId,
            newLocalId,
        )

        // setAutoSave('local:button_added');

        let nodes = upd(nodesState, [
            // related effect
            {remember: 'nodes'},
            {find: ['localId', relatedNodeLocalId]}, // find related condition node
            {go: ['effects']},
            {push: [relatedConditionEffect]}, // add condition effect
            // event filters
            {find: ['type', graph_jump_effect.type]}, // find graph_jump_effect
            {go: ['spec', 'localIds']},
            {push: [effectLocalId]}, // add new button filter
            // message ports
            {restore: 'nodes'},
            {find: ['localId', chosenNode.localId]}, // find chosenNode
            {go: ['ports']}, // add related port
            {
                push: [{
                    ...composePort('button', localId, 'output', 'buttons'),
                    ui: {type: button.color, title: button.label}
                }]
            },
        ], 'Chosen or Related Node undefined')
        setNodesState(nodes)
    }

    const editKeyboardButton = (localId, button) => {
        const relatedNode = findRelatedNodeData(getChosenNode(), 'reactions', 'event')
        if (!relatedNode) {
            notice.error('Related Node undefined!');
            return;
        }

        const relatedNodeLocalId = relatedNode.nodeLocalId;
        // console.log('editKeyboardButton', relatedNodeLocalId, chosenNode)

        if (!localId) return
        if (!relatedNodeLocalId) notice.error('Related condition node not found')
        // setAutoSave('local:button_updated');

        let conditionSpec = {values: [button.label]};
        if (button.type === 'url') {
            if (!button.spec) notice.error('URL Button spec is undefined!')
            else conditionSpec = {values: [button.spec.url]};
        }
        // all request button types
        else if (button.type === 'request') {
            conditionSpec = {values: [deepGet(button, 'spec.object', 'contact')]};
        }

        setNodesState(upd(nodesState, [
            // update condition
            {remember: 'nodes'},
            {find: ['localId', relatedNodeLocalId]},
            {go: ['effects']},
            {find: ['spec.truePorts.0', localId]},
            {go: 'spec.conditions.0.spec'},
            {set: conditionSpec},
            // update button port
            {restore: 'nodes'},
            {find: ['localId', chosenNode.localId]}, // find chosenNode
            {go: ['ports']},
            {find: ['localId', localId]},
            // update button color and label
            {go: ['ui']},
            {set: {type: button.color, title: button.label}},
        ], 'Related item undefined'))
    }

    const removeKeyboardButton = (localId, keyboard, effectLocalId) => {
        // let nodes = [...nodesState]
        const chosenNodeItem = getChosenNode();
        const relatedNode = findRelatedNodeData(chosenNodeItem, 'reactions', 'event')
        if (!relatedNode) {
            notice.error('Related Node undefined!')
            return;
        }
        const relatedNodeLocalId = relatedNode.nodeLocalId;

        // remove message ports fist
        let nds = new Io(nodesState);
        nds.find(['localId', chosenNode.localId])
        nds.remove({from: 'ports', by: 'localId', value: localId})
        nds.go(['ports'])
        let nodes = nds.data
        const messagePorts = nds.space.filter(p => p.group === 'buttons')

        // remove related edges
        setEdgesState(edgesState.filter(edge => edge.srcPort !== localId))

        if (messagePorts.length) {
            // remove related condition and port
            if (!localId) return

            // setAutoSave('local:button_deleted');

            // get filter ID
            let iNodes = new Io(nodesState);
            iNodes.find(['localId', relatedNodeLocalId])
            iNodes.go(['effects'])
            iNodes.find(['spec.truePorts.0', localId])
            const filterLocalId = iNodes.space.localId;
            if (!filterLocalId) {
                // notice.error('Filter ID undefined')
                return
            }

            nodes = upd(nodes, [
                // update condition
                {remember: 'nodes'},
                {find: ['localId', relatedNodeLocalId]},
                {remove: {from: 'effects', by: 'spec.truePorts.0', value: localId}},
                // update event filter
                {go: ['effects']},
                {find: ['type', graph_jump_effect.type]},
                {go: ['spec']},
                {remove: {from: 'localIds', value: filterLocalId}},
                // update button port
                {restore: 'nodes'},
                {find: ['localId', chosenNode.localId]}, // find chosenNode
                // {remove: {from: 'ports', by: 'localId', value: localId}}, // relocated above
                // fix keyboard
                {go: ['effects']},
                {find: ['localId', effectLocalId]},
                {go: ['spec']},
                {set: {keyboard}}
            ], 'Related item undefined')

            setNodesState(nodes)
        } else {
            // cleanup related ids
            nodes = upd(nodes, [
                // {remember: 'nodes'},
                {find: ['localId', chosenNode.localId]},
                // {remove: {from: 'ports', by: 'localId', value: localId}}, // relocated above
                {go: ['ui']},
                {remove: {from: 'related', by: 'nodeLocalId', value: relatedNodeLocalId}},
                // {restore: 'nodes'},
                // {remove: {by: 'localId', value: relatedEventId}},
                // {remove: {by: 'localId', value: relatedCondId}},
            ], 'Chosen Node undefined')

            // and delete related nodes
            nodes = nodes.filter(node => !inArray(node.localId, [relatedNodeLocalId]))
            // console.log('removeKeyboardButton from event', relatedNodeLocalId, 'nodes:', nodes)
            // store to state
            setNodesState(nodes)
        }
    }

    // ============= renderer ============

    const resetGraphPosition = () => {
        dispatch(setLoadingStatus('loading'))
        graphParams.position = {x: 0, y: 0, zoom: 1}
        setViewport(graphParams.position)
    }

    // ------ running on every pixel move ↓ ------
    // const onNodesChange = (nodes) => {
    //     console.log('onNodesChange', nodes)
    //     onFlowNodesChange(nodes);
    // }

    return (
        <FlowContext.Provider value={{
            // nodes & common
            newLocalIdNext: newLocalId,
            saveGraph: saveGraph,
            resetGraphPosition,

            // update history logic
            graphUpdateIndex: graphUpdateIndex,
            setGraphUpdateIndex: setGraphUpdateIndex,
            localUpdatesCount: graphParams.localUpdatesCount,
            restoreLocalUpdate: restoreLocalUpdate,
            onGraphUndo: onGraphUndo,
            onGraphRedo: onGraphRedo,

            // nodes & common
            onCopy: onGraphNodesDuplicate,
            onDelete: onGraphElementsDelete,
            onUpdate: onGraphNodeUpdate,
            updateNodeTitle: updateGraphNodeText,
            onIgnore: onGraphNodeIgnoreChange,
            onEdgesDelete: onEdgesDelete,

            // effect containers
            addNodeEvent,
            addNodeEffect,
            addNodeEffectCondition,
            batchNodeEffectsUpdate,
            updateNodeEffectParams,
            updateNodeContainerParams,
            updateNodeEffect,
            moveNodeContainer,
            updateNodeEffectCondition,
            deleteEffectCondition,
            cloneNodeEffect,
            deleteNodeEffect,

            // keyboard buttons
            createButtonSystemNodes,
            addKeyboardButton,
            editKeyboardButton,
            removeKeyboardButton,

            // states
            nodesState,
            edgesState,
            chosenNode,
            chosenContainerId,
            setChosenContainerId,
            controlPanel,
            setControlPanel,
            setAutoSave,
        }}>
            <ReactFlow
                ref={flowRef}
                className={classes.flow}
                onMoveStart={onMoveStart}
                onMoveEnd={onMoveEnd}

                nodes={flowNodes}
                edges={flowEdges}

                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}

                // onInit={onFlowInit}
                // onWheelCapture={onWheel} // horizontal scroll with shift
                zoomOnScroll={!movingMode} // turn off zoom and work with move on scroll
                panOnScroll={movingMode}
                // onPaneScroll={onPaneScroll}

                nodesDraggable={blockDraggable}
                onEdgesChange={onFlowEdgesChange}
                onNodesChange={onFlowNodesChange}

                onPaneContextMenu={onPaneContextMenu}
                onSelectionChange={onSelectionChange}

                onNodeClick={onNodeClick}
                onNodeContextMenu={onNodeContextMenu}
                onNodeDoubleClick={onNodeDoubleClick}
                onSelectionDragStop={onSelectionDragStop}
                onNodeDragStop={onNodeDragStop}
                onNodesDelete={onNodesDelete}

                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onClickConnectStart={onClickConnectStart}
                onClickConnectEnd={onClickConnectEnd}
                onConnectEnd={onConnectEnd}

                onEdgeUpdate={onEdgeUpdate}
                onEdgesDelete={onEdgesDelete}
                onEdgeUpdateStart={onEdgeUpdateStart}
                onEdgeUpdateEnd={onEdgeUpdateEnd}

                defaultZoom={1}
                minZoom={0.2}
                maxZoom={5}

                defaultPosition={[0, 0]}
                attributionPosition="bottom-left"
                zoomOnDoubleClick={false}
                selectNodesOnDrag={false}
                // edgeUpdaterRadius={30}
                // fitView={true}

                deleteKeyCode={['Backspace', 'Delete']}
                selectionKeyCode={['Shift']}    // синий прямоугольник мешает переключению режимов выделения комбинациями клавиш и mouseover
                multiSelectionKeyCode={['Meta', 'Control']}

                onMouseMove={onMouseMove}
                onDragOver={onDragOver}
                onDrop={onDrop}
            >
                <ButtonsPublishExit
                    nodes={nodesState}
                    flowNodes={flowNodes}
                    admin={admin}
                    project={project}
                    graph={graph}
                    saveGraph={saveGraph}
                    graph_id={graphItem.id}
                    folderId={graphItem.folder_id}
                    createNewGraph={createNewGraph}
                />

                <ButtonsAddControl
                    onNodeAdd={onClickLeftButtons}
                    onCopy={onGraphNodesDuplicate}
                    onDelete={onGraphElementsDelete}

                    selectionMode={selectionMode}
                    setSelectionMode={setSelectionMode}
                    hasSelected={!!(selected.nodes.length || selected.edges.length)}
                />
                <AddNodeMenu
                    options={creatingMenuProps}
                    onOptionsChange={setCreatingMenuProps}
                    createNewGraphNode={createNewGraphNode}
                />

                {/*<Background color="#aaa" gap={16}  />*/}
            </ReactFlow>

            <ControlPanel chosenNode={chosenNode} isPanelFixed={isPanelFixed}>
                <ButtonsZoom
                    nodes={flowNodes}
                    setDraggable={setDraggable}
                    blockDraggable={blockDraggable}
                />
                <ControlButtons
                    isPanelFixed={isPanelFixed}
                    onPanelFixedChange={onPanelFixedChange}
                    onClose={resetSelectedElements}
                    onSave={saveGraph}
                    controlPanel={controlPanel}
                />
            </ControlPanel>

            <InputModal
                initValue={''}
                isVisible={isVisiblePasteModal}
                setVisible={setVisiblePasteModal}
                onModalOk={insertCopiedData}
                title={t('graph.flow.paste.modal.title')}
                placeholder={t('graph.flow.paste.modal.import.placeholder')}
            />
        </FlowContext.Provider>)

};

const Flow = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const params = useParams()
    const project_id = Number(params.project_id)
    const id = Number(params.id)

    const {app, admin, project, graph} = useSelector(store => store)
    const user = JSON.parse(decrypt(localStorage.getItem(dotenv.app_name)));

    const item = graph.item;
    const list = graph.list;
    const status = app.status;
    const project_item = project.item;

    useEffect(() => {
        if (admin.authorized && project_id && (!project_item.id || project_item.id !== project_id)) {
            // console.log('getProjectItem', admin.authorized, project_item.id, project_id)
            dispatch({type: 'getProjectItem', admin, id: project_id});
        }
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id])

    useEffect(() => {
        dispatch(setLoadingStatus('loading'))

        if (admin.authorized && id) {
            // get graph from DB
            dispatch({type: 'getGraphItem', admin, id});
        }
        //eslint-disable-next-line
    }, [admin.authorized])

    useEffect(() => {
        if (admin.authorized) {
            if (!graph.list.length && item.folder_id !== undefined) {
                dispatch({
                    type: 'getGraphList', admin, filters: {
                        project_id,
                        folder_ids: [item.folder_id],
                        ordering: '-created_at'
                    }
                });
            }
        }
        //eslint-disable-next-line
    }, [admin.authorized, graph])

    return status === 'ready' ?
        <ReactFlowProvider>
            <GraphFlow graph={graph} project={project} admin={admin}/>
        </ReactFlowProvider> : <Preloader/>
}

export default Flow;
