export const coreGame = {
    'achievement/list': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/game/achievement/list',
                            spec: {
                                show_title: true,
                                by_game: false,
                                cl: {
                                    item: [],
                                    title: [
                                        "text-h3",
                                        "mt-5"
                                    ],
                                    photo: [
                                        "inline-block",
                                        "rounded-xl",
                                        "w-32"
                                    ],
                                },
                            },
                            style: {
                                padding: {
                                    top: "4",
                                    left: "4",
                                    right: "4",
                                    bottom: "4"
                                },
                                flex: {
                                    gap: "8",
                                    direction: "row",
                                    justify: "evenly",
                                    wrap: true
                                },
                                grid: {
                                    "default": "4"
                                },
                                text: {
                                    align: "center"
                                },
                            }
                        }
                    ],
                    style: {}
                }
            ],
            style: {
                width: {
                    container: "md"
                },
                padding: {
                    top: "6",
                    left: "6",
                    right: "6",
                    bottom: "6"
                }
            }
        }]
    },
}