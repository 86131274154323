export const coreForm = {
    'button/url': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    style: {
                        flex: {
                            direction: 'row',
                            justify: 'center',
                            gap: '4',
                            wrap: true
                        },
                    },
                    items: [
                        {
                            type: 'core/form/button/url',
                            spec: {
                                label: '{locale:container.example.form.button.url}',
                                url: '',
                                color: 'primary',
                                size: 'medium',
                                variant: 'contained',
                                is_external: false,
                                icon: {
                                    name: null,
                                    set: 'regular',
                                }
                            },
                        }
                    ]
                }
            ],
            style: {
                padding: {
                    top: '10',
                    bottom: '10',
                    left: '4',
                    right: '4',
                }
            }
        }]
    },
    'button/func': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    style: {
                        flex: {
                            direction: 'row',
                            justify: 'center',
                            gap: '4',
                            wrap: true
                        },
                    },
                    items: [
                        {
                            type: 'core/form/button/func',
                            spec: {
                                label: '{locale:container.example.form.button.apply}',
                                color: 'primary',
                                size: 'medium',
                                variant: 'contained',
                                icon: {
                                    name: null,
                                    set: 'regular',
                                },
                                functions: {
                                    '0': {
                                        type: 'class_toggle',
                                        spec: {values: ['hidden']}
                                    },
                                    '20': {
                                        type: 'class_toggle',
                                        spec: {values: ['hidden']}
                                    },
                                    '21': {
                                        type: 'class_toggle',
                                    },
                                    '1': {
                                        type: 'class_add',
                                    },
                                    '2': {
                                        type: 'class_remove',
                                    },
                                    '3': {
                                        type: 'attr_set',
                                    },
                                    '4': {
                                        type: 'elem_remove',
                                    },
                                    '5': {
                                        type: 'form_submit',
                                    },
                                    '6': {
                                        type: 'form_combine',
                                    },
                                    '7': {
                                        type: 'request',
                                    },
                                    '8': {
                                        type: 'alert',
                                        spec: {
                                            on: {
                                                type: 'success',
                                                text: '{locale:container.example.form.alert.success}',
                                            },
                                            off: {
                                                type: 'danger',
                                                text: '',
                                            }
                                        }
                                    },
                                    '9': {
                                        type: 'redirect',
                                    },
                                    '10': {
                                        type: 'text_copy', // copy text to clipboard
                                    },
                                    '11': {
                                        type: 'text_add',
                                    },
                                    '12': {
                                        type: 'text_replace',
                                    },
                                    '13': {
                                        type: 'logout',
                                    },
                                    '14': {
                                        type: 'button',
                                    },
                                },
                                active: {
                                    label: '{locale:container.example.form.button.cancel}',
                                    variant: null,
                                    color: null,
                                    icon: {
                                        name: null,
                                        set: 'regular',
                                    },
                                }
                            },
                        }
                    ]
                }
            ],
            style: {
                padding: {
                    top: '10',
                    bottom: '10',
                    left: '4',
                    right: '4',
                }
            }
        }]
    },
    'subscribe': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    style: {},
                    wrap: {},
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.subscribe.title}',
                                level: 'h3',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                    top: "6",
                                }
                            },
                            wrap: {},
                        },
                        {
                            type: 'core/form/subscribe',
                            spec: {
                                fields: [
                                    {
                                        name: "name",
                                        type: "text",
                                        required: true,
                                        label: "{locale:container.example.fields.first_name.label}",
                                        placeholder: "{locale:container.example.fields.first_name.placeholder}",
                                        desc: "{locale:container.example.fields.first_name.desc}",
                                        defaultValue: '{user:first_name}',
                                    },
                                    {
                                        name: "email",
                                        type: "email",
                                        required: true,
                                        label: "{locale:container.example.fields.email.label}",
                                        placeholder: "{locale:container.example.fields.email.placeholder}",
                                        desc: "{locale:container.example.fields.email.desc}",
                                        defaultValue: '{contact:email}',
                                    },
                                    {
                                        name: "phone",
                                        type: "phone",
                                        required: true,
                                        label: "{locale:container.example.fields.phone.label}",
                                        placeholder: "{locale:container.example.fields.phone.placeholder}",
                                        desc: "{locale:container.example.fields.phone.desc}",
                                        defaultValue: '{contact:phone}',
                                    },
                                ],

                                functions: {
                                    profile: true,
                                    visibility: {
                                        enable: true,
                                        auth: 'user',
                                    },
                                    list: {
                                        enable: true,
                                        code: null,
                                    },
                                    redirect: {
                                        start: '',
                                        finish: '',
                                    },
                                },

                                content: '{locale:container.example.subscribe.content}',
                                alert: '{locale:container.example.subscribe.alert}',

                                submit: {
                                    label: '{locale:container.example.subscribe.submit.label}',
                                    color: 'primary',
                                    variant: 'contained',
                                    align: 'center',
                                    expand: true,
                                },

                                reset: {
                                    label: '{locale:container.example.subscribe.unsubscribe.label}',
                                    color: 'default',
                                    variant: 'contained',
                                    alert: '{locale:container.example.subscribe.unsubscribe.alert}',
                                    text: '{locale:container.example.subscribe.unsubscribe.text}',
                                    expand: true,
                                },

                                policy: {
                                    enable: true,
                                    url: '/law/policy',
                                    text: '{locale:container.example.form.policy.text}',
                                    prefix: '{locale:container.example.form.policy.prefix}',
                                    suffix: '',
                                },


                                size: 'md',
                                orientation: 'vertical',

                                error_400: '{locale:container.example.form.error_400}',
                                error_401: '{locale:container.example.form.error_401}',
                                error_403: '{locale:container.example.subscribe.error_403}',
                                error_500: '{locale:container.example.form.error_500}',
                            },
                            wrap: {},
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'xs'
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: '4',
                    right: '4',
                }
            },
            wrap: {},
        }]
    },
    'common': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    style: {},
                    wrap: {},
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.form.title}',
                                level: 'h3',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                    top: "6",
                                }
                            },
                            wrap: {},
                        },
                        {
                            type: 'core/form/common',
                            spec: {
                                fields: [
                                    {
                                        name: "name",
                                        type: "text",
                                        required: true,
                                        label: "{locale:container.example.fields.first_name.label}",
                                        placeholder: "{locale:container.example.fields.first_name.placeholder}",
                                        desc: "{locale:container.example.fields.first_name.desc}",
                                        defaultValue: '{user:first_name}',
                                    },
                                    {
                                        name: "email",
                                        type: "email",
                                        required: true,
                                        label: "{locale:container.example.fields.email.label}",
                                        placeholder: "{locale:container.example.fields.email.placeholder}",
                                        desc: "{locale:container.example.fields.email.desc}",
                                        defaultValue: '{contact:email}',
                                    },
                                    {
                                        name: "phone",
                                        type: "phone",
                                        required: true,
                                        label: "{locale:container.example.fields.phone.label}",
                                        placeholder: "{locale:container.example.fields.phone.placeholder}",
                                        desc: "{locale:container.example.fields.phone.desc}",
                                        defaultValue: '{contact:phone}',
                                    },
                                    // {
                                    //     name: "password",
                                    //     type: "password",
                                    //     required: true,
                                    //     label: "{locale:container.example.fields.password.label}",
                                    //     placeholder: "{locale:container.example.fields.password.placeholder}",
                                    //     desc: "{locale:container.example.fields.password.desc}",
                                    // },
                                ],

                                functions: {
                                    event: true,
                                    profile: true,
                                    redirect: {
                                        enable: false,
                                        url: '',
                                    },
                                    access: {
                                        limiter: 'lesson',
                                    }
                                },

                                text: '{locale:container.example.form.text}',
                                content: '{locale:container.example.form.content}',

                                submit: {
                                    label: '{locale:container.example.form.submit.label}',
                                    color: 'primary',
                                    variant: 'contained',
                                    expand: false,
                                },

                                reset: {
                                    enable: false,
                                    label: '{locale:container.example.form.reset.label}',
                                    color: 'default',
                                    variant: 'contained',
                                    expand: false,
                                },

                                policy: {
                                    enable: false,
                                    url: '/law/policy',
                                    text: '{locale:container.example.form.policy.text}',
                                    prefix: '{locale:container.example.form.policy.prefix}',
                                    suffix: '',
                                },

                                size: 'md',
                                orientation: 'vertical',

                                error_400: '{locale:container.example.form.error_400}',
                                error_401: '{locale:container.example.form.error_401}',
                                error_403: '{locale:container.example.form.error_403}',
                                error_500: '{locale:container.example.form.error_500}',
                            },
                            wrap: {},
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'xs'
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: '4',
                    right: '4',
                }
            },
            wrap: {},
        }]
    },
    'login': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    style: {},
                    wrap: {},
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.login.title}',
                                level: 'h2',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                    top: "6",
                                }
                            },
                            wrap: {},
                        },
                        {
                            type: 'core/form/login',
                            spec: {
                                contact_type: 'email',

                                label: '{locale:container.example.login.submit.label}',
                                error_401: '{locale:container.example.login.error_401}',
                                error_500: '{locale:container.example.login.error_500}',
                                orientation: 'vertical',
                                size: 'md',

                                functions: {
                                    redirect: {
                                        enable: true,
                                        url: '',
                                    },
                                },

                                login: {
                                    label: '{locale:container.example.login.login.label}',
                                    placeholder: '{locale:common.placeholder.text}',
                                },

                                password: {
                                    label: '{locale:container.example.login.password.label}',
                                    placeholder: '{locale:common.placeholder.text}',
                                },

                                submit: {
                                    label: '{locale:container.example.login.submit.label}',
                                    color: 'primary',
                                    variant: 'contained',
                                    expand: false,
                                },

                                logout: {
                                    enable: true,
                                    text: '{locale:container.example.login.logout.text}',
                                    label: '{locale:container.example.login.logout.label}',
                                    color: 'default',
                                    variant: 'contained',
                                    expand: false,
                                },
                            },
                            wrap: {},
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'xs'
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: '4',
                    right: '4',
                }
            },
            wrap: {},
        }]
    },
    'register': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    style: {},
                    wrap: {},
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.register.title}',
                                level: 'h3',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                    top: "6",
                                }
                            },
                            wrap: {},
                        },
                        {
                            type: 'core/form/common',
                            spec: {
                                title: '{locale:container.example.register.short}',
                                fields: [
                                    {
                                        name: "name",
                                        type: "text",
                                        required: true,
                                        label: "{locale:container.example.fields.first_name.label}",
                                        placeholder: "{locale:container.example.fields.first_name.placeholder}",
                                        desc: "{locale:container.example.fields.first_name.desc}",
                                    },
                                    {
                                        name: "email",
                                        type: "email",
                                        required: true,
                                        label: "{locale:container.example.fields.email.label}",
                                        placeholder: "{locale:container.example.fields.email.placeholder}",
                                        desc: "{locale:container.example.fields.email.desc}",
                                    },
                                    {
                                        name: "phone",
                                        type: "phone",
                                        required: true,
                                        label: "{locale:container.example.fields.phone.label}",
                                        placeholder: "{locale:container.example.fields.phone.placeholder}",
                                        desc: "{locale:container.example.fields.phone.desc}",
                                    },
                                    {
                                        name: "password",
                                        type: "password",
                                        required: true,
                                        label: "{locale:container.example.fields.password.label}",
                                        placeholder: "{locale:container.example.fields.password.placeholder}",
                                        desc: "{locale:container.example.fields.password.desc}",
                                    },
                                ],

                                functions: {
                                    profile: true,
                                    redirect: {
                                        enable: true,
                                        url: '/',
                                    },
                                    visibility: {
                                        enable: true,
                                        auth: 'guest',
                                    }
                                },

                                text: '{locale:container.example.register.text}',
                                content: '{locale:container.example.register.content}',

                                submit: {
                                    label: '{locale:container.example.form.submit.label}',
                                    color: 'primary',
                                    variant: 'contained',
                                    expand: false,
                                },

                                reset: {
                                    enable: false,
                                    label: '{locale:container.example.form.reset.label}',
                                    color: 'secondary',
                                    variant: 'contained',
                                    expand: false,
                                },

                                policy: {
                                    enable: true,
                                    url: '/law/policy',
                                    text: '{locale:container.example.form.policy.rules}',
                                    prefix: '{locale:container.example.form.policy.prefix}',
                                    suffix: '',
                                },

                                size: 'md',
                                orientation: 'vertical',

                                error_400: '{locale:container.example.register.error_400}',
                                error_403: '{locale:container.example.form.error_403}',
                                error_401: '{locale:container.example.form.error_401}',
                                error_500: '{locale:container.example.form.error_500}',
                            },
                            wrap: {},
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'xs'
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: '4',
                    right: '4',
                }
            },
            wrap: {},
        }]
    },
}