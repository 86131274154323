import React, {useContext} from "react";
import {Button, Dropdown, Menu} from "antd";
import classes from './NodeOperations.module.scss'
import {SettingOutlined} from "@ant-design/icons";
import FlowContext from "../../../../FlowContext";
import {useTranslation} from "react-i18next";
import {inArray} from "../../../../../../library/functions";
import {isNodeIgnored} from "../../../../library/flowFunctions";

// let messMenuId = 0;
// export const DropBoxPlatform = () => {
//
//     let mess = [
//         { id: 0, item: <AllPlatform/>, name: 'Все платформы' },
//         { id: 1, item: <FaTelegramPlane style={{width:20, height:20, color: '#00A9FF'}}/>, name: 'Telegram' },
//         { id: 2, item: <FaVk style={{width:20, height:20, color: '#226ECC'}}/>, name: 'ВКонтакте' },
//         // { id: 3, item: <FaInstagram style={{width:20, height:20, color: '#CC1A8B'}}/>, name: 'Instagram' },
//         // { id: 4, item: <FaTiktok style={{width:20, height:20, color: '#111'}}/>, name: 'TikTok' },
//     ]
//
//     const [activemenu, setActiveMenu] = useState({id:mess[messMenuId].id, item:mess[messMenuId].item, name:mess[messMenuId].name});
//
//     const changeActivemenu = (item_id, item_menu, item_name) => {
//         setActiveMenu({id: item_id, item: item_menu, name: item_name});
//     }
//
//
//     const menu_platform = mess.map((item, index) => {
//         return (
//             {
//                 key: index,
//                 label: <div className={classes.wrapper_item_menu_mess} onClick={changeActivemenu.bind(this, item.id, item.item, item.name)}>
//                            <div style={{marginRight:7, paddingTop:3}}> {item.item} </div>
//                            <div>{item.name}</div>
//                        </div>
//             }
//         )
//     })
//
//     return (
//         <Dropdown menu={{items: menu_platform}} trigger={['click']} placement='bottomLeft' className="graph-panel-platform-dropdown">
//             <Button className={classes.drop_sidebar_pl}>
//                 <div className={classes.wrapper_button_text}>
//                     <div style={{marginRight:7, marginTop:5}}>{ activemenu.item }</div>
//                     { isMobile480 ? null : <div className={classes.activemenu_text}>{activemenu.name}</div> }
//                 </div>
//                 <DownOutlined />
//             </Button>
//         </Dropdown>
//     )
// }
//
// let langMenuId = 0;
// export const DropBoxLang = () => {
//
//     let lang = [
//         { id: 0, item: '&#127479;&#127482;' },
//         { id: 1, item: '&#127482;&#127480;' },
//         { id: 2, item: '&#127482;&#127462;' },
//     ]
//
//     const [activemenu, setActiveMenu] = useState({id:lang[langMenuId].id, item:emoji(lang[langMenuId].item).img});
//
//     const changeActivemenu = (item_id, item_menu) => {
//         setActiveMenu({id: item_id, item: emoji(item_menu).img});
//     }
//
//     const menu_lang = lang.map((item, index) => {
// //                     return (
// //                         {
// //                             key: index,
// //                             label: <div className={classes.wrapper_item_menu} onClick={changeActivemenu.bind(this, item.id, item.item)}>{emoji(item.item).img}</div>
// //                         }
// //                     )
// //                 })
//
//     return (
//         <Dropdown menu={{items: menu_lang}} trigger={['click']} placement='bottom'>
//             <Button className={classes.drop_sidebar_pl}>
//                 {activemenu.item} <DownOutlined />
//             </Button>
//         </Dropdown>
//     )
// }

export const NodeOperations = ({nodeLocalId, nodeType}) => {
    const {t} = useTranslation();
    const {onCopy, onDelete, onIgnore, onUndo, chosenNode, batchNodeEffectsUpdate} = useContext(FlowContext);

    let operations = [
        {name: 'undo', disabled: true, action: onUndo},
        {name: 'duplicate', disabled: false, action: onCopy},
        {name: 'delete', disabled: false, action: onDelete},
        {name: isNodeIgnored(chosenNode) ? 'show' : 'ignore', disabled: false, action: onIgnore}
    ]

    // if (!inArray(nodeType, ['event', 'note'])) {
    //     let name = 'ignore'
    //     if (isNodeIgnored(chosenNode)) name = 'show'
    //     operations = [...operations, {name, disabled: false, action: onIgnore}]
    // }

    if (nodeType !== 'note') {
        operations = [...operations,
            {
                name: 'stat',
                disabled: true,
                action: () => {
                    console.log('TODO: add logic: show stat');
                }
            },
            {
                name: 'expand',
                disabled: true,
                action: () => {
                    console.log('TODO: add logic: expand containers');
                    batchNodeEffectsUpdate(nodeLocalId, {collapsed: false});
                }
            },
            {
                name: 'collapse',
                disabled: true,
                action: () => {
                    console.log('TODO: add logic: expand containers');
                    batchNodeEffectsUpdate(nodeLocalId, {collapsed: true});
                }
            },
        ]
    }

    const items = operations.map((item, index) => {
        return (
            {
                key: `node-operations-item-${item.name}`,
                label: <div onClick={() => {
                    if (!item.disabled) item.action();
                }}>
                    <div
                        className={`${classes.node_operations_item_wrapper} ${item.disabled ? classes.disabled : ''}`}>
                        {t('graph.flow.panel.operation.' + item.name)}
                    </div>
                </div>
            }
        )
    })

    return (
        <Dropdown menu={{items}} trigger={['click']} placement='bottomRight'>
            <Button className={classes.node_operations_button} icon={<SettingOutlined/>}/>
        </Dropdown>
    )
}
