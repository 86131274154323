import React from 'react'
import {is} from "../../../../../../library/functions";
import {useTranslation} from "react-i18next";

export const BlockAchievementList = (spec) => {
    const {t} = useTranslation();
    if (!spec) return null;

    const list = [
        {
            title: t('container.example.achievement.0.title'),
            photo_url: t('container.example.achievement.0.photo_url'),
        },
        {
            title: t('container.example.achievement.1.title'),
            photo_url: t('container.example.achievement.1.photo_url'),
        },
        {
            title: t('container.example.achievement.2.title'),
            photo_url: t('container.example.achievement.2.photo_url'),
        }
    ];

    const classes = is(spec.cl) ? spec.cl : {};

    let itemClasses = ['c-item'];
    if (is(classes.item)) itemClasses = [...itemClasses, ...classes.item];

    let imgClasses = ['c-item-photo'];
    if (is(classes.photo)) imgClasses = [...imgClasses, ...classes.photo];

    let titleClasses = ['c-item-title'];
    if (is(classes.title)) titleClasses = [...titleClasses, ...classes.title];

    return <>
        {list.map((item) => <div className={itemClasses.join(' ')}>
            <img className={imgClasses.join(' ')} src={item.photo_url} alt={item.title}/>
            {spec.show_title ? <h3 className={titleClasses.join(' ')}>{item.title}</h3> : null}
        </div>)}
    </>
}