export const events_categories = {
    core: {
        message: [],
        bot: [],
        chat: [],
        channel: [],
        wall: [],
        site: [],
        crm: [],
        sales: [],
        game: [],
        system: [],
    },
    integration: {
        tg: [],
        vk: [],
        crm: [],
        sales: [],
        site: [],
        table: [],
        delivery: [],
        other: [],
    }
}

export const specialEventTypes = {
    message: 'event/core/message/new',
    message_send: 'event/core/message/send',
    button: 'event/core/button/press',
    webhook: 'event/core/webhook/new',
    url: 'event/core/url/open',

    payment_new: 'event/core/sales/payment/new',
}

export const events = [

    // message - part 1
    {
        title: null, // event.core.message.new.title => default: null
        type: 'event/core/message/new',
        category: ['message'],
        icon: 'message-arrow-down',
    },
    {
        type: 'event/core/button/press',
        category: ['message'],
        icon: 'hand-back-point-down',
    },

    // bot
    {
        title: null, // default = null = event.core.bot.start.title
        type: 'event/core/bot/start',
        category: ['bot'],
        icon: 'circle-play',
    },
    {
        type: 'event/core/bot/stop',
        category: ['bot'],
        icon: 'circle-stop',
    },


    {
        type: 'event/core/list/join',
        category: ['site'],
        icon: 'user-check',
    },
    {
        type: 'event/core/list/left',
        category: ['site'],
        icon: 'user-xmark',
    },
    {
        type: 'event/core/button/submit',
        category: ['site'],
        icon: 'mouse-pointer',
    },
    {
        type: 'event/core/form/submit',
        category: ['site'],
        icon: 'pen-to-square',
    },

    // common
    {
        type: 'event/core/webhook/new',
        category: ['site'],
        icon: 'code-pull-request',
    },
    {
        type: 'event/core/url/open',
        category: ['site'],
        icon: 'link',
    },
    {
        type: 'event/core/message/edit',
        category: ['message'],
        icon: 'message-pen',
    },

    // message - part 2
    // {
    //     type: 'event/core/message/attach/stories',
    //     category: ['message'],
    //     icon: 'rectangle-vertical-history',
    // },
    // {
    //     type: 'event/core/message/attach/product',
    //     category: ['message'],
    //     icon: 'money-check-pen',
    // },
    {
        type: 'event/core/message/send',
        category: ['message'],
        exclude_platforms: ['tg'], // show tooltip if platform is installed
        icon: 'message-arrow-up-right',
    },
    // {
    //     type: 'event/core/message/typing',
    //     category: ['message'],
    //     icon: 'message-dots',
    // },

    // chat
    {
        type: 'event/core/chat/message/new',
        category: ['chat','message'],
        icon: 'comments',
    },
    {
        type: 'event/core/chat/message/edit',
        category: ['chat','message'],
        icon: 'comment-pen',
    },

    // business account
    {
        type: 'event/core/user/message/new',
        category: ['message'],
        icon: 'chalkboard-user',
    },
    {
        type: 'event/core/user/message/edit',
        category: ['message'],
        icon: 'chart-user',
    },
    {
        type: 'event/core/user/message/send',
        category: ['message'],
        icon: 'message-arrow-up',
    },
    {
        type: 'event/core/user/message/fix',
        category: ['message'],
        icon: 'message-exclamation',
    },

    {
        type: 'event/core/chat/notice/member/join',
        category: ['chat'],
        icon: 'comment-plus',
    },
    {
        type: 'event/core/chat/notice/member/add',
        category: ['chat'],
        icon: 'comment-check',
    },
    {
        type: 'event/core/chat/notice/member/left',
        category: ['chat'],
        icon: 'comment-minus',
    },
    {
        type: 'event/core/chat/notice/member/kick',
        category: ['chat'],
        icon: 'comment-xmark',
    },
    {
        type: 'event/core/chat/notice/title',
        category: ['chat'],
        icon: 'message-captions',
    },
    {
        type: 'event/core/chat/notice/avatar',
        category: ['chat'],
        icon: 'message-image',
    },
    {
        type: 'event/core/chat/notice/pin',
        category: ['chat'],
        icon: 'message-exclamation',
    },
    {
        type: 'event/core/chat/notice/unpin',
        category: ['chat'],
        icon: 'message-xmark',
    },

    {
        type: 'event/core/chat/poll/answer',
        category: ['message'],
        icon: 'square-poll-horizontal',
    },

    {
        type: 'event/core/chat/member/request',
        category: ['chat'],
        icon: 'person-circle-check',
    },
    {
        type: 'event/core/chat/member/join',
        category: ['chat'],
        icon: 'person-circle-plus',
    },
    {
        type: 'event/core/chat/member/left',
        category: ['chat'],
        icon: 'person-circle-minus',
    },
    {
        type: 'event/core/chat/member/add',
        category: ['chat'],
        icon: 'images-user',
    },
    {
        type: 'event/core/chat/member/kick',
        category: ['chat'],
        icon: 'users-slash',
    },
    {
        type: 'event/core/chat/member/add/own',
        category: ['bot','chat'],
        icon: 'message-plus',
    },
    {
        type: 'event/core/chat/member/kick/own',
        category: ['bot','chat'],
        icon: 'message-minus',
    },

    {
        type: 'event/core/chat/member/promote',
        category: ['chat'],
        icon: 'user-group-crown',
    },
    {
        type: 'event/core/chat/member/demote',
        category: ['chat'],
        icon: 'user-helmet-safety',
    },
    // {
    //     type: 'event/core/chat/member/ban',
    //     category: ['chat'],
    //     icon: 'person-circle-xmark',
    // },
    {
        type: 'event/core/chat/member/promote/own',
        category: ['bot','chat'],
        icon: 'robot',
    },
    {
        type: 'event/core/chat/member/demote/own',
        category: ['bot','chat'],
        icon: 'message-bot',
    },

    // channel
    {
        type: 'event/core/channel/member/request',
        category: ['channel'],
        icon: 'person-chalkboard',
    },
    {
        type: 'event/core/channel/member/join',
        category: ['channel'],
        icon: 'square-check',
    },
    {
        type: 'event/core/channel/member/left',
        category: ['channel'],
        icon: 'square-arrow-down',
    },
    {
        type: 'event/core/channel/member/add',
        category: ['channel'],
        icon: 'square-plus',
    },
    {
        type: 'event/core/channel/member/kick',
        category: ['channel'],
        icon: 'square-minus',
    },
    {
        type: 'event/core/channel/notice/member/ban',
        category: ['channel'],
        icon: 'ban',
    },

    {
        type: 'event/core/channel/member/add/own',
        category: ['channel','bot'],
        icon: 'circle-plus',
    },
    {
        type: 'event/core/channel/member/kick/own',
        category: ['channel','bot'],
        icon: 'circle-minus',
    },

    {
        type: 'event/core/channel/member/promote',
        category: ['channel'],
        icon: 'square-a',
    },
    {
        type: 'event/core/channel/member/demote',
        category: ['channel'],
        icon: 'square-x',
    },
    {
        type: 'event/core/channel/member/promote/own',
        category: ['channel', 'bot'],
        icon: 'square-b',
    },
    // {
    //     type: 'event/core/channel/member/demote/own',
    //     category: ['channel', 'bot'],
    //     icon: 'square-quarters',
    // },

    {
        type: 'event/core/channel/notice/title',
        category: ['channel'],
        icon: 'message-lines',
    },
    {
        type: 'event/core/channel/notice/avatar',
        category: ['channel'],
        icon: 'square-user',
    },
    {
        type: 'event/core/channel/notice/pin',
        category: ['channel'],
        icon: 'paperclip',
    },
    {
        type: 'event/core/user/member/add/own',
        category: ['bot'],
        icon: 'user-plus',
    },
    {
        type: 'event/core/user/member/kick/own',
        category: ['bot'],
        icon: 'user-xmark',
    },

    // site
    // {
    //     type: 'event/core/pages/form/lead',
    //     category: ['site'],
    //     icon: 'list-check',
    // },
    // {
    //     type: 'event/core/url/open',
    //     category: ['site'],
    //     icon: 'link',
    // },
    // {
    //     type: 'event/core/site/page/visit',
    //     category: ['site'],
    //     icon: 'file-circle-check',
    // },
    // {
    //     type: 'event/core/page/new',
    //     category: ['site'],
    //     icon: 'file-circle-plus',
    // },
    // {
    //     type: 'event/core/page/edit',
    //     category: ['site'],
    //     icon: 'file-circle-exclamation',
    // },
    // {
    //     type: 'event/core/page/delete',
    //     category: ['site'],
    //     icon: 'file-slash',
    // },

    // sales
    {
        type: 'event/core/sales/payment/new',
        category: ['sales'],
        icon: 'sack-dollar',
    },
    {
        type: 'event/core/sales/order/new',
        category: ['sales'],
        icon: 'cart-shopping',
    },
    {
        type: 'event/core/sales/order/edit',
        category: ['sales'],
        icon: 'cart-plus',
    },
    {
        type: 'event/core/sales/order/delete',
        category: ['sales'],
        icon: 'cart-xmark',
    },

    // subscriptions
    {
        type: 'event/core/sales/subscription/new',
        category: ['sales'],
        icon: 'cart-circle-arrow-down',
    },
    {
        type: 'event/core/sales/subscription/update',
        category: ['sales'],
        icon: 'cart-shopping-fast',
    },
    {
        type: 'event/core/sales/subscription/cancel',
        category: ['sales'],
        icon: 'cart-circle-arrow-up',
    },
    {
        type: 'event/core/sales/subscription/finish',
        category: ['sales'],
        icon: 'cart-circle-check',
    },
    // {
    //     type: 'event/core/sales/subscription/error',
    //     category: ['sales'],
    //     icon: 'cart-circle-xmark',
    // },
    {
        type: 'event/core/sales/subscription/notice',
        category: ['sales'],
        icon: 'cart-circle-exclamation',
    },

    // app sales events
    {
        type: 'event/core/sales/order/tariff',
        category: ['sales'],
        icon: 'dragon',
        system: true,
    },
    {
        type: 'event/core/sales/order/tariff/promo',
        category: ['sales'],
        icon: 'percent',
        system: true,
    },
    {
        type: 'event/core/sales/template/install',
        category: ['sales'],
        icon: 'dolphin',
        system: true,
    },
    {
        type: 'event/core/admin/account/register',
        category: ['sales'],
        icon: 'face-smile-plus',
        system: true,
    },
    {
        type: 'event/core/admin/project/create',
        category: ['sales'],
        icon: 'rectangle-history-circle-plus',
        system: true,
    },

    // wall
    {
        type: 'event/core/post/comment/new',
        category: ['wall'],
        icon: 'comment-plus',
    },
    {
        type: 'event/core/post/comment/edit',
        category: ['wall'],
        icon: 'comment-pen',
    },
    {
        type: 'event/core/post/comment/delete',
        category: ['wall'],
        icon: 'comment-minus',
    },
    {
        type: 'event/core/post/comment/restore',
        category: ['wall'],
        icon: 'comment-arrow-down',
    },
    {
        type: 'event/core/like/new',
        category: ['wall'],
        icon: 'heart-circle-check',
    },
    {
        type: 'event/core/like/delete',
        category: ['wall'],
        icon: 'heart-circle-xmark',
    },
    {
        type: 'event/core/post/share',
        category: ['wall'],
        icon: 'share-from-square',
    },
    {
        type: 'event/core/post/new',
        category: ['wall'],
        icon: 'file-pen',
    },
    {
        type: 'event/core/post/edit',
        category: ['wall'],
        icon: 'pen-field',
    },
    // {
    //     type: 'event/core/post/delete',
    //     category: ['wall'],
    //     icon: 'trash-can',
    // },

// ---------- integrations section ----------------
    // senler
    {
        type: 'event/integration/delivery/senler/subscribe',
        category: ['delivery'],
        icon: 'envelope-circle-check',
    },
    {
        type: 'event/integration/delivery/senler/unsubscribe',
        category: ['delivery'],
        icon: 'message-slash',
    },
    {
        type: 'event/integration/delivery/senler/action',
        category: ['delivery'],
        icon: 'message-bot',
    },
    // vk
    {
        type: 'event/integration/vk/lead/forms/new',
        category: ['vk'],
        icon: 'address-card',
    },
    {
        type: 'event/integration/vk/board/post/new',
        category: ['vk'],
        icon: 'file-circle-plus',
    },
    {
        type: 'event/integration/vk/board/post/edit',
        category: ['vk'],
        icon: 'file-signature',
    },
    {
        type: 'event/integration/vk/board/post/delete',
        category: ['vk'],
        icon: 'file-circle-xmark',
    },
    {
        type: 'event/integration/vk/board/post/restore',
        category: ['vk'],
        icon: 'file-circle-check',
    },
    {
        type: 'event/integration/vk/donut/money/withdraw',
        category: ['vk'],
        icon: 'money-from-bracket',
    },
    {
        type: 'event/integration/vk/donut/money/withdraw/error',
        category: ['vk'],
        icon: 'file-invoice-dollar',
    },
    {
        type: 'event/integration/crm/getcourse/user',
        category: ['crm'],
        icon: 'person-walking-luggage',
    },
    {
        type: 'event/integration/crm/getcourse/order',
        category: ['crm'],
        icon: 'basket-shopping-simple',
    },
    {
        type: 'event/integration/crm/getcourse/purchase',
        category: ['crm'],
        icon: 'bags-shopping',
    },
]
