import React, {useContext, useState} from 'react'
import classes from './ContainerOperations.module.scss'
import {Dropdown, Menu} from "antd";
import FlowContext from "../../../../FlowContext";
import {useTranslation} from "react-i18next";
import {copyToClipboard} from "../../../../../../library/clipboard";
import InputModal from "../../../../../../components/Modal/InputModal";
import {getElementByLocalId} from "../../../../library/flowFunctions";
import {inArray, parseBool, redirect} from "../../../../../../library/functions";

const ContainerOperations = ({nodeLocalId, effectLocalId, isIgnore, helpUrl}) => {            // кнопка с тремя точками
    const {t} = useTranslation();
    const {updateNodeContainerParams, cloneNodeEffect, deleteNodeEffect, nodesState} = useContext(FlowContext);
    const [isVisibleModal, setVisibleModal] = useState(false);

    // TODO: optimize!
    const node = getElementByLocalId(nodesState, nodeLocalId);
    let effect = null;
    if (!inArray(node?.type, ['event', 'condition']) && node?.effects) {
        effect = getElementByLocalId(node.effects, effectLocalId)
    }
    const ignore_errors = parseBool(effect?.ignore_errors);
    // console.log('effect', effect, node?.type, effect?.ignore_errors)

    const renameEffect = (value) => {
        updateNodeContainerParams(nodeLocalId, effectLocalId, {title: value}, true, true);
    }

    let item_menu = [
        {
            type: 'rename', action: () => {
                setVisibleModal(true);
            }
        },
        {
            type: isIgnore ? 'on' : 'off', action: () => {
                updateNodeContainerParams(nodeLocalId, effectLocalId, {ignore: !isIgnore}, true);
            }
        },
        {
            type: 'doc', action: () => {
                redirect(helpUrl, '_blank');
            }
        },
        {
            type: 'copy_number', action: () => {
                copyToClipboard(effectLocalId);
            }
        },
        effect ? {
            type: ignore_errors ? 'errors_on' : 'errors_off', action: () => {
                updateNodeContainerParams(nodeLocalId, effectLocalId, {ignore_errors: !ignore_errors}, true);
            }
        } : null,
        {
            type: 'clone', action: () => {
                cloneNodeEffect(nodeLocalId, effectLocalId);
            }
        },
        {
            type: 'delete', action: () => {
                deleteNodeEffect(nodeLocalId, effectLocalId);
            }
        },
    ]

    const onItemMenuClick = (action) => action(effectLocalId);

    const menu = (
        <Menu style={{borderRadius: 6}}
              items={item_menu.map((item, index) => {
                  if (!item) return null;
                  return ({
                      key: index,
                      label: <div onClick={onItemMenuClick.bind(this, item.action)}>
                          {t('graph.flow.container.operation.' + item.type)}
                      </div>
                  })
              })}
        />
    )

    return (
        <div className={classes.wrapper_button_action}>
            <Dropdown
                overlay={menu}
                trigger={['click']}
                placement='bottomRight'
                getPopupContainer={() => document.getElementById('effects-list-wrapper')}
            >
                <div className={classes.action_content}>
                    <div className={classes.three_point}>...</div>
                </div>
            </Dropdown>

            <InputModal
                initValue={''}
                isVisible={isVisibleModal}
                setVisible={setVisibleModal}
                onModalOk={renameEffect}
                title={t('common.action.rename')}
                placeholder={t('common.form.name.placeholder')}
                maxLength={35}
            />
        </div>
    )
}

export default ContainerOperations


