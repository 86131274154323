import React, {useEffect, useState} from 'react'
import {dotenv} from "../../config/config";
import {
    Button,
    Input,
    Col,
    Form,
    Layout,
    Row,
    Tooltip,
    Typography,
    InputNumber,
    Slider,
    Checkbox
} from "antd";
import {useDispatch, useSelector} from "react-redux";

import {
    getUtcDateTime,
    momentFromUnix,
    deepGet,
    redirect,
    inArray,
    addUniqueValueToArray,
    removeValueFromArray,
    roundPrice,
    numberForHuman,
    createMarkup,
    toNum,
} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {useTranslation} from "react-i18next";
import {Banner} from "../../components/Banner/Banner";
import {CustomHeader} from "../../components/Layout/CustomHeader";
import {ContentCard} from "../../components/Card/Content/ContentCard";
import {
    CheckOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    ShoppingOutlined,
    SettingOutlined,
    ThunderboltOutlined,
} from "@ant-design/icons";
import {reachGoal} from "../../library/metrics";
import InputModal from "../../components/Modal/InputModal";
import {SimpleModal} from "../../components/Modal/SimpleModal";
import {FormItemWrapper} from "../../components/Form/FormItemWrapper";
import {FormItemLabel} from "../../components/Form/FormItemLabel";
import {NoForm} from "../../components/Form/NoForm";
import {notice} from "../../library/notice";
import {LabelSwitch} from "../../components/Form/Field/LabelSwitch";

const {Content} = Layout;
const {Title, Text} = Typography;

const Tariff = () => {
    const {t} = useTranslation()
    const section = 'tariff'

    // data from URL params
    // const params = useParams()
    // const project_id = Number(params.project_id)

    // init hooks
    const dispatch = useDispatch()

    // const [checkedSections, setCheckedSections] = useState(['graph', 'templates', 'gamification', 'sales'])
    const [aiForm] = Form.useForm();
    const [orderForm] = Form.useForm();
    const [checkedSections, setCheckedSections] = useState([])
    const [pageParams, setPageParams] = useState({
        messages: {
            limit: 0,
            batch: 0,
        },
        amount: {
            months: 1,
            order: 0,
            project: 0,
            discount: 0,
        },
        ai: {
            amount: 0,
            from_project: false,
        },
        order: {
            phone: '',
            subscribe: false,
            auto: false,
            to_project: false,
        },
        modal: {
            tariff: false,
            order: false,
            promo: false,
            project: false,
            ai: false,
        },
        view: {
            tight: false,
        },
        tariff: {
            type: 'business',
        },
        promo: {
            show: false,
            bonus: 10,
            code: '',
            name: '',
            value: 0,
        },
    })

    // sync with store
    const {admin, project, userField, order, discount} = useSelector(store => store)
    const project_item = project.item
    const balance = deepGet(userField, 'balance.value', 0)
    const coins = deepGet(userField, 'coins.value', 0)
    const projectTariffType = deepGet(project_item, 'params.tariff.type', 'business');
    const discountItem = discount.list.length ? discount.list[0] : {'id': 0, 'amount': 10, 'name': ''}

    useEffect(() => {
        if (admin.authorized && admin.token) {
            dispatch({
                type: 'getUserFieldSet',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    field_names: ['balance', 'coins'],
                }
            })

            dispatch({
                type: 'getDiscountList',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    product_id: dotenv.main_product,
                    partner_user_ids: [admin.user.id],
                    page_size: 1
                }
            })
        }
        //eslint-disable-next-line
    }, [admin])

    useEffect(() => {
        setState('tariff.type', projectTariffType)
        //eslint-disable-next-line
    }, [projectTariffType])

    useEffect(() => {
        if (order.url) redirect(order.url)
        // if (order.url) console.log('order.url', order.url)
        // else if (order.url && !order.loading.balance) dispatch(setOrderUrl(''))
        //eslint-disable-next-line
    }, [order.url])

    useEffect(() => {
        if (discountItem.id) {
            // console.log('discountItem.id', discountItem.id)
            // setState('promo.name', discountItem.name)
            // setState('promo.bonus', discountItem.amount)

            setPageParams({
                ...pageParams,
                promo: {
                    ...pageParams.promo,
                    name: discountItem.is_on ? discountItem.name : '',
                    bonus: discountItem.amount,
                }
            })
        }
        //eslint-disable-next-line
    }, [discountItem.id])

    // local locale func
    const l = (c) => t('project.' + section + '.' + c);

    const setState = (key, value) => {
        // console.log('setState', key, value)

        const keys = key.split('.');
        const firstKey = keys.shift();
        const lastKey = keys.pop();

        let newSection = {...pageParams[firstKey]};
        newSection[lastKey] = value;
        setPageParams({...pageParams, [firstKey]: newSection});
    }

    const onTariffFormSubmit = () => {
        // const tariffPrice = getTariffPrice();
        // console.log('onTariffFormSubmit', tariffPrice)

        const tariffData = {
            project_id: project_item.id,
            sections: checkedSections,
            permanent_messages: pageParams.messages.batch,
            daily_count: pageParams.messages.limit,
            month_count: pageParams.amount.months,
        }

        // catch metrics goal
        reachGoal('tariff', tariffData)

        dispatch({
            type: 'purchaseAppTariff',
            admin,
            data: tariffData
        })

        setPageParams({
            ...pageParams,
            messages: {...pageParams.messages, limit: 0, batch: 0},
            amount: {...pageParams.amount, months: 1, order: 0, project: 0, discount: 0},
        })
        setCheckedSections([])
    }

    const onOrderFormSubmit = () => {
        // console.log('onOrderFormSubmit', value)

        // catch metrics goal
        reachGoal('order', {order_price: pageParams.amount.order})

        const orderValues = orderForm.getFieldsValue();
        // console.log('orderValues', orderValues)
        const data = {
            // title: l('account.amount.product_title'),
            price: orderValues.amount,
            promo: orderValues.promo,
            user_phone: orderValues.phone,
            related_project_id: project_item.id,
            subscribe: pageParams.order.subscribe,
            renew_tariff: pageParams.order.auto,
            to_project: pageParams.order.to_project,
        }
        // console.log('onOrderFormSubmit data', data)
        dispatch({type: 'createAppOrder', admin, data})
    }

    const onOk = () => {
        orderForm.submit()
    }

    const setProjectTariff = () => {
        dispatch({
            type: 'setAppTariff',
            admin,
            data: {
                project_id: project_item.id,
                tariff: pageParams.tariff.type,
            }
        })
    }

    const onAiOk = () => {
        aiForm.submit()
    }

    const onAiFormSubmit = (values) => {
        // console.log('onAiFormSubmit values', values)
        dispatch({
            type: 'increaseProjectAiBalance',
            admin,
            data: {
                project_id: project_item.id,
                amount: values.amount,
                from_project: !!values.from_project
            }
        })
    }

    const onProjectFormSubmit = (value) => {
        // console.log('onProjectFormSubmit', pageParams.amount.project, value)

        // catch metrics goal
        reachGoal('projectBalance', {order_price: value})

        dispatch({
            type: 'increaseProjectBalance',
            admin,
            data: {
                project_id: project_item.id,
                amount: value,
            }
        })
    }

    const onPromoSave = () => {
        // console.log('promoSave', pageParams.promo.name, pageParams.promo.bonus)
        if (!admin.user.id) {
            notice.error('Admin not authorized')
            return
        }

        const discountData = {
            project_id: dotenv.main_project,
            product_id: dotenv.main_product,
            title: l('account.discount.item.title') + ': ' + pageParams.promo.name,
            // name: pageParams.promo.name,
            amount: pageParams.promo.bonus,
            partner_user_id: admin.user.id,
            mode: 'percent',
            params: {
                bonus: {
                    partner: {
                        currency: 'coins',
                        mode: 'percent',
                        amount: Math.round((30 - pageParams.promo.bonus) * 10, 2),
                        limit: 0,
                    }
                }
            }
        }

        if (discountItem.id) {
            if (pageParams.promo.name) {
                discountData['is_on'] = true
                discountData['name'] = pageParams.promo.name
            } else discountData['is_on'] = false

            dispatch({
                type: 'updateDiscount', admin, data: {
                    id: discountItem.id,
                    ...discountData
                }
            })
        } else {
            if (!pageParams.promo.name) {
                notice.info(l('account.promo.error.name_is_empty'))
                return
            }
            discountData['name'] = pageParams.promo.name
            dispatch({type: 'createDiscount', admin, data: discountData})
        }
    }

    const onSectionChecked = (event) => {
        // console.log(event.target)
        const section_name = event.target.name;
        let checked_sections = [...checkedSections];
        // console.log('event.target.checked', event.target.checked)
        if (event.target.checked) checked_sections = addUniqueValueToArray(section_name, checked_sections)
        else checked_sections = removeValueFromArray(section_name, checked_sections)
        // console.log('checked_sections', checked_sections)
        setCheckedSections(checked_sections)
    }

    const now = getUtcDateTime();
    let allowed_sections = [];

    if (project_item['allowed_sections']) {
        for (const allowedSection of project_item['allowed_sections']) {
            if (allowedSection.expire_at > now) {
                allowed_sections.push({
                    section: allowedSection.section,
                    expire: momentFromUnix(allowedSection.expire_at, l('access.expire_at') + " D MMMM YYYY " + l('access.expire_year')),
                })
            }
        }
    }

    const isDeveloper = projectTariffType === 'developer' || pageParams.tariff.type === 'developer';
    const sectionsRows = [
        {
            key: 'graph',
            title: l('access.section.graph'),
            price: isDeveloper ? dotenv.price['graph'][1] : dotenv.price['graph'][0],
            disabled: projectTariffType !== pageParams.tariff.type,
        },
        {
            key: 'page',
            title: l('access.section.page'),
            price: isDeveloper ? dotenv.price['page'][1] : dotenv.price['page'][0],
            disabled: projectTariffType !== pageParams.tariff.type,
        },
    ];

    const getTariffPrice = () => {
        let price = pageParams.messages.limit * dotenv.daily_price;
        for (const checkedSection of checkedSections) {
            const sect = sectionsRows.find(e => e.key === checkedSection)
            // console.log('sect', sect, checkedSection)
            if (sect) price += sect.price
        }

        price *= pageParams.amount.months
        price += pageParams.messages.batch * dotenv.permanent_price;

        return price
    }

    const tariffPrice = getTariffPrice();
    const proj_balance = parseFloat(toNum(project_item.balance))
    const ai_balance = parseFloat(toNum(project_item.ai_balance))
    const coins_price = roundPrice(parseInt(coins) / dotenv.coins_divider)
    const isEnough = tariffPrice <= proj_balance + balance + coins_price;
    // const orderPrice = tariffPrice - proj_balance - balance - coins_price;

    let admin_phones = []
    if (admin.user && admin.user.contacts) admin_phones = admin.user.contacts.filter(e => e.type === 'phone')
    const admin_phone = admin_phones.length ? admin_phones[0].value : ''

    const showOrderModal = () => {
        const currentValues = orderForm.getFieldsValue();

        const newValues = {
            amount: currentValues.amount > 0 ? currentValues.amount : tariffPrice,
            phone: currentValues.phone ?? admin_phone,
        }

        orderForm.setFieldsValue(newValues);
        setPageParams({
            ...pageParams,
            amount: {...pageParams.amount, order: newValues.amount},
            modal: {...pageParams.modal, order: true},
        })
    }

    const onOrderFormChange = (changedValues) => {
        if (changedValues.amount !== undefined) setState('amount.order', toNum(changedValues.amount));
    }

    const onAiFormChange = (changedValues) => {
        if (changedValues.amount !== undefined) setState('ai.amount', toNum(changedValues.amount));
    }

    const renderTariffDesc = (classes = 'margin-top-ps') => {
        if (projectTariffType !== pageParams.tariff.type) {
            return <div
                className={classes}
                dangerouslySetInnerHTML={createMarkup(l('type.' + pageParams.tariff.type + '.ad'))}
            />
        } else {
            return (<div className={classes}>
                {l('type.info.details')}:<br/>

                {projectTariffType === 'business' ?
                    <span dangerouslySetInnerHTML={createMarkup(t('project.tariff.type.info.messages', {
                        postProcess: 'sprintf',
                        sprintf: [
                            project_item.daily_count,
                            momentFromUnix(project_item.tariff_expire_at, "D MMMM YYYY"),
                        ]
                    }))}/> : null}

                {allowed_sections.map((section_item) => {
                    return <span key={section_item.section}>
                        <span
                            dangerouslySetInnerHTML={createMarkup(l('type.info.' + section_item.section))}
                        /> {section_item.expire}<br/>
                    </span>
                })} {allowed_sections.length ? <br/> : null}

                <span dangerouslySetInnerHTML={createMarkup(l('type.' + pageParams.tariff.type + '.desc'))}/>
            </div>)
        }
    }

    return (
        <AppWrapper className="bg-gray-light">
            <Layout className="site-layout site-layout-transparent">
                <Banner image="universe"/>
                <CustomHeader
                    key="tariff-header"
                    className={'padding-none-horizontal'}
                    title={l('title')}
                />
                <Content className="page-container container-with-cards padding-none-vertical">
                    <Row gutter={30} align="stretch">
                        {/* left column start */}
                        <Col xs={24} xl={12} className="margin-top-pm xs-margin-top-none">
                            <ContentCard flat_xs={true} className="">
                                {/* Кнопки оплаты: margin-top-md*/}
                                <Title className="" level={2}>{l('account.title')}</Title>

                                <div className="visible-xxl">
                                    {/*Баланс вашего аккаунта*/}
                                    <Row gutter={18} align="stretch" className="margin-top-pm">
                                        <Col xs={24} sm={13} xxl={11}>
                                            <div className="ant-typography ant-typography-secondary font-size-md"
                                                 style={{marginTop: 4}}>
                                                {l('account.balance.title')}
                                                <Tooltip title={l('account.balance.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={11} xxl={7}>
                                            <Title level={3} className="inline color-secondary"
                                                   style={{fontWeight: 400}}>
                                                {numberForHuman(roundPrice(balance))} {t('common.money.abbreviation')}
                                            </Title>
                                        </Col>
                                        <Col xs={24} sm={24} xxl={6}>
                                            <Button
                                                // className="btn-bordered"
                                                type="primary"
                                                loading={order.loading.balance}
                                                //disabled={pageParams.amount.order < 100}
                                                icon={<ShoppingOutlined/>}
                                                onClick={showOrderModal}
                                            >{t('common.action.add_funds')}</Button>
                                        </Col>
                                    </Row>

                                    {/*Баланс проекта*/}
                                    <Row gutter={18} align="stretch" className="margin-top-xp">
                                        <Col xs={24} sm={13} xxl={11}>
                                            <div className="ant-typography ant-typography-secondary font-size-md"
                                                 style={{marginTop: 4}}>
                                                {l('account.project.title')}
                                                <Tooltip title={l('account.project.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={11} xxl={7}>
                                            <Title level={3} className="inline color-secondary"
                                                   style={{fontWeight: 400}}>
                                                {numberForHuman(roundPrice(proj_balance))} {t('common.money.abbreviation')}
                                            </Title>
                                        </Col>
                                        <Col xs={24} sm={24} xxl={6}>
                                            <Tooltip
                                                title={!balance ? l('calc.pay.not_enough_balance') : ''}
                                                placement="topRight"
                                            >
                                                <div className="inline">
                                                    <Button
                                                        className="btn-bordered"
                                                        type="default"
                                                        loading={order.loading.project}
                                                        //disabled={true}
                                                        disabled={!balance}
                                                        icon={<PlusOutlined/>}
                                                        onClick={() => setState('modal.project', true)}
                                                    >{t('common.action.add_funds')}</Button>
                                                </div>
                                            </Tooltip>
                                        </Col>
                                    </Row>

                                    {/* Add funds to project AI balance */}
                                    {projectTariffType === 'business' ? <Row
                                        gutter={18}
                                        align="stretch"
                                        className="margin-top-xp"
                                    >
                                        <Col xs={24} sm={13} xxl={11}>
                                            <div className="ant-typography ant-typography-secondary font-size-md"
                                                 style={{marginTop: 4}}>
                                                {l('account.ai.title')}
                                                <Tooltip title={l('account.ai.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={11} xxl={7}>
                                            <Title level={3} className="inline color-secondary"
                                                   style={{fontWeight: 400}}>
                                                {numberForHuman(roundPrice(ai_balance))} {t('common.money.abbreviation')}
                                            </Title>
                                        </Col>
                                        <Col xs={24} sm={24} xxl={6}>
                                            <Tooltip
                                                title={(balance + proj_balance) <= 0 ? l('calc.pay.not_enough_desc') : ''}
                                                placement="topRight"
                                            >
                                                <div className="inline">
                                                    <Button
                                                        className="btn-bordered"
                                                        type="default"
                                                        loading={order.loading.ai}
                                                        //disabled={true}
                                                        disabled={(balance + proj_balance) <= 0}
                                                        icon={<PlusOutlined/>}
                                                        onClick={() => setState('modal.ai', true)}
                                                    >{t('common.action.add_funds')}</Button>
                                                </div>
                                            </Tooltip>
                                        </Col>
                                    </Row> : null}
                                </div>

                                <div className="hidden-xxl">

                                    {/*Баланс вашего аккаунта*/}
                                    <div className="margin-top-sm">
                                        <div className="color-secondary font-size-md float-left margin-right-ps">
                                            <span className="hidden-xs">
                                                {l('account.balance.title')}
                                                <Tooltip title={l('account.balance.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="visible-xs">
                                                {l('account.balance.short_title')}
                                                <Tooltip title={l('account.balance.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                        </div>
                                        <div className="float-right">
                                            <Button
                                                type="primary"
                                                loading={order.loading.balance}
                                                //disabled={pageParams.amount.order < 100}
                                                icon={<ShoppingOutlined/>}
                                                onClick={showOrderModal}
                                            >{t('common.action.add_funds')}</Button>
                                        </div>
                                        <Title level={4} className="inline color-secondary"
                                               style={{fontWeight: 400, marginTop: 0, marginBottom: 0, minWidth: 85}}>
                                            {numberForHuman(roundPrice(balance))} {t('common.money.abbreviation')}
                                        </Title>
                                    </div>

                                    {/*Баланс проекта*/}
                                    <div className="margin-top-sm">
                                        <div className="color-secondary font-size-md float-left margin-right-ps">
                                            <span className="hidden-xs">
                                                {l('account.project.title')}

                                                <Tooltip title={l('account.project.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="visible-xs">
                                                {l('account.project.short_title')}
                                                <Tooltip title={l('account.project.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                        </div>

                                        <div className="float-right">
                                            <Tooltip
                                                title={!balance ? l('calc.pay.not_enough_balance') : ''}
                                                placement="topRight"
                                            >
                                                <div className="inline">
                                                    <Button
                                                        className="btn-bordered"
                                                        type="default"
                                                        loading={order.loading.project}
                                                        //disabled={true}
                                                        disabled={!balance}
                                                        icon={<PlusOutlined/>}
                                                        onClick={() => setState('modal.project', true)}
                                                    >{t('common.action.add_funds')}</Button>
                                                </div>
                                            </Tooltip>
                                        </div>

                                        <Title level={4} className="inline color-secondary"
                                               style={{fontWeight: 400, marginTop: 0, marginBottom: 0, minWidth: 85}}>
                                            {numberForHuman(roundPrice(proj_balance))} {t('common.money.abbreviation')}
                                        </Title>
                                    </div>

                                    {/* Add funds to project AI balance */}
                                    {projectTariffType === 'business' ? <div className="margin-top-sm">
                                        <div className="color-secondary font-size-md float-left margin-right-ps">
                                            <span className="hidden-xs">
                                                {l('account.ai.title')}

                                                <Tooltip title={l('account.ai.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="visible-xs">
                                                {l('account.ai.short_title')}
                                                <Tooltip title={l('account.ai.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                        </div>

                                        <div className="float-right">
                                            <Tooltip
                                                title={(balance + proj_balance) <= 0 ? l('calc.pay.not_enough_desc') : ''}
                                                placement="topRight"
                                            >
                                                <div className="inline">
                                                    <Button
                                                        className="btn-bordered"
                                                        type="default"
                                                        loading={order.loading.ai}
                                                        //disabled={true}
                                                        disabled={(balance + proj_balance) <= 0}
                                                        icon={<PlusOutlined/>}
                                                        onClick={() => setState('modal.ai', true)}
                                                    >{t('common.action.add_funds')}</Button>
                                                </div>
                                            </Tooltip>
                                        </div>

                                        <Title
                                            level={4}
                                            className="inline color-secondary"
                                            style={{fontWeight: 400, marginTop: 0, marginBottom: 0, minWidth: 85}}
                                        >
                                            {numberForHuman(roundPrice(ai_balance))} {t('common.money.abbreviation')}
                                        </Title>
                                    </div> : null}
                                </div>
                                <div id="modals">
                                    {/* Add funds to project balance */}
                                    <InputModal
                                        initValue={''}
                                        isVisible={pageParams.modal.project}
                                        setVisible={(value) => {
                                            setState('modal.project', value)
                                        }}
                                        onModalOk={onProjectFormSubmit}
                                        title={l('account.project.modal.title')}
                                        placeholder={t('common.placeholder.amount')}
                                        loading={order.loading.project}
                                    />

                                    {/* Add funds to account balance */}
                                    <SimpleModal
                                        isVisible={pageParams.modal.order}
                                        setVisible={(value) => {
                                            setState('modal.order', value)
                                        }}
                                        onOk={onOk}
                                        title={l('account.balance.modal.title')}
                                        loadingOnOk={order.loading.balance}
                                        disabled={pageParams.amount.order === 0} // pageParams.amount.order === 0 && tariffPrice === 0
                                    >
                                        <Form
                                            id="order-form"
                                            name="order-form"
                                            layout="vertical"
                                            form={orderForm}
                                            onValuesChange={onOrderFormChange}
                                            onFinish={onOrderFormSubmit}
                                        >
                                            <FormItemWrapper className="" desc={l('account.balance.modal.amount.desc')}>
                                                <Form.Item
                                                    name="amount"
                                                    label={l('account.balance.modal.amount.label')}
                                                >
                                                    <InputNumber
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        className="always-show-arrows"
                                                        min={0}
                                                        step={1000}
                                                        placeholder="1000"
                                                        // onChange={(value) => setState('amount.order', value)}
                                                        // defaultValue={tariffPrice ? tariffPrice : null}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper className="" desc={l('account.balance.modal.promo.desc')}>
                                                <Form.Item name="promo" label={l('account.balance.modal.promo.label')}>
                                                    <Input
                                                        showCount={true}
                                                        maxLength={30}
                                                        placeholder={t('common.placeholder.text')}
                                                        autoComplete='off'
                                                        // onChange={(event) => {
                                                        //     // console.log('balance.modal.promo', event.target.value)
                                                        //     setState('promo.code', event.target.value)
                                                        // }}
                                                        defaultValue={pageParams.promo.code}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper desc={l('account.balance.modal.phone.desc')}>
                                                <Form.Item name="phone" label={l('account.balance.modal.phone.label')}>
                                                    <Input
                                                        inputMode="numeric"
                                                        pattern="\+*[0-9]*"
                                                        placeholder={t('common.placeholder.text')}
                                                        // placeholder="+7 987 654 32 10"
                                                        // onChange={(event, val) => {
                                                        //     console.log('value', event, val)
                                                        //     setState('order.phone', event.target.value)
                                                        // }}
                                                        defaultValue={admin_phone}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper desc={l('account.balance.modal.to_project.desc')}>
                                                <Form.Item name="to_project">
                                                    <LabelSwitch
                                                        label={l('account.balance.modal.to_project.label')}
                                                        onChange={(value) => setState('order.to_project', value)}
                                                        checked={pageParams.order.to_project}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper desc={l('account.balance.modal.subscribe.desc')}>
                                                <Form.Item name="subscribe">
                                                    <LabelSwitch
                                                        label={l('account.balance.modal.subscribe.label')}
                                                        onChange={(value) => setState('order.subscribe', value)}
                                                        checked={pageParams.order.subscribe}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper
                                                desc={l('account.balance.modal.auto.desc')}
                                                className={pageParams.order.subscribe ? '' : 'hide'}
                                            >
                                                <Form.Item name="auto">
                                                    <LabelSwitch
                                                        label={l('account.balance.modal.auto.label')}
                                                        onChange={(value) => setState('order.auto', value)}
                                                        checked={pageParams.order.auto}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>
                                        </Form>
                                    </SimpleModal>

                                    {/* Get Coins */}
                                    <SimpleModal
                                        isVisible={pageParams.modal.promo}
                                        setVisible={(value) => {
                                            setState('modal.promo', value)
                                        }}
                                        onOk={onPromoSave}
                                        actionLabel="save"
                                        title={l('account.promo.modal.title')}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: l('account.promo.modal.desc')}}/>
                                        <NoForm className="margin-top-sm">
                                            <FormItemWrapper className="" desc={l('account.promo.modal.field.desc')}>
                                                <FormItemLabel label={l('account.promo.modal.field.label')}/>
                                                <Input
                                                    showCount={true}
                                                    maxLength={30}
                                                    placeholder={t('common.placeholder.text')}
                                                    autoComplete='off'
                                                    defaultValue={pageParams.promo.name}
                                                    onBlur={(e) => {
                                                        setState('promo.name', e.target.value)
                                                    }}
                                                    onChange={(value) => {
                                                        setState('promo.name', value)
                                                    }}
                                                />
                                            </FormItemWrapper>
                                            <div className="float-right">{pageParams.promo.bonus}%</div>
                                            <Text>{l('account.promo.modal.slider.label')}</Text>
                                            <Slider
                                                className="primary"
                                                min={0}
                                                max={30}
                                                step={1}
                                                onChange={(value) => setState('promo.bonus', value)}
                                                value={pageParams.promo.bonus}
                                                defaultValue={pageParams.promo.bonus}
                                            />
                                            <div className="margin-top-sm">
                                                <div dangerouslySetInnerHTML={
                                                    {
                                                        __html: t('project.' + section + '.account.promo.modal.slider.desc', {
                                                            postProcess: 'sprintf',
                                                            sprintf: [
                                                                numberForHuman((30 - pageParams.promo.bonus) / 100 * 1000 * 10)
                                                            ]
                                                        })
                                                    }
                                                }/>
                                            </div>
                                        </NoForm>
                                    </SimpleModal>

                                    {/* Add funds to project AI balance */}
                                    <SimpleModal
                                        isVisible={pageParams.modal.ai}
                                        setVisible={(value) => {
                                            setState('modal.ai', value)
                                        }}
                                        onOk={onAiOk}
                                        title={l('account.ai.modal.title')}
                                        loadingOnOk={order.loading.ai}
                                        disabled={pageParams.ai.amount <= 0 || (
                                            pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > proj_balance ||
                                            !pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > balance
                                        )}
                                    >
                                        <Form
                                            id="ai-balance-form"
                                            name="ai-balance-form"
                                            layout="vertical"
                                            form={aiForm}
                                            onValuesChange={onAiFormChange}
                                            onFinish={onAiFormSubmit}
                                        >
                                            <FormItemWrapper className="" desc={l('account.balance.modal.amount.desc')}>
                                                <Form.Item
                                                    name="amount"
                                                    label={l('account.balance.modal.amount.label')}
                                                >
                                                    <InputNumber
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        className="always-show-arrows"
                                                        min={0}
                                                        step={1000}
                                                        placeholder="1000"
                                                        // onChange={(value) => setState('amount.order', value)}
                                                        // defaultValue={tariffPrice ? tariffPrice : null}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper desc={l('account.ai.modal.from_project.desc')}>
                                                <Form.Item name="from_project">
                                                    <LabelSwitch
                                                        label={l('account.ai.modal.from_project.label')}
                                                        onChange={(value) => setState('ai.from_project', value)}
                                                        checked={pageParams.ai.from_project}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>
                                        </Form>

                                        {pageParams.ai.amount > 0 && (
                                            pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > proj_balance ||
                                            !pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > balance
                                        ) ? <p
                                            className="color-danger"
                                        >
                                            {l('account.ai.modal.not_enough')}
                                        </p> : null}

                                    </SimpleModal>
                                </div>
                            </ContentCard>
                            <ContentCard flat_xs={true} className="margin-top-pm">
                                {/* Текущий тариф */}
                                <Title level={2}>
                                    {l('type.title') + ': «' + l('type.' + projectTariffType + '.title') + '»'}
                                </Title>
                                <div className="margin-top-ps">
                                    <Button
                                        icon={<ThunderboltOutlined/>}
                                        type={pageParams.tariff.type === 'business' ? 'primary' : 'default'}
                                        onClick={() => setState('tariff.type', 'business')}
                                        className="btn-default-gray margin-right-xp fix-height"
                                    >
                                        {l('type.business.title')}
                                    </Button>
                                    <Button
                                        icon={<SettingOutlined/>}
                                        type={pageParams.tariff.type === 'business' ? 'default' : 'primary'}
                                        onClick={() => setState('tariff.type', 'developer')}
                                        className="btn-default-gray fix-height"
                                    >
                                        {l('type.developer.title')}
                                    </Button>
                                    {/*<Button icon={<Fa icon="rocket" set="regular" antd={true}/>} type="primary">*/}
                                    {/*    {l('type.business.title')}*/}
                                    {/*</Button>*/}
                                </div>

                                {/* Описание тарифа */}
                                {renderTariffDesc()}

                                <div className="margin-top-sm margin-bottom-xp">
                                    <Button
                                        icon={<CheckOutlined/>}
                                        type="primary"
                                        className="btn-bordered"
                                        disabled={projectTariffType === pageParams.tariff.type}
                                        onClick={() => setState('modal.tariff', true)}
                                    >
                                        {projectTariffType === pageParams.tariff.type ? l('type.already') : l('type.apply')}
                                    </Button>

                                    <SimpleModal
                                        isVisible={pageParams.modal.tariff}
                                        setVisible={(value) => {
                                            setState('modal.tariff', value)
                                        }}
                                        loading={order.loading.tariff}
                                        onOk={setProjectTariff}
                                        title={l('type.modal.title')}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: l('type.modal.desc')}}/>
                                    </SimpleModal>
                                </div>

                            </ContentCard>

                            {/*<ContentCard flat_xs={true} className="margin-top-pm">*/}
                            {/*    */}
                            {/*</ContentCard>*/}

                        </Col>

                        {/* right column start */}
                        <Col xs={24} xl={12} className="margin-top-pm xs-margin-top-none" id="tariff-column">
                            {/*className="full-height"*/}
                            <ContentCard flat_xs={true}>
                                {/* Калькулятор тарифа */}
                                <Title level={2}>{l('calc.heading')}</Title>
                                <div className={isDeveloper ? 'hide' : ''}>
                                    <div className="margin-top-ps"/>
                                    <InputNumber
                                        inputMode="numeric"
                                        className="float-right align-right always-show-arrows"
                                        bordered={false}
                                        min={0}
                                        step={500}
                                        placeholder="1000"
                                        // formatter={(value) => `${value}/д.`}
                                        onChange={(value) => setState('messages.limit', value)}
                                        value={pageParams.messages.limit}
                                        style={{
                                            width: 100,
                                            maxWidth: '50%',
                                        }}
                                    />
                                    <Text>{l('calc.limit')}</Text>
                                    <Slider
                                        className="primary"
                                        min={0}
                                        max={100000}
                                        step={500}
                                        onChange={(value) => setState('messages.limit', value)}
                                        value={pageParams.messages.limit}
                                    />

                                    {/*<div className="margin-top-ps"/>
                                    <InputNumber
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="float-right align-right always-show-arrows"
                                        bordered={false}
                                        min={0}
                                        step={500}
                                        placeholder="1000"
                                        // formatter={(value) => `${value}/д.`}
                                        onChange={(value) => setState('messages.batch', value)}
                                        value={pageParams.messages.batch}
                                        style={{
                                            width: 100,
                                            maxWidth: '50%',
                                        }}
                                    />
                                    <Tooltip title={l('stat.permanent_desc')} placement="topLeft">
                                        <Text className="margin-right-xs">{l('calc.batch')} </Text>
                                        <Text type="secondary"><QuestionCircleOutlined className="font-size-xs"/></Text>
                                    </Tooltip>
                                    <Slider
                                        className="primary"
                                        min={0}
                                        max={50000}
                                        step={500}
                                        onChange={(value) => setState('messages.batch', value)}
                                        value={pageParams.messages.batch}
                                    />*/}
                                </div>

                                {/* Доступ к разделам */}
                                <div className={isDeveloper ? "margin-top-sm" : "margin-top-md"}>
                                    <div className="margin-bottom-xp">{l('calc.sections')}:</div>
                                    {sectionsRows.map((section_item) => {
                                        return <div key={section_item.key} className="checkbox-row">
                                            <span className="float-right">
                                                {section_item.price} {t('common.money.symbol')}
                                            </span>
                                            <Checkbox
                                                className="fix-checkbox-align checkbox-small"
                                                name={section_item.key}
                                                onChange={onSectionChecked}
                                                checked={section_item.checked || inArray(section_item.key, checkedSections)}
                                                disabled={section_item.disabled}
                                            > {section_item.title} </Checkbox>
                                        </div>
                                    })}
                                </div>

                                <div className="margin-top-sm">
                                    <Text className="margin-right-sm">{l('calc.months')}</Text>
                                    <InputNumber
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="always-show-arrows align-center"
                                        bordered={true}
                                        min={0}
                                        step={1}
                                        placeholder="12"
                                        onChange={(value) => setState('amount.months', value)}
                                        style={{width: 63}}
                                        disabled={!checkedSections.length && !pageParams.messages.limit}
                                        value={pageParams.amount.months}
                                    />
                                </div>

                                <hr className="margin-top-sm" style={{opacity: 0.5}}/>

                                {/* Стоимость выбранного тарифа | Оплатить тариф с баланса */}
                                <div className="margin-top-pm">
                                    <Row gutter={18} align="stretch">
                                        <Col xs={24} sm={13} xxl={11}>
                                            <div className="ant-typography ant-typography-secondary font-size-md"
                                                 style={{marginTop: 4}}>
                                                {l('calc.pay.price')}:
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={11} xxl={13}>
                                            <Title level={3} className="inline" style={{fontWeight: 400}}>
                                                {roundPrice(tariffPrice)} {t('common.money.abbreviation')}
                                            </Title>
                                        </Col>
                                    </Row>

                                    <Row gutter={18} align="stretch">
                                        <Col xs={24} className="margin-top-ps visible-xxl"/>
                                        <Col xs={24} xxl={11}>
                                            <Tooltip
                                                title={isEnough ? l('calc.pay.not_set_tariff') : l('calc.pay.not_enough_desc')}
                                                placement="topLeft"
                                            >
                                                <div className="inline">
                                                    <Button
                                                        // style={{marginTop: 6}}
                                                        className="height-auto margin-right-sm visible-xxl"
                                                        type="primary"
                                                        size="large"
                                                        loading={order.loading.messages}
                                                        disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.type}
                                                        icon={<CheckOutlined/>}
                                                        onClick={onTariffFormSubmit}
                                                    >{pageParams.view.tight ? t('common.action.apply') : l('calc.pay.apply')}</Button>

                                                    <Button
                                                        // style={{marginTop: 6}}
                                                        className="height-auto margin-right-sm margin-bottom-ps hidden-xxl"
                                                        type="primary"
                                                        size="default"
                                                        loading={order.loading.messages}
                                                        disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.type}
                                                        icon={<CheckOutlined/>}
                                                        onClick={onTariffFormSubmit}
                                                    >{pageParams.view.tight ? t('common.action.apply') : l('calc.pay.apply')}</Button>
                                                </div>
                                            </Tooltip>
                                        </Col>

                                        <Col xs={24} xxl={13} className="bg-white">
                                            {proj_balance + coins_price ?
                                                // <Tooltip title={l('calc.pay.amount_desc')} placement="topLeft">
                                                <div className="inline margin-right-pm">
                                                    <Text type="secondary">{l('calc.pay.amount')}: </Text>
                                                    {/*{isEnough ? <div/> : null}*/}
                                                    <Text
                                                        type="secondary"
                                                        className="nowrap"
                                                    >
                                                        {tariffPrice > (proj_balance + coins_price) ? roundPrice(tariffPrice - proj_balance - coins_price) : 0} {t('common.money.abbreviation')}
                                                    </Text>
                                                </div> : null
                                            }
                                            {isEnough ? null :
                                                <div className="inline">
                                                    <Text type="secondary">{l('calc.pay.not_enough')}: </Text>
                                                    <Text type="danger">
                                                        {roundPrice(tariffPrice - balance - proj_balance - coins_price)} {t('common.money.abbreviation')}
                                                    </Text>
                                                    {/*<Text type={isEnough ? null : "danger"}>{tariffPrice} {t('common.money.symbol')}</Text>*/}
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </div>

                            </ContentCard>

                            <ContentCard flat_xs={true} className="margin-top-pm">

                                <Title level={2} className="">
                                    {l('account.coins.title')}
                                </Title>
                                {/*Накоплено монет:*/}
                                <Row gutter={18} align="stretch" className="margin-top-xp">
                                    <Col xs={24} sm={13} xxl={11}>
                                        <div className="ant-typography ant-typography-secondary font-size-md"
                                             style={{marginTop: 4}}>
                                            {l('calc.pay.coins')}
                                            {/*<Tooltip title={l('account.coins.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>*/}
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={11} xxl={7}>
                                        <Title level={3} className="inline color-secondary"
                                               style={{fontWeight: 400}}>
                                            {coins ? numberForHuman(coins) : '—'}
                                        </Title>
                                    </Col>
                                    <Col xs={24} sm={24} xxl={6}>
                                        <Button
                                            className="btn-bordered"
                                            type="default"
                                            // loading={order.loading.balance}
                                            // disabled={pageParams.amount.order < 100}
                                            icon={<QuestionCircleOutlined/>}
                                            onClick={() => setState('modal.promo', true)}
                                            title={t('common.action.get')}
                                            style={{width: 140}}
                                        >{t('common.action.get')}</Button>
                                    </Col>
                                    <Col xs={24} className="margin-top-xp font-size-sm">
                                        {l('account.coins.desc')}
                                    </Col>
                                </Row>

                            </ContentCard>
                        </Col>
                    </Row>
                </Content>
                <div className="margin-top-pl"/>
            </Layout>
        </AppWrapper>
    )
}

export default Tariff