import React, {useEffect, useState} from 'react'
import {Button, Col, Layout, Row, Typography} from "antd";

import {routes} from "../../config/config";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {filterArrayByPathValue} from "../../library/functions";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {CustomHeader} from "../../components/Layout/CustomHeader";
import {IntegrationListTable} from "./IntegrationListTable";
import {SearchBox} from "../../components/Search/SearchBox";
import {PlusOutlined} from "@ant-design/icons";
import {setIntegrationItem} from "../../redux/reducers/IntegrationReducer";

const {Content} = Layout;
const {Title} = Typography;

const IntegrationList = () => {
    const section = 'platform'

    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    // const [searchParams, setSearchParams] = useSearchParams();
    const {admin, project, integration, sect} = useSelector(store => store)
    const [filters, setFilters] = useState({})

    const integrations = sect.integration;
    const project_item = project.item
    const integration_name = params.name
    let list = integration.list


    if (list.length && integration_name) {
        list = filterArrayByPathValue(list, 'integration.platform', integration_name)
    }

    useEffect(() => {
        if (admin.authorized && project_item.id) {
            let filters_result = {project_id: project_item.id}
            if (filters) filters_result = {...filters_result, ...filters}
            // if (integration_name) filters.platform = integration_name
            dispatch({type: 'getIntegrationList', admin, filters: filters_result})
        }

        if (admin.authorized && !sect.integration.length) {
            dispatch({type: 'getSectData', admin, section: 'integration', filters: {
                // is_on: true, // required for other sections!
                page_size: 0
            }});
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item, list.length, filters])

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const renderItemsList = (items) => {
        let rows = [];
        for (let item of items) {
            const itemUrl = `${routes.project_list}/${project_item.id}${routes.local.integration_list}/${item.name}`
            let itemClasses = 'int-item'
            if (!item.is_on) itemClasses += ' not-allowed';

            rows.push(
                <a href={item.is_on ? itemUrl : null}
                   title={item.is_on ? t('common.action.open') : t('error.denied')}
                   className={itemClasses}
                   key={`${section}-button-${item.name}`}
                   onClick={(e) => {
                       e.preventDefault()
                       if (item.is_on) navigate(itemUrl)
                   }}
                >
                    <span className="int-icon">
                        <img
                            className={item.is_on ? 'active' : 'disabled'}
                            src={item.icon_url}
                            alt={item.name}
                        />
                    </span>
                    <span className="int-label">{t('integration.' + item.name + '.title')}</span>
                </a>
            );
        }

        return rows
    }

    const renderItemsSection = (items, currentSection) => {
        return (
            <Col lg={6} md={8} sm={12} xs={24}>
                <Title level={3} className="int-header">
                    {t(section + '.list.headers.' + currentSection)}
                </Title>
                {renderItemsList(items)}
            </Col>
        )
    }

    const renderItemsSections = () => {
        let currentSection = null;
        let sections = [];
        let items = [];

        for (let item of integrations) {
            if (!currentSection) currentSection = item.category;
            else if (currentSection !== item.category) {
                sections.push(renderItemsSection(items, currentSection))

                items = []
                currentSection = item.category
            }

            items.push(item)
        }

        if (items.length) sections.push(renderItemsSection(items, currentSection))
        return sections
    }

    const recordCreateCommon = () => {
        dispatch(setIntegrationItem({})); // reset integration item
        navigate(`${routes.project_list}/${project_item.id}${routes.local.integration_list}/edit/0?platform=${integration_name}`)
    }

    let section_title = t(section + '.list.title')
    if (integration_name) section_title += ' - ' + t('integration.' + integration_name + '.title')

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <CustomHeader
                    key="integration-header"
                    className={'padding-none-horizontal'}
                    title={section_title}
                    actions={integration_name ? [
                        <SearchBox searchHandler={searchHandler} key="header-search-button"/>,
                        <Button
                            key="header-primary-button"
                            type="primary"
                            icon={<PlusOutlined/>}
                            onClick={recordCreateCommon}>
                            {t('page_header.buttons.add.title')}
                        </Button>
                    ] : null}
                />
                <Content className="page-container site-layout-background">
                    {integration_name ?
                        <div className="integration-table">
                            <IntegrationListTable
                                admin={admin}
                                project={project_item}
                                list={list}
                                filters={filters}
                            />
                        </div> :
                        <div className="integration-list">
                            <Row gutter={20}>
                                {renderItemsSections()}
                            </Row>
                        </div>
                    }
                </Content>
            </Layout>
        </AppWrapper>
    )
}
export default IntegrationList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link