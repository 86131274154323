import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {dotenv, routes} from "../../config/config";
import {messengers} from "../../schemas/frontend/effects";
import {
    Button,
    Col,
    Layout,
    Row,
    Table,
    Tooltip,
    Typography
} from "antd";

import {
    getUtcDateTime,
    momentFromUnix,
    deepGet,
    getBotUrl,
    numberForHuman,
    getUserFullName,
    getFileSizeString,
    inArray,
} from "../../library/functions";

import {useTranslation} from "react-i18next";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {Banner} from "../../components/Banner/Banner";
import {CustomHeader} from "../../components/Layout/CustomHeader";
import {ContentCard} from "../../components/Card/Content/ContentCard";
import {ProgressBar} from "../../components/ProgressBar/ProgressBar";
import {NavigateBtn, NavigateLink} from "../../components/Navigate/Navigate";
import Fa from "../../components/Icon/Fa";
import {
    CalendarOutlined,
    CheckOutlined,
    CommentOutlined, LockOutlined,
    MailOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    CloseOutlined,
    PlusOutlined,
} from "@ant-design/icons";

const {Content} = Layout;
const {Title, Text} = Typography;

const Overview = () => {
    const {t} = useTranslation()
    const section = 'tariff'

    // data from URL params
    const params = useParams()
    const project_id = Number(params.project_id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, integration} = useSelector(store => store)
    const project_item = project.item
    const projectTariffType = deepGet(project_item, 'params.tariff.type', 'business');
    const stat = deepGet(project_item, 'stat', {}) || {};

    const adminContacts = deepGet(admin, 'user.contacts', []) || [];
    const connected = adminContacts.map(contact => contact.type);
    const isContactsConnected = inArray('tg_id', connected) && inArray('vk_id', connected);
    // console.log('admin.user', admin.user.contacts, connected)

    useEffect(() => {
        if (admin.authorized && admin.token) {
            dispatch({type: 'getProjectItem', admin, id: project_id, data: {extended: true}});

            if (!integration.list.length) {
                dispatch({type: 'getIntegrationList', admin, filters: {project_id: project_id, platforms: messengers}})
            }

            if (!isContactsConnected) {
                dispatch({type: 'getAdminItem', admin});
            }
        }
    }, [admin.authorized, project_id, window.location.pathname])

    // local locale func
    const l = (c) => t('project.' + section + '.' + c)

    const navigateToEdit = () => {
        navigate(`${routes.project_list}/edit/${project_item.id}#alerts`);
    }

    const navigateToTariff = () => {
        navigate(`${routes.project_list}/${project_item.id}/tariff`);
    }

    const expire_date = momentFromUnix(project_item.tariff_expire_at, "D MMMM");
    const expire_date_parts = [
        momentFromUnix(project_item.tariff_expire_at, "D MMMM YYYY"),
        t('common.at'),
        momentFromUnix(project_item.tariff_expire_at, "H:mm")
    ]

    const now = getUtcDateTime();
    const date_warning = now > project_item.tariff_expire_at - 7 * 60 * 60 * 24;
    const messages_warning = project_item.permanent_messages < (now > project_item.tariff_expire_at ? 100 : 0)

    let isExpired = false;
    let dateWarningClassname = date_warning ? 'color-danger' : '';
    if (date_warning && project_item.permanent_messages > 0 && project_item.tariff_expire_at + 7 * 60 * 60 * 24 < now) {
        dateWarningClassname = 'color-gray-6';
        isExpired = true;
    }

    let allowed_sections = [];
    let enabled_sections = [];
    if (project_item['allowed_sections']) {
        for (const allowedSection of project_item['allowed_sections']) {
            allowed_sections.unshift({
                section: allowedSection.section,
                expire: momentFromUnix(allowedSection.expire_at, l('access.expire_at') + " D MMMM YYYY " + l('access.expire_year') + " H:mm"),
                expired: allowedSection.expire_at <= now,
            })

            if (allowedSection.expire_at > now) {
                enabled_sections.push(allowedSection.section);
            }
        }
    }

    const renderSectionsItems = () => {
        let result = allowed_sections.map((section_item, item_key) => {
            return <Row key={item_key} gutter={16} style={{marginTop: 6}}>
                <Col xs={24} sm={14}>
                    {section_item.expired ? <LockOutlined className="color-secondary margin-right-xp"/> :
                        <CheckOutlined className="color-secondary margin-right-xp"/>}
                    {l('access.section.' + section_item.section)}
                </Col>
                <Col xs={{span: 22, offset: 2}} sm={{span: 10, offset: 0}}>
                    <Text type={section_item.expired ? 'danger' : 'secondary'}>{section_item.expire}</Text>
                </Col>
            </Row>
        })

        if (project_item.id) {
            const isEnabled = deepGet(project_item, 'params.dialogs.is_on');

            result.push(<Row key="title" gutter={16} style={{marginTop: 6}}>
                <Col xs={24} sm={14}>
                    {isEnabled ? <CheckOutlined className="color-secondary margin-right-xp"/> :
                        <CloseOutlined className="color-secondary margin-right-xp"/>}
                    {l('access.section.dialog')}
                </Col>
                <Col xs={{span: 22, offset: 2}} sm={{span: 10, offset: 0}}>
                    {isEnabled ? <Text type="secondary">
                        {l('access.section.on')}
                    </Text> : <Text type="danger">
                        {l('access.section.off')}
                    </Text>}
                </Col>
            </Row>)
        }

        return result;
    }

    let isToday = false;
    let update_date = l('stat.today');
    if (project_item.daily_updated_at) {
        // TODO. use something like date.setUTCFullYear(2022,8,25) for getting correct date
        const updatedAtDate = new Date(project_item.daily_updated_at * 1000);
        const todayMidnight = new Date();
        todayMidnight.setUTCHours(3, 0, 0, 0);

        isToday = updatedAtDate > todayMidnight;
        update_date = momentFromUnix(project_item.daily_updated_at, "D MMMM")
        // console.log('compare', todayMidnight, updatedAtDate)
    }

    // start tasks
    const tasks = deepGet(project_item, 'params.start', {}) || {};
    const integrationsUrl = `${routes.project_list}/${project_id}${routes.local.integration_list}`;
    let messagesUrl = integrationsUrl;

    // console.log('integration.list', integration.list)
    if (integration.list.length) {
        const integrationItem = integration.list[0];
        const botUrl = getBotUrl(integrationItem, true);
        if (botUrl) messagesUrl = botUrl;
    }

    // receive free items from stat info
    let freeUsersResult = [];

    const freePagesResult = deepGet(stat, 'free.pages', []) || [];
    const freeContacts = deepGet(stat, 'free.contacts', []) || [];

    for (const freeContact of freeContacts) freeUsersResult.push({
        id: freeContact.id,
        name: getUserFullName(freeContact),
    });

    return (
        <AppWrapper className="bg-gray-light">
            <Layout className="site-layout site-layout-transparent">
                <Banner image="universe"/>
                <CustomHeader
                    key="tariff-header"
                    className={'padding-none-horizontal'}
                    title={l('about')}
                    actions={[
                        <NavigateBtn
                            key="edit"
                            type="text"
                            icon={<SettingOutlined/>}
                            className="default"
                            link={`${routes.project_list}/${project_item.id}/edit`}
                        >
                            {t('common.button.settings')}
                        </NavigateBtn>
                    ]}
                />
                <Content className="page-container container-with-cards padding-none-vertical">
                    <Row gutter={30} align="stretch">
                        {/* left column start */}
                        <Col xs={24} xl={12} className="margin-top-pm xs-margin-top-none">
                            <ContentCard flat_xs={true} className="full-height">

                                {/* Стартовые задачи */}
                                {project_item.id && !tasks.done ? <div className="padding-bottom-md">
                                    <Title level={2}>
                                        {l('start.title')}
                                    </Title>

                                    <Table
                                        // TODO: replace help links to config
                                        showHeader={false}
                                        pagination={false}
                                        className="font-size-sm border-last-none"
                                        bordered={false}
                                        columns={[
                                            {
                                                title: 'Text',
                                                dataIndex: 'text',
                                                key: 'text',
                                                className: 'padding-left-none padding-vertical-xs',
                                                render: (val, record) => <div className="inline">
                                                    {val} <Tooltip title={l('start.help')}>
                                                    <Button
                                                        type="text"
                                                        className="font-size-sm inverted padding-top-xxs"
                                                        icon={<QuestionCircleOutlined/>}
                                                        href={record.help}
                                                        target="_blank"
                                                        size={'small'}
                                                    />
                                                </Tooltip>
                                                </div>
                                            },
                                            {
                                                title: 'Buttons',
                                                dataIndex: 'actions',
                                                key: 'actions',
                                                className: 'padding-none-horizontal padding-vertical-xs border-none vertical-align-top',
                                                align: 'right',
                                                width: 150,
                                                render: (col, record) => <div className="table-row-buttons">
                                                    {record.status ? <Button
                                                        type="text"
                                                        icon={<CheckOutlined/>}
                                                        className="inverted padding-none-horizontal"
                                                    >
                                                        {l('start.done')}
                                                    </Button> : <NavigateBtn
                                                        type="primary"
                                                        link={record.url}
                                                        children={l('start.execute')}
                                                        className="btn-bordered"
                                                        target={record.external ? '_blank' : null}
                                                    />}
                                                </div>,
                                            },
                                            // Add more columns as needed
                                        ]}
                                        dataSource={[
                                            {
                                                text: l('start.integration'),
                                                status: !!tasks.integration,
                                                url: integrationsUrl,
                                                help: `${dotenv.docs_url}/integration`
                                            },
                                            {
                                                text: l('start.alert'),
                                                status: !!tasks['alert'],
                                                // url: `${routes.project_list}/${project_id}/edit#alerts`,
                                                url: `https://t.me/${dotenv.alerts_bot_name}?start=alerts-1--pr-${project_id}`,
                                                help: `${dotenv.docs_url}/alerts`,
                                                external: true,
                                            },
                                            {
                                                text: l('start.template'),
                                                status: !!tasks.template,
                                                url: `${routes.project_list}/${project_id}${routes.local.template_store}/${dotenv.hello_template}`,
                                                help: `${dotenv.docs_url}/templates/store`
                                            },
                                            {
                                                text: l('start.message'),
                                                status: !!tasks.message,
                                                url: messagesUrl,
                                                external: !(messagesUrl === integrationsUrl),
                                                help: `${dotenv.docs_url}/graph/examples/keyword-reaction`,
                                            },
                                            {
                                                text: l('start.contact'),
                                                status: !!tasks.contact,
                                                url: `${routes.project_list}/${project_id}${routes.local.user_list}`,
                                                help: `${dotenv.docs_url}/tariff/free-contacts`
                                            },
                                        ]}
                                    />
                                </div> : null}

                                {/* Contact bonuses. TODO: check on finished + reload */}
                                {admin.user.contacts && isContactsConnected ? null : <div className="padding-bottom-md">
                                    <Title level={2}>
                                        {l('contact.title')}
                                    </Title>

                                    <Table
                                        showHeader={false}
                                        pagination={false}
                                        className="font-size-sm border-last-none"
                                        bordered={false}
                                        columns={[
                                            {
                                                title: 'Text',
                                                dataIndex: 'text',
                                                key: 'text',
                                                className: 'padding-left-none padding-vertical-xs',
                                                render: (val, record) => <div className="inline">
                                                     <Tooltip title={l('contact.tooltip')}>
                                                         <span className="margin-right-xp">{val}</span>
                                                         {record.status ? null : <span className="ant-badge ant-badge-not-a-wrapper">
                                                            <sup
                                                                data-show="true"
                                                                style={{backgroundColor: '#52c41a'}}
                                                                className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                                                            >
                                                                +1000 {l('contact.coins')}
                                                            </sup>
                                                         </span>}
                                                </Tooltip>
                                                </div>
                                            },
                                            {
                                                title: 'Buttons',
                                                dataIndex: 'actions',
                                                key: 'actions',
                                                className: 'padding-none-horizontal padding-vertical-xs border-none vertical-align-top',
                                                align: 'right',
                                                width: 180,
                                                render: (col, record) => <div className="table-row-buttons">
                                                    {record.status ? <Button
                                                        type="text"
                                                        icon={<CheckOutlined/>}
                                                        className="inverted padding-none-horizontal"
                                                    >
                                                        {l('start.done')}
                                                    </Button> : <Button
                                                        type="primary"
                                                        icon={<PlusOutlined/>}
                                                        href={record.url}
                                                        children={l('contact.apply')}
                                                        className={record.bordered ? "btn-bordered" : null}
                                                        target="'_blank"
                                                    />}
                                                </div>,
                                            },
                                            // Add more columns as needed
                                        ]}
                                        dataSource={[
                                            {
                                                text: l('contact.tg'),
                                                url: dotenv.main_bot_url + `?start=a-cnt--uh-${admin.user.key}--pr-${project_id}`,
                                                status: inArray('tg_id', connected),
                                                bordered: false,
                                            },
                                            {
                                                text: l('contact.vk'),
                                                url: dotenv.mini_app_url + `#connect?utm_uh=${admin.user.key}&pr=${project_id}`,
                                                status: inArray('vk_id', connected),
                                                bordered: true
                                            },
                                        ]}
                                    />
                                </div>}

                                {/* Текущий тариф */}
                                <Title level={2}>
                                    {l('type.title') + ': «' + l('type.' + projectTariffType + '.title') + '»'}
                                </Title>

                                <div className="margin-top-sm">
                                    {l('stat.summary.stat.title')}
                                    <ul className="list-style-secondary">
                                        <li>{l('stat.summary.stat.users')}: {
                                            numberForHuman(deepGet(stat, 'users', 0))
                                        }</li>
                                        <li>{l('stat.summary.stat.contacts')}: {
                                            numberForHuman(deepGet(stat, 'contacts', 0))
                                        }</li>
                                        <li>{l('stat.summary.stat.chats')}: {
                                            deepGet(stat, 'chats', 0)
                                        }</li>
                                        <li>{l('stat.summary.stat.money')}: {
                                            numberForHuman(deepGet(stat, 'money', 0)) + ' ' + t('common.money.abbreviation')
                                        }</li>
                                    </ul>
                                    <br/>
                                    {l('stat.summary.info.title')}
                                    <ul className="list-style-secondary">
                                        <li>{l('stat.summary.info.contacts')}: <span
                                            className="color-secondary">{freeUsersResult.length} / 3</span>
                                            <ul>{freeUsersResult.map((item, index) => {
                                                return <li key={"cnt" + item.id}>
                                                    <NavigateLink
                                                        link={`${routes.project_list}/${project_id}${routes.local.user_list}/profile/${item.id}`}
                                                        className="color-default hover:text-underline"
                                                    >
                                                        {item.name}
                                                    </NavigateLink>
                                                </li>
                                            })}</ul>
                                        </li>
                                        {(projectTariffType === 'developer' && inArray('page', enabled_sections))? <li>
                                            {l('stat.summary.info.pages')}: <span
                                            className="color-secondary">{freePagesResult.length} / 5</span>
                                            <ul>{freePagesResult.map((item, index) => {
                                                return <li key={"pg" + item.id}>
                                                    <NavigateLink
                                                        link={`${routes.project_list}/${project_id}${routes.local.page_list}/edit/${item.id}?tab=settings`}
                                                        className="color-default hover:text-underline"
                                                    >
                                                        {item.title}
                                                    </NavigateLink>
                                                </li>
                                            })}</ul>
                                        </li> : null}
                                    </ul>
                                </div>

                                {/* Настроить уведомления */}
                                <div className="margin-top-sm">
                                    <Button
                                        type="primary"
                                        onClick={navigateToTariff}
                                        className="margin-right-ps margin-bottom-xp"
                                    >
                                        {l('type.change')}
                                    </Button>

                                    <Button type="primary" className="btn-bordered" onClick={navigateToEdit}>
                                        {l('access.control_alerts')}
                                    </Button>
                                </div>
                            </ContentCard>
                        </Col>

                        {/* right column start */}
                        <Col xs={24} xl={12} className="margin-top-pm xs-margin-top-none" id="tariff-column">
                            <ContentCard flat_xs={true} className="full-height">
                                {/* Остаток сообщений */}
                                <div className={projectTariffType === 'developer' ? 'hide' : ''}>
                                    <Title level={2}>
                                        {l('stat.heading') + ' ' + ((isToday || isExpired) ? l('stat.today') : update_date)}
                                    </Title>

                                    {isExpired ? null : <ProgressBar
                                        className="margin-top-ps"
                                        lg={true}
                                        max={project_item.daily_count}
                                        current={project_item.daily_messages}
                                        details_as_label={true}
                                        danger_on_low={true}
                                    />}

                                    <Row gutter={24} className="margin-top-pm">
                                        <Col xs={24} sm={8}>
                                            <Tooltip title={l('stat.max_desc')} placement="topLeft">
                                                <Title level={2}>
                                                    <span className={isExpired ? 'color-gray-6' : null}>
                                                        <CommentOutlined/> {project_item.daily_count}
                                                    </span>
                                                </Title>
                                            </Tooltip>
                                            <Text type="secondary">
                                                {l('stat.max')}
                                            </Text>
                                        </Col>
                                        <Col xs={24} sm={8} className="xs-margin-top-pm">
                                            <Tooltip
                                                title={project_item.tariff_expire_at ? expire_date_parts.join(' ') : l('stat.expire_at_unset')}
                                                placement="topLeft"
                                            >
                                                <Title level={2}>
                                                    <span className={dateWarningClassname}>
                                                        <CalendarOutlined/>
                                                        {project_item.tariff_expire_at ? expire_date :
                                                            <Fa icon="infinity" set="regular"/>}
                                                    </span>
                                                </Title>
                                            </Tooltip>
                                            <Text type="secondary">{now > project_item.tariff_expire_at ? l('stat.expired_at') : l('stat.expire_at')}</Text>
                                        </Col>
                                        <Col xs={24} sm={8} className="xs-margin-top-pm">
                                            <Tooltip title={l('stat.permanent_desc')} placement="topLeft">
                                                <Title level={2}>
                                                    <span className={messages_warning ? 'color-danger' : ''}>
                                                        <MailOutlined/> {project_item.permanent_messages}
                                                    </span>
                                                </Title>
                                                <Text type="secondary">
                                                    {l('stat.permanent')} <QuestionCircleOutlined
                                                    className="font-size-xs"/>
                                                </Text>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Доступ к разделам */}
                                <div className="margin-top-md">
                                    <Title level={2}>{l('sections.heading')}</Title>
                                    <div className="margin-top-ps">{renderSectionsItems()}</div>
                                </div>

                                <div className="margin-top-sm">
                                    <Button type="primary" onClick={navigateToTariff} className="btn-bordered">
                                        {l('stat.renew')}
                                    </Button>
                                </div>

                                {/* Файловое хранилище */}
                                <div className="margin-top-md">
                                    <Title level={2}>{l('stat.files.title')}</Title>
                                    <div className="margin-top-ps">
                                        <ul className="list-style-secondary">
                                            <li>{l('stat.files.size')}: {getFileSizeString(deepGet(stat, 'memory.used', 0) || 0, t)}</li>
                                        </ul>
                                    </div>
                                </div>
                            </ContentCard>
                        </Col>
                    </Row>

                </Content>
                <div className="margin-top-pl"/>
            </Layout>
        </AppWrapper>
    )
}

export default Overview