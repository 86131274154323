import React from 'react'
import moment from "moment";
import {
    deepCopyObject,
    deepGet,
    deepSet,
    deepSetOrig,
    inArray, is, isArr,
    objDeepExtend,
    objectLength,
    ucFirst, unixFromMoment
} from "./functions";
import {schemes} from "../schemas/frontend/schemas";
import {Form, Row, Tooltip} from "antd";
import {copyToClipboard} from "./clipboard";

const excluded_data_tables = ['nodes']

export const composeEffectForDuplicate = (orig_effect, newLocalIdNext) => {
    const new_effect = {
        ...orig_effect,
        localId: newLocalIdNext(),
    }
    if (new_effect.title) {
        new_effect.title = new_effect.title + ' - Copy';
    }
    if (new_effect['spec'] && 'conditions' in new_effect['spec']) {
        new_effect['spec']['conditions'].forEach(cn => {
            cn.localId = newLocalIdNext();
            cn.ownerLocalId = new_effect.localId;
        });
    }

    return new_effect;
}

export const getFullEffectByType = (type, glob = null) => {
    const effectType = glob ?? type.split('/')[0];
    let scheme = schemes[effectType];
    let effect_scheme = scheme.find(cond => cond.type === type);
    if (effect_scheme) {
        let effect = deepCopyObject(effect_scheme);
        if (effect.inherit && !effect.fields) {
            const inherit = deepCopyObject(scheme.find(cond => cond.type === effect.inherit));

            let effect_fields = [];
            inherit['fields'].forEach(fieldRow => {
                let fields_row_result = [];
                fieldRow.forEach((fieldElement) => {
                    const field_name = fieldElement.name;
                    let field_result;

                    if (effect.replace && effect.replace[field_name]) {
                        if (effect.replace[field_name].type !== 'unset') {
                            field_result = effect.replace[field_name];
                        }
                    } else {
                        field_result = fieldElement;
                        field_result['containerType'] = effect.inherit;
                        delete field_result.width;
                    }

                    if (field_result) fields_row_result.push(field_result);
                })
                if (fields_row_result.length) effect_fields.push(fields_row_result);
            });

            effect['fields'] = effect_fields;
        }
        return effect;
    }
    return null;
}

export const createSpecFromFields = (fields, isForForm = false) => {
    let spec = {};
    if (fields && fields.length) {
        for (const fieldRow of fields) {
            for (const field of fieldRow) {
                if (field && field.name && field.type) {
                    if (field.default !== undefined) {
                        // spec[field.name] = field.default;
                        deepSetOrig(spec, field.name, field.default);
                    } else if (inArray(field.type, ['text', 'editor', 'media', 'images', 'videos', 'upload'])) {
                        // spec[field.name] = '';
                        deepSetOrig(spec, field.name, '');
                    } else if (field.type === 'switcher' || field.type === 'checkbox') {
                        // spec[field.name] = false;
                        deepSetOrig(spec, field.name, false);
                    } else if (field.type === 'menu') {
                        // spec[field.name] = undefined;
                        deepSetOrig(spec, field.name, undefined);
                    } else if (field.type === 'tags' || field.type === 'conditions') {
                        // spec[field.name] = [];
                        deepSetOrig(spec, field.name, []);
                    } else if (inArray(field.type, ['time', 'date', 'datetime'])) {
                        // spec[field.name] = isForForm ? moment(0) : 0;
                        deepSetOrig(spec, field.name, isForForm ? moment(0) : 0);
                    }
                }
            }
        }
    }
    return spec;
}

export const dispatchTables = (dispatch, admin, project_id, dbTables, site_id = null, store = null) => {
    if (objectLength(dbTables)) {
        for (const dataName in dbTables) {
            let data_filters = {project_id: project_id};

            // console.log('dispatchTables', dataName, site_id)
            if (inArray(dataName, ['pageShort']) && site_id) {
                if (!store) continue;

                data_filters['site_id'] = site_id;
                data_filters['ordering'] = 'custom';

                // console.log('store.page.short.list', site_id, store.page.short.list[0].site_id, store.page.short.list)
                if (store.page.short.list && store.page.short.list.length) {
                    if (store.page.short.list[0].site_id === site_id) {
                        continue;
                    }
                }
            }

            if (typeof dbTables[dataName] === 'object') data_filters = {...data_filters, ...dbTables[dataName]}
            dispatch({type: 'get' + ucFirst(dataName) + 'List', admin, filters: data_filters});
            // console.log('dispatch', {type: 'get' + ucFirst(dataName) + 'List', admin, filters: data_filters})
        }
    }
}

export const findAndDispatchData = (dispatch, admin, project_id, type, spec, glob = null, site_id = null) => {
    // console.log('findAndDispatchData RUN', type);
    let fields = null;

    const effect = getFullEffectByType(type, glob)
    if (effect && effect.fields) fields = effect.fields;

    // console.log('fields', fields)
    if (!fields || fields.length <= 0) return false;
    // TODO: add depends_on checks

    let dataNames = {};
    fields.forEach((fieldRow, index) => {
        fieldRow.forEach((field, index) => {
            if ('data' in field && !inArray(field.data, excluded_data_tables)) {
                let dataQuery = true;
                if (field.data_spec_query) {
                    let data_spec_query = {}
                    // set query param if not empty
                    for (const [query_param, spec_param] of Object.entries(field.data_spec_query)) {
                        const spec_value = deepGet(spec, spec_param)
                        if (spec_value !== undefined) data_spec_query = deepSet(data_spec_query, query_param, spec_value)
                        // data_spec_query[query_param] = spec_value
                    }
                    // console.log('data_spec_query', data_spec_query)
                    // set spec query if not empty
                    if (objectLength(data_spec_query) === objectLength(field.data_spec_query)) {
                        if (field.data_query) dataQuery = {...field.data_query, ...data_spec_query}
                        else dataQuery = data_spec_query
                    }
                    // turn off if any data is undefined
                    else dataQuery = undefined
                } else if (field.data_query) dataQuery = field.data_query
                if (dataQuery !== undefined) dataNames[field.data] = dataQuery;
            }
        });
    });

    if (objectLength(dataNames)) {
        // console.log(
        //     'Dispatch data tables:', dataNames,
        //     // '\n effect:', effect,
        // )

        dispatchTables(dispatch, admin, project_id, dataNames, site_id)
        // setDataTables({...dataTables, ...dataNames});
    }
}

export const setEffectSpec = (form, setSpecState, specNewValues = null) => {
    let currentSpec = form.getFieldsValue();

    let specValues = {...currentSpec};  // let specValues = {...specState};
    if (specNewValues) objDeepExtend(specValues, specNewValues);
    // console.log('specValues', specValues)
    // console.log('specNewValues', specNewValues)

    for (const [fieldName, fieldValue] of Object.entries(specValues)) {
        if (fieldValue && typeof fieldValue === 'object') {
            // upload field
            if ('fileList' in fieldValue) {
                // effect.spec[fieldName] = []
                // specValues[fieldName] = effectField.fileList.filter((item) => !!item.url);
                deepSetOrig(specValues, fieldName, fieldValue.fileList.map((item) => {
                    if (!item.url) return null;
                    return {url: item.url}
                }));
            }
            // datetime field
            else if (moment.isMoment(fieldValue)) {
                // console.log('fieldValue', fieldName, fieldValue)
                deepSetOrig(specValues, fieldName, unixFromMoment(fieldValue));
            }
            // timer fields
            else if (!isArr(fieldValue)) {
                specValues[fieldName] = {...fieldValue};
                for (const [childrenFieldName, childrenFieldValue] of Object.entries(specValues[fieldName])) {
                    if (moment.isMoment(childrenFieldValue)) {
                        // console.log('fieldValue', fieldName, fieldValue, unixFromMoment(childrenFieldValue))
                        deepSetOrig(specValues, [fieldName, childrenFieldName], unixFromMoment(childrenFieldValue));
                    }
                }
            }
        }
        // console.log('fieldName', fieldName, moment.isMoment(fieldValue))
    }
    return specValues;
}

const renderFieldsRow = (renderField, fieldsRow, localId, rowIndex) => {
    return fieldsRow.map((fieldCol, colIndex) => {
        const width = fieldCol.width ? fieldCol.width : 24 / fieldsRow.length;
        return renderField(fieldCol, width, `${localId}_${rowIndex}_${colIndex}`)
    })
}

export const renderFormRows = (localId, containerState, renderField) => {
    if (containerState.fields) {
        return containerState.fields.map((fieldsRow, rowIndex) => {
            if (fieldsRow) {
                return (
                    <Row justify="space-between" gutter={8} key={`${localId}_${rowIndex}`}>
                        {renderFieldsRow(renderField, fieldsRow, localId, rowIndex)}
                    </Row>
                )
            }
            return null;
        })
    } else {
        return null;
    }
}

export const renderContainerForm = (itemId, localId, form, specState, containerState, renderField, onChange = null) => {
    return (
        <Form
            form={form}
            initialValues={specState}
            layout="vertical"
            // className=''
            onValuesChange={onChange}
            name={`form_${itemId}_${localId}`}
        >
            {renderFormRows(localId, containerState, renderField)}
        </Form>
    )
}



// ================= for templates and pages =================

const getBaseFieldSchema = () => {
    return {
        desc: '',
        placeholder: '',
        type: 'textarea',
    }
}

const composeTemplateFieldsFromValues = (t, fields, values, setRemoveFieldName) => {
    // let values = deepCopyObject(initValues);
    // let result = [];

    for (const [name, value] of Object.entries(values)) {
        let field = getBaseFieldSchema();
        field.name = 'values.' + name;
        field.label = name;
        field.desc = <div
            className="cursor-pointer"
            onClick={() => {
                setRemoveFieldName(name)
            }}
        >{t('common.action.delete')}</div>;

        if (typeof value in ['string']) {
            // pass
        } else if (typeof value in ['number', 'bigint']) {
            field.type = 'text'
        } else if (typeof value === 'boolean') {
            field.type = 'switcher'
        }
        fields.push(field);
    }
    return fields;
}


export const removeDeprecatedField = (formFields, setFormFields, formValues, setFormValues, removeFieldName, setRemoveFieldName) => {
    if (removeFieldName) {
        // console.log('removeFieldName', removeFieldName);

        // remove field
        const fields = formFields.filter((field) => {
            return field.name !== 'values.' + removeFieldName;
        });
        // console.log('fields', fields, formFields)
        setFormFields(fields);

        // and also remove value
        const newValues = {...formValues.values};
        delete newValues[removeFieldName];
        // console.log('newValues', newValues, formValues)
        setFormValues({...formValues, values: newValues});

        // reset field name
        setRemoveFieldName('');
    }
}

export const handleTemplateFields = (t, item, fields, values, isCreated, setDataTables, setRemoveFieldName, prefix = null) => {
    let valuesWithoutFields = deepCopyObject(values);
    let dataNames = {};

    for (let field of fields) {
        const fieldName = deepGet(field, 'name');
        if (fieldName) {
            delete valuesWithoutFields[fieldName];
            field.name = prefix ? prefix + fieldName : fieldName;

            if (field.label && isCreated) {
                field.label = <Tooltip
                    title={t('common.form.name.label') + ': [ ' + fieldName + ' ]. ' + t('common.action.click_for_copy')}>
                            <span
                                className="cursor-pointer"
                                onClick={() => {
                                    copyToClipboard(`{t:${fieldName}}`)
                                }}
                            >{field.label}</span>
                </Tooltip>
            }

            if (inArray(field.type, ['menu', 'tags'])) {
                // field['placeholder'] = t('common.placeholder.' + field.type);
                field['localized'] = false
                // fix the values saved and passes as string or object
                if (!isArr(field.values)) field['values'] = [];
            } else if (typeof values[fieldName] === 'object' && !inArray(field.type, ['images', 'upload'])) {
                values[fieldName] = JSON.stringify(values[fieldName]);
            } else if (!values[fieldName] && field.type === 'hidden') {
                values[fieldName] = deepGet(field, 'defaultValue', '');
            } else if (!values[fieldName] && inArray(field.type, ['switch', 'checkbox'])) {
                values[fieldName] = deepGet(field, 'defaultValue', false);
            }
        }

        if ('data' in field && field.data) {
            let dataQuery = true;
            // sections-field types
            if (inArray(field.data, [
                'list',
                'currency',
                'resource',
                'achievement',
            ])) {
                field['data_filters'] = {system_field_type: field.data, data_type_admin: 'int'}
                field.data = 'field';
            }
            // user fields
            else if (field.data.startsWith('field_')) {
                field['data_filters'] = {
                    system_field_type: 'crm',
                    data_type_admin: field.data.split('_')[1]
                }
                field.data = 'field';
            } else if (field.data === 'money') {
                field['data_filters'] = {system_field_type: field.data, data_type_admin: 'float'}
                field.data = 'field';
            }
            // user types
            else if (field.data === 'chat' || field.data === 'channel') {
                field['data_filters'] = {profile_type: field.data}
                field['data_query'] = {profile_types: ['chat', 'channel'], page: 0}
                field.features = [];
                field.data = 'user';
            }
            // integrations
            else if (field.data === 'integration') {
                field.features = [];
                field['data_query'] = {ordering: '-updated_at'}
            } else if (field.data === 'template' || field.data === 'pageShort') {
                // console.log('field.data', field.data)
                field.features = [];
            }

            // store data query
            if (field.data_query) dataQuery = field.data_query
            if (dataQuery !== undefined) dataNames[field.data] = dataQuery;
        }
    }

    // for templates case do not forget about additional field
    if (prefix && deepGet(item, 'params.integration.show', false)) {
        dataNames['integration'] = true;
    }

    for (const [name, value] of Object.entries(valuesWithoutFields)) {
        if (typeof value === 'object') {
            values[name] = JSON.stringify(value)
        }
    }

    if (objectLength(valuesWithoutFields)) {
        composeTemplateFieldsFromValues(t, fields, valuesWithoutFields, setRemoveFieldName);
        // console.log('composeTemplateFields', fields, valuesWithoutFields);
    }

    if (objectLength(dataNames)) {
        setDataTables(dataNames)
    }
}