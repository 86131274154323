import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import classes from './HelpPopup.module.scss'
import {dotenv, images} from "../../../../config/config";
import {createMarkup, deepGet, inArray, redirect, toNum} from "../../../../library/functions";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FormOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {List, Popconfirm} from "antd";

const HelpPopup = () => {
    const {t} = useTranslation()

    const dispatch = useDispatch()
    const {admin} = useSelector(store => store)
    // const status = deepGet(admin.params, 'welcome.help', false)

    const [opened, setOpened] = useState(false)
    const [status, setStatus] = useState(null)

    // console.log('status', status)
    useEffect(() => {
        //console.log('admin.params', admin.params)
        if (admin.user.id) setStatus(deepGet(admin.params, 'welcome.help', false))
        //else console.log('admin.params', admin.user.id, admin.params)
    }, [admin.user.id])

    const path = window.location.pathname
    const parts = path.split('/')

    const params = useParams()
    const project_id = toNum(params.project_id)

    const rememberStatus = () => {
        if (!status) {
            dispatch({type: 'updateAdminParams', admin, data: {welcome: true}});
            setStatus(true)
        }
    }

    if (!project_id) return null;

    return (
        <div className={classes.wrapper}>
            <div className={`${classes.popup}${opened ? '' : ' hide'}`}>
                <List>
                    <List.Item>
                        <List.Item.Meta
                            title={t('menu.section_help')}
                            avatar={<QuestionCircleOutlined/>}
                            onClick={() => {
                                let helpUrl = dotenv.docs_url

                                // get section from page path
                                let section = parts[3]

                                if (!section) section = 'about'
                                else if (section === 'edit') section = 'alerts'
                                else if (section === 'glob') section = 'variable/global'
                                else if (section === 'category') section = 'product/about-category'
                                else if (section === 'template') section = 'templates/installed'
                                else if (section === 'store') section = 'templates/store'
                                else if (section === 'layout') section = 'site-designer/create-landingpage' // TODO: remove
                                else if (inArray(section, ['site', 'page', 'domain', 'layout'])) {
                                    section = 'site-designer/' + section
                                } else if (inArray(section, ['currency', 'achievement', 'resource', 'role', 'money'])) {
                                    section = 'gamification/' + section
                                }

                                // add section to path
                                helpUrl += '/' + section

                                redirect(helpUrl, '_blank')
                                setOpened(false)
                            }}
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            title={t('menu.social_club')}
                            avatar={<FormOutlined/>}
                            onClick={() => {
                                redirect(dotenv.social_club_url, '_blank')
                                setOpened(false)
                            }}
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            title={t('menu.main_bot')}
                            avatar={<FormOutlined/>}
                            onClick={() => {
                                redirect(dotenv.main_bot_url, '_blank')
                                setOpened(false)
                            }}
                        />
                    </List.Item>
                </List>
            </div>

            <Popconfirm
                icon={null}
                open={status === false}
                defaultOpen={false}
                placement="leftBottom"
                title={<span dangerouslySetInnerHTML={createMarkup(t('help.tooltip'))}/>}
                onConfirm={rememberStatus}
                okText={t('common.button.understand')}
                showCancel={false}
                trigger="click"
                getPopupContainer={() => document.getElementById('app-wrapper')}
            >
                <img src={images.universe.character.help} alt="Help" onClick={() => {
                    setOpened(!opened)
                    rememberStatus()
                }}/>
            </Popconfirm>
        </div>
    )
}

export default HelpPopup