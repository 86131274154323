import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {l} from "../../library/locale";
import {routes} from "../../config/config";
import {getItemValues} from "../../library/functions";
import cryptoRandomString from "crypto-random-string";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate, dispatchItemDataWithFolders} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {notice} from "../../library/notice";

const GlobEdit = () => {
    const section = 'glob'
    const adminSection = 'glob'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // data from GET params
    const [searchParams] = useSearchParams();
    let folder_id = Number(searchParams.get('folder')) // for fast create in folder

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, glob, folder} = useSelector(store => store)
    const project_item = project.item
    const item = glob.item

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: "title",
            type: "text",
            placeholder: true,
            required: true,
        },
        {
            type: "text",
            name: "number",
            placeholder: true,
        },
        {
            type: "textarea",
            name: "text",
            placeholder: true,
        },
        // {
        //     type: "textarea",
        //     name: "value",
        //     placeholder: true,
        // },
        {
            name: "value",
            type: "code",
            form: form,
            language: 'js',
            placeholder: '{"list": ["value"], "number": 1, "text": "text", "boolean": true}',
        },
        {
            label: l('common.form.is_on.label'),
            desc: '',
            name: "is_on",
            type: "switcher",
        },
        {
            name: "name",
            type: "text",
            required: true,
            label: l('common.form.name.label'),
            desc: l('common.form.name.desc'),
            placeholder: l('common.form.name.placeholder'),
        },
        {
            name: "icon_name",
            type: "icon",
            placeholder: '',
            desc: l('common.form.icon_name.desc'),
        },
        {
            name: "folder_id",
            type: "folder",
            folder_list: folder.list,
            desc: l('common.form.folder.desc'),
        },
    ]

    // itis form state
    const [folderId, setFolderId] = useState(0)
    const [formValues, setFormValues] = useState({
        name: cryptoRandomString({length: 10}),
        icon_name: 'marker',
        is_on: true,
        folder_id: folder_id,
    })

    // get data from API first
    useLayoutEffect(() => {
        dispatchItemDataWithFolders(dispatch, admin, project_item, section, adminSection, id)
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id])

    // set field values to STATE if correct data received
    useEffect(() => {
        if (id && item.id === id) {
            let formValuesNew = getItemValues(item, formFields)

            // convert json to string
            if (formValuesNew.value !== null) formValuesNew.value = JSON.stringify(formValuesNew.value, null, 2)
            // if (typeof formValuesNew.value === 'string') {
            //     // replaced to backend
            //     formValuesNew.value = JSON.parse(formValuesNew.value)
            // }

            // save to form state
            setFormValues(formValuesNew)

            if (formValuesNew.folder_id !== undefined) {
                setFolderId(formValuesNew.folder_id) // for backToList url
            }
        }
        //eslint-disable-next-line
    }, [item])

    // set field values to FORM if correct data received
    useEffect(() => {
        form.setFieldsValue(formValues)
    }, [formValues])

    // compose form functions
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        if (values.value) {
            try {
                values.value = JSON.parse(values.value)
                if (typeof values.value !== 'object') {
                    notice.error(l('glob.error.json_parse'))
                    return false;
                }
            }
            catch (e) {
                // values.value = JSON.stringify(values.value)
                // console.log('JSON parse error', e);
                notice.error(l('glob.error.json_parse'))
                return false;
            }
        }
        else {
            values.value = null
        }

        if (!values.number) values['number'] = 0
        formCreateOrUpdate('Glob', values, formFields, id, project_item, admin, dispatch)
        backToList(parseInt(values.folder_id))
    }

    const backToList = (folder_id = false) => {
        if (folder_id === false || typeof folder_id !== 'number') folder_id = folderId
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}?folder=${folder_id}`);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={adminSection} // for labels generation
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default GlobEdit