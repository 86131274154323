import React, {useState, useLayoutEffect, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {resetActive} from "../../../redux/reducers/BlockReducer";
import classes from "./Components/BlockList.module.scss";
import listClasses from "./PageExamples.module.scss";
import {Button, Card, Typography} from "antd";
import {notice} from "../../../library/notice";
import Fa from "../../../components/Icon/Fa";
import {useTranslation} from "react-i18next";
import {deepCopyObject, deepGet, replaceLocaleVarsInObj, stripHtmlTags} from "../../../library/functions";
import {ContainersModal} from "../Add/ContainersModal";
import {BlockDrawers} from "./BlockDrawers";
import {BlockSect} from "./Components/BlockSect";
import Preloader from "../../System/Preloader";
import ImportModal from "../../../components/Modal/ImportModal";
import {CheckOutlined, EyeOutlined} from "@ant-design/icons";
import {dotenv} from "../../../config/config";

const {Meta} = Card;
const {Title} = Typography;

const screenMap = {
    'ts': {
        icon: 'mobile',
        flip: false,
        size: '375',
    },
    'xs': {
        icon: 'mobile',
        flip: true,
        size: '670',
    },
    'sm': {
        icon: 'tablet-alt',
        flip: false,
        size: '768',
    },
    'md': {
        icon: 'tablet-alt',
        flip: true,
        size: '992',
    },
    'lg': {
        icon: 'laptop',
        flip: false,
        size: '> 1200',
    },
    'xl': {
        icon: 'display',
        flip: false,
        size: '> 1600',
    }
}

export const pushPageContainer = (t, items, newItems, getNewId) => {
    if (!items || !newItems || !newItems.length) return;

    for (const rowItem of newItems) {
        let newRow = {
            id: getNewId(),
            items: [],
            style: {...rowItem.style},
            wrap: {...rowItem.wrap},
        }

        for (const colItem of rowItem.items) {
            let newCol = {
                id: getNewId(),
                items: [],
                style: {...colItem.style},
                wrap: {...colItem.wrap},
            }

            for (const colBlock of colItem.items) {
                newCol.items.push({
                    id: getNewId(),
                    ...replaceLocaleVarsInObj(t, deepCopyObject(colBlock))
                });
            }

            newRow.items.push(newCol);
        }

        items.push(newRow);
    }
}

export const BlockSections = (
    {
        id,
        pageItem,
        list,
        setList,
        layoutName,
        savePage,
        getNewId,
        showExamples = false,
        onContainersAdd = null,
        isOn = false,
        screens = null
    }
) => {
    const section = 'page';

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {admin, project, pb, page} = useSelector(store => store)
    const active = pb.active;
    const examples = page.public.list;
    // console.info('Examples', showExamples, examples)

    const [openedSection, setOpenedSection] = useState('');  // for MODAL - add new container

    const wiw = window.innerWidth;
    let defaultWidth = (screens && screens.length) ? screens[screens.length - 1] : 'xl';
    if (wiw < 670) defaultWidth = 'ts';
    else if (wiw < 768) defaultWidth = 'xs';

    const [width, setWidth] = useState(defaultWidth);
    const [isVisibleModal, setVisibleModal] = useState(false);

    // import states
    const [fileList, setFileList] = useState([]);
    const [fileContent, setFileContent] = useState(null);
    const [isVisibleImportModal, setVisibleImportModal] = useState(false);

    useLayoutEffect(() => {
        if (admin.authorized && project.item.id && layoutName && showExamples) {
            // land templates - examples of blocks based pages
            if (examples.length === 0 || layoutName !== page.public.layout) {
                // console.info('Get page examples list', examples.length, layoutName, '!=', page.public.layout);
                dispatch({
                    type: 'getPagePublicList', admin, layout: layoutName, filters: {
                        is_on: true,
                        layout_name: layoutName,
                        ordering: 'custom'
                    }
                });
            }
        }

    }, [admin.authorized && project.item.id, showExamples, layoutName, id])

    useEffect(() => {
        if (layoutName) {
            if (width !== defaultWidth) {
                // console.log('width', width, defaultWidth, 'screens[-1]', screens[-1], screens)
                setWidth(defaultWidth);
            }
        }
    }, [layoutName, screens])

    const getSectionIndexById = (sectionId) => {
        const index = list.findIndex(item => item.id === sectionId);
        if (index === -1) {
            console.error('Block section not found by id', sectionId, list);
            return undefined;
        }

        return index;
    }

    const setSectionItemsByIndex = (index, items) => {
        let newList = [...list];
        newList[index] = {...newList[index], items};
        setList(newList);
    }

    const onSectionChange = (rows, sectionId = null) => {
        const index = getSectionIndexById(sectionId || active.sec);
        if (index === undefined) return;

        setSectionItemsByIndex(index, rows);
    }

    const getRowsBySectionId = (sectionId) => {
        const index = getSectionIndexById(sectionId);
        if (index === undefined) return [];

        return [index, [...list[index].items]];
        // return [index, deepCopyObject(list[index].items)];
    }

    const addRow = (newRows) => {
        if (!openedSection) {
            console.error('Block opened Section is empty', active);
            return;
        }

        let [index, newItems] = getRowsBySectionId(openedSection);

        pushPageContainer(t, newItems, newRows, getNewId)

        setSectionItemsByIndex(index, newItems);
        dispatch(resetActive()); // reset active Ids
    }

    const importRows = () => {
        if (!fileContent) {
            notice.info(t('common.import.error.empty'));
            return;
        }
        try {
            const data = JSON.parse(String(fileContent));
            if (deepGet(data, [0, 'items']) === undefined) {
                notice.error(t('common.import.error.content'));
                return;
            }

            console.log('Import Content', data);
            addRow(data);

        } catch (e) {
            console.log('Import data parse error', e);
            notice.error(t('common.import.error.format'));
        }
    };


    const openImportModal = () => {
        setVisibleModal(false);
        setVisibleImportModal(true);
        setFileList([]);
        setFileContent('');
    }

    const onExampleUse = (item) => {
        if (onContainersAdd) onContainersAdd(item)
    }

    const renderWidthControls = () => {
        return <div className={`${classes.widthBtns} hidden-xs-important`}>
            <div>
                {Object.keys(screenMap).map((key) => {
                    // allow hide some screen sizes
                    if (screens && screens.length && !screens.includes(key)) return null;
                    // console.log('screenMap[key]', key, screenMap[key], screenMap)

                    return <Button
                        key={key}
                        type={width === key ? 'link' : 'text'}
                        className={screenMap[key]?.flip ? classes.flip : null}
                        icon={<Fa icon={screenMap[key]?.icon} set="regular"/>}
                        onClick={() => setWidth(key)}
                    />
                })}
            </div>

            <div className={`${classes.desc} text-secondary`}>
                <div className="float-right cursor-pointer" onClick={savePage}>
                    {t('page.notice.save')}
                </div>
                {screenMap[width]?.size}px
            </div>
        </div>
    }

    const renderSectionList = () => {
        if (!list || !list.length) {
            // console.error('Block list is empty:', list);
            return <Preloader/>;
        }

        // if (!list || !list.length) return <Alert
        //     closable={true}
        //     type="error"
        //     className="font-size-sm"
        //     message={t('page.error.empty_content')}
        // />;

        return list.map((section) => {
            const sectionId = section['id'] || '0';

            return <BlockSect
                key={sectionId}
                className={classes.containerInner}

                t={t}
                screen={width}
                isOn={isOn}
                section={section}
                getNewId={getNewId}
                setVisibleModal={setVisibleModal}
                setOpenedSection={setOpenedSection}
                onChange={(rows) => onSectionChange(rows, sectionId)}
            />
        });
    }

    return (<div className="site-layout-background user-select-none">
            {renderWidthControls()}

            <div className={`${classes.container} ${classes[width]}`}>
                {renderSectionList()}
            </div>

            <ContainersModal
                t={t}
                title={t('container.title')}
                isVisible={isVisibleModal}
                setVisible={setVisibleModal}
                onAdd={addRow}
                onImport={openImportModal}
            />

            <ImportModal
                t={t}
                onOk={importRows}
                section="common"
                isVisibleModal={isVisibleImportModal}
                setVisibleModal={setVisibleImportModal}
                fileList={fileList}
                setFileList={setFileList}
                fileContent={fileContent}
                setFileContent={setFileContent}
                fileFormat="json"
            />

            <BlockDrawers
                section={section}
                page={pageItem}
                sectionList={list}
                savePage={id ? savePage : null}
                onChange={onSectionChange}
            />

            {showExamples && !!(examples && examples.length) && <>
                <div className="margin-top-lg margin-bottom-xp">
                    <Title level={3}>
                        {t(section + '.object.examples.title')}
                    </Title>
                    <p className="font-size-sm text-secondary">
                        {t(section + '.object.examples.desc')}
                    </p>
                </div>

                <div className={`${listClasses.list_wrapper} padding-top-sm padding-bottom-sm`}>
                    {examples.map((item, index) => {
                        const demoPath = '/' + item.path.join('/');
                        const demoUrl = `https://${dotenv.demo_site_subdomain}.${dotenv.sites_bace_domain}${demoPath}`;

                        return <Card
                            key={index}
                            className={listClasses.card}
                            hoverable
                            bordered={false}
                            cover={item.photo_url ? <img
                                alt={item.name}
                                className="cursor-pointer"
                                src={item.photo_url ? item.photo_url : "/image/icons/common/plus.svg"}
                                onClick={() => onExampleUse(item)}
                            /> : <Fa
                                // icon={item.icon_name}
                                className="cursor-pointer"
                                icon="file"
                                set="light"
                                wcn={listClasses.icon}
                                onClick={() => onExampleUse(item)}
                            />}

                            actions={[
                                <Button
                                    key="preview"
                                    type="primary"
                                    className="full-width"
                                    icon={<EyeOutlined/>}
                                    href={demoUrl}
                                    target="_blank"
                                >
                                    {t('common.button.demo')}
                                </Button>,
                                <Button
                                    key="use"
                                    type="text"
                                    icon={<CheckOutlined/>}
                                    onClick={() => onExampleUse(item)}
                                >
                                    {t('common.action.apply')}
                                </Button>
                            ]}
                        >
                            <Meta
                                title={item.title}
                                description={stripHtmlTags(item.description || '')}
                            />
                        </Card>
                    })}
                </div>
            </>}

        </div>
    )
}