import React, {Suspense, useEffect, useLayoutEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'

import {dotenv, routes} from "./config/config";
import {addCanonical} from "./library/dom";
import {getEncryptedStorageItem, inArray, toNum} from "./library/functions";
import {setAdminRestore} from "./redux/reducers/AdminReducer";
import {resetReducers} from "./redux/store/reset";
import {copyToClipboard} from "./library/clipboard";
import {useTranslation} from "react-i18next";
import {hitPage} from "./library/metrics";

import {ConfigProvider} from "antd";
import ruAntLocale from 'antd/lib/locale/ru_RU';
import ukAntLocale from 'antd/lib/locale/uk_UA';
import enAntLocale from 'antd/lib/locale/en_US';

// system
import Page404 from "./containers/404/Page404";
import Preloader from "./containers/System/Preloader";
import Typography from "./containers/System/Typography";

// login, etc
import AuthFormAdmin from "./containers/LoginAdmin/AuthFormAdmin";
import AfterAuth from "./containers/System/AfterAuth";
import RegFormAdmin from "./containers/LoginAdmin/RegFormAdmin";
import AfterReg from "./containers/System/AfterReg";
import ForgotPassword from "./containers/LoginAdmin/ForgotPassword";
import LogOut from "./containers/LoginAdmin/LogOut";

// account
import AdminItem from './containers/Admin/AdminItem'
import AdminSettings from "./containers/Admin/AdminSettings";
import AdminEdit from "./containers/Admin/AdminEdit";
import PasswordForm from "./containers/Admin/PasswordForm";

// projects and graphs
import ProjectList from './containers/Projects/ProjectList'
import ProjectEdit from "./containers/Projects/ProjectEdit";

import GraphList from "./containers/Graphs/GraphList";
import Flow from "./containers/Flow/Flow";
import GraphEdit from "./containers/Graphs/GraphEdit";

import IntegrationList from "./containers/Integrations/IntegrationList";
import IntegrationEdit from "./containers/Integrations/IntegrationEdit";

import WebhookList from "./containers/Webhooks/WebhookList";
import WebhookEdit from "./containers/Webhooks/WebhookEdit";

import GlobList from "./containers/Glob/GlobList";
import GlobEdit from "./containers/Glob/GlobEdit";

import FieldList from "./containers/Fields/FieldList";
import FieldEdit from "./containers/Fields/FieldEdit";

import GroupList from "./containers/Groups/GroupList";
import GroupEdit from "./containers/Groups/GroupEdit";

import UserList from "./containers/Users/UserList";
import UserEdit from "./containers/Users/UserEdit";
import UserProfile from "./containers/Users/UserProfile";
import UserContactEdit from "./containers/Users/UserContactEdit";
import UserUtmEdit from "./containers/Users/UserUtmEdit";
import UserFieldEdit from "./containers/Users/UserFieldEdit";
import UserPageAccessEdit from "./containers/Users/UserPageAccessEdit";

import Universe from "./containers/System/Universe";
import ProjectQuiz from "./containers/System/ProjectQuiz";
import UserRating from "./containers/System/Rating";

import Tariff from "./containers/Projects/Tariff";
import Overview from "./containers/Projects/Overview";
import AlertsSettings from "./containers/Projects/AlertsSettings";

import GameCommonEdit from "./containers/Gamification/Common/GameCommonEdit";
import GameCommonList from "./containers/Gamification/Common/GameCommonList";

import TemplateStore from "./containers/Template/TemplateStore";
import TemplateList from "./containers/Template/TemplateList";
import TemplateEdit from "./containers/Template/TemplateEdit";
import TemplateData from "./containers/Template/TemplateData";
import TemplateItem from "./containers/Template/TemplateItem";
import TemplateFields from "./containers/Template/TemplateFields";
import TemplateComponents from "./containers/Template/TemplateComponents";

import UrlList from "./containers/Url/UrlList";
import UrlEdit from "./containers/Url/UrlEdit";

import ProductCategoryList from "./containers/Sales/ProductCategory/ProductCategoryList";
import ProductCategoryEdit from "./containers/Sales/ProductCategory/ProductCategoryEdit";
import ProductList from "./containers/Sales/Product/ProductList";
import ProductEdit from "./containers/Sales/Product/ProductEdit";
import OrderList from "./containers/Sales/Order/OrderList";
import OrderEdit from "./containers/Sales/Order/OrderEdit";
import UtmList from "./containers/Sales/Utm/UtmList";
import UtmEdit from "./containers/Sales/Utm/UtmEdit";

import MailList from "./containers/Communication/Mainling/MailList";
import MailEdit from "./containers/Communication/Mainling/MailEdit";
import DialogList from "./containers/Communication/Dialog/DialogList";

import EmployeeList from "./containers/System/Staff/EmployeeList";
import EmployeeEdit from "./containers/System/Staff/EmployeeEdit";

import SiteList from "./containers/Site/Sites/SiteList";
import SiteEdit from "./containers/Site/Sites/SiteEdit";
import DomainList from "./containers/Site/Domains/DomainList";
import DomainEdit from "./containers/Site/Domains/DomainEdit";
import LayoutList from "./containers/Site/Layout/LayoutList";
import LayoutEdit from "./containers/Site/Layout/LayoutEdit";
import ThemeList from "./containers/Site/Theme/ThemeList";
import ThemeEdit from "./containers/Site/Theme/ThemeEdit";
import PageList from "./containers/Site/Page/PageList";
import PageEdit from "./containers/Site/Page/PageEdit";
import PageItem from "./containers/Site/Page/PageItem";
import TagList from "./containers/Site/Tag/TagList";
import TagEdit from "./containers/Site/Tag/TagEdit";

import SectionList from "./containers/Section/SectionList";
import SectionEdit from "./containers/Section/SectionEdit";

const App = () => {
    const {t} = useTranslation();
    const pathname = useLocation().pathname;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [pathValue, setPathValue] = useState(pathname)
    const admin = useSelector(store => store.admin)
    const storageAdmin = getEncryptedStorageItem()

    const path_parts = pathname.split('/');
    const url_section = path_parts.length >= 1 ? path_parts[1] : '';
    const project_id = (url_section === 'project' && path_parts.length >= 2) ? toNum(path_parts[2]) : 0;
    const project_section = path_parts.length >= 3 ? path_parts[3] : '';
    const isAuth = admin.authorized && admin.correct;

    useLayoutEffect(() => {
        // reset all local items list on project change
        resetReducers(dispatch);
    }, [project_id])

    useEffect(() => {
        if (!admin.authorized && storageAdmin && storageAdmin.user) dispatch(setAdminRestore(storageAdmin));
    })

    useEffect(() => {
        if (pathname !== pathValue) {
            // console.log('pathname', pathname)
            hitPage(pathname)
            setPathValue(pathname)
        }

        let titleSection = project_section || url_section;
        if (titleSection === 'integration') titleSection = 'platform';
        if (titleSection) document.title = t(titleSection + '.list.title') + ' - ' +  dotenv.public_name;

        if (pathname === '/') {
            addCanonical(routes.auth);
            if (!isAuth) navigate(routes.auth);
        } else {
            addCanonical(pathname);
        }
    }, [pathname])

    // useEffect(() => {
    if (!isAuth && inArray(url_section, ['universe', 'admin', 'project']) && project_section !== 'rating') {
        // console.log('not correct: isAuth', isAuth, url_section)
        // dispatch(setLogOut());
        navigate(routes.logout);
    }
    // }, [isAuth])

    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--color-primary', '#' + (process.env.REACT_APP_HEX_PRIMARY || '5D80FF'));
        root.style.setProperty('--color-primary-lighten', '#' + (process.env.REACT_APP_HEX_PRIMARY_LIGHTEN || '7794FF'));
        root.style.setProperty('--color-primary-hover', '#' + (process.env.REACT_APP_HEX_PRIMARY_HOVER || 'C8D4FF'));
        root.style.setProperty('--color-primary-light', '#' + (process.env.REACT_APP_HEX_PRIMARY_LIGHT || 'E6EEFF'));
        root.style.setProperty('--color-primary-bg', '#' + (process.env.REACT_APP_HEX_PRIMARY_BG || 'F1F7FF'));

        const handleCopyClick = (event) => {
            if (event.target.classList.contains('copy-on-click')) {
                copyToClipboard(event.target.innerText);
            }
        };

        document.addEventListener('click', handleCopyClick);
        return () => {
            document.removeEventListener('click', handleCopyClick);
        };
    }, []);

    let antLocale = enAntLocale;
    if (dotenv.default_lang === 'uk') antLocale = ukAntLocale;
    if (dotenv.default_lang === 'ru') antLocale = ruAntLocale;

    return (
        <ConfigProvider locale={antLocale}>
            <Suspense fallback={<Preloader/>}>
                <Routes>
                    <Route path={routes.root} element={isAuth ? <AfterAuth/> : <AuthFormAdmin toURL={dotenv.main_page}/>}/>
                    <Route path={routes.preloader} element={<Preloader/>}/>
                    <Route path={routes.typography} element={<Typography/>}/>

                    <Route path={routes.auth} element={isAuth ? <AfterAuth/> : <AuthFormAdmin toURL={dotenv.main_page}/>}/>
                    <Route path={routes.welcome} element={<AfterReg
                        toURL={dotenv.reg_start_page ? 'https://' + dotenv.home_page_domain + dotenv.reg_start_page : dotenv.main_page}
                    />}
                    />
                    <Route path={routes.reg} element={<RegFormAdmin/>}/>
                    <Route path={routes.forgot} element={<ForgotPassword/>}/>
                    <Route path={routes.logout} element={<LogOut/>}/>

                    <Route path={routes.admin.account} element={<AdminItem/>}/>
                    <Route path={routes.admin.settings} element={<AdminSettings/>}/>
                    <Route path={routes.admin.edit} element={<AdminEdit/>}/>
                    <Route path={routes.admin.safety} element={<PasswordForm/>}/>

                    <Route path={routes.universe} element={<Universe/>}/>
                    <Route path={routes.project_quiz} element={<ProjectQuiz/>}/>
                    <Route path={routes.project_list} element={<ProjectList/>}/>
                    <Route path={routes.project_edit} element={<ProjectEdit/>}/>

                    <Route path={routes.project} element={<Overview/>}/>
                    <Route path={routes.project + routes.local.about} element={<Overview/>}/>
                    <Route path={routes.project + routes.local.tariff} element={<Tariff/>}/>
                    <Route path={routes.project + routes.local.settings} element={<ProjectEdit local={true}/>}/>
                    <Route path={routes.project + routes.local.alerts_settings} element={<AlertsSettings/>}/>

                    <Route path={routes.project + routes.local.integration_list} element={<IntegrationList/>}/>
                    <Route path={routes.project + routes.local.integration_table} element={<IntegrationList/>}/>
                    <Route path={routes.project + routes.local.integration_edit} element={<IntegrationEdit/>}/>

                    <Route path={routes.project + routes.local.webhook_list} element={<WebhookList/>}/>
                    <Route path={routes.project + routes.local.webhook_edit} element={<WebhookEdit/>}/>

                    <Route path={routes.project + routes.local.url_list} element={<UrlList/>}/>
                    <Route path={routes.project + routes.local.url_edit} element={<UrlEdit/>}/>

                    <Route path={routes.project + routes.local.graph_list} element={<GraphList/>}/>
                    <Route path={routes.project + routes.local.graph_edit} element={<GraphEdit/>}/>
                    <Route path={routes.project + routes.local.graph_item} element={<Flow/>}/>

                    <Route path={routes.project + routes.local.field_list} element={<FieldList/>}/>
                    <Route path={routes.project + routes.local.field_edit} element={<FieldEdit/>}/>

                    <Route path={routes.project + routes.local.glob_list} element={<GlobList/>}/>
                    <Route path={routes.project + routes.local.glob_edit} element={<GlobEdit/>}/>

                    <Route path={routes.project + routes.local.group_list} element={<GroupList/>}/>
                    <Route path={routes.project + routes.local.group_edit} element={<GroupEdit/>}/>

                    <Route path={routes.project + routes.local.user_list} element={<UserList/>}/>
                    <Route path={routes.project + routes.local.user_edit} element={<UserEdit/>}/>
                    <Route path={routes.project + routes.local.user_profile} element={<UserProfile/>}/>
                    <Route path={routes.project + routes.local.user_contact} element={<UserContactEdit/>}/>
                    <Route path={routes.project + routes.local.user_field} element={<UserFieldEdit/>}/>
                    <Route path={routes.project + routes.local.user_page} element={<UserPageAccessEdit/>}/>
                    <Route path={routes.project + routes.local.user_utm} element={<UserUtmEdit/>}/>

                    <Route path={routes.gamification_list} element={<GameCommonList/>}/>
                    <Route path={routes.gamification_edit} element={<GameCommonEdit/>}/>

                    <Route path={routes.template} element={<TemplateItem/>}/>
                    <Route path={routes.project + routes.local.template_store} element={<TemplateStore/>}/>
                    <Route path={routes.project + routes.local.template_store_item} element={<TemplateStore/>}/>
                    <Route path={routes.project + routes.local.template_list} element={<TemplateList/>}/>
                    <Route path={routes.project + routes.local.template_edit} element={<TemplateEdit/>}/>
                    <Route path={routes.project + routes.local.template_data} element={<TemplateData/>}/>
                    <Route path={routes.project + routes.local.template_item} element={<TemplateItem/>}/>
                    <Route path={routes.project + routes.local.template_fields} element={<TemplateFields/>}/>
                    <Route path={routes.project + routes.local.template_components} element={<TemplateComponents/>}/>

                    <Route path={routes.project + routes.local.category_list} element={<ProductCategoryList/>}/>
                    <Route path={routes.project + routes.local.category_edit} element={<ProductCategoryEdit/>}/>

                    <Route path={routes.project + routes.local.product_list} element={<ProductList/>}/>
                    <Route path={routes.project + routes.local.product_edit} element={<ProductEdit/>}/>

                    <Route path={routes.project + routes.local.order_list} element={<OrderList/>}/>
                    <Route path={routes.project + routes.local.order_edit} element={<OrderEdit/>}/>

                    <Route path={routes.project + routes.local.utm_list} element={<UtmList/>}/>
                    <Route path={routes.project + routes.local.utm_edit} element={<UtmEdit/>}/>

                    <Route path={routes.project + routes.local.mail_list} element={<MailList/>}/>
                    <Route path={routes.project + routes.local.mail_edit} element={<MailEdit/>}/>

                    <Route path={routes.project + routes.local.dialog_list} element={<DialogList/>}/>
                    <Route path={routes.project + routes.local.dialog_item} element={<DialogList/>}/>

                    <Route path={routes.project + routes.local.employee_list} element={<EmployeeList/>}/>
                    <Route path={routes.project + routes.local.employee_edit} element={<EmployeeEdit/>}/>

                    <Route path={routes.project + routes.local.tag_list} element={<TagList/>}/>
                    <Route path={routes.project + routes.local.tag_edit} element={<TagEdit/>}/>

                    <Route path={routes.project + routes.local.section_list} element={<SectionList/>}/>
                    <Route path={routes.project + routes.local.section_edit} element={<SectionEdit/>}/>

                    <Route path={routes.project + routes.local.site_list} element={<SiteList/>}/>
                    <Route path={routes.project + routes.local.site_edit} element={<SiteEdit/>}/>
                    <Route path={routes.project + routes.local.domain_list} element={<DomainList/>}/>
                    <Route path={routes.project + routes.local.domain_edit} element={<DomainEdit/>}/>
                    <Route path={routes.project + routes.local.layout_list} element={<LayoutList/>}/>
                    <Route path={routes.project + routes.local.layout_edit} element={<LayoutEdit/>}/>
                    <Route path={routes.project + routes.local.theme_list} element={<ThemeList/>}/>
                    <Route path={routes.project + routes.local.theme_edit} element={<ThemeEdit/>}/>
                    <Route path={routes.project + routes.local.page_list} element={<PageList/>}/>
                    <Route path={routes.project + routes.local.page_edit} element={<PageEdit/>}/>
                    <Route path={routes.project + routes.local.page_item} element={<PageItem/>}/>

                    <Route path={routes.project + routes.local.rating} element={<UserRating/>}/>

                    <Route path='*' element={<Page404/>}/>
                </Routes>
            </Suspense>
        </ConfigProvider>
    )
}

export default App
