import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Button, Divider, Dropdown, Form, Layout, Menu, PageHeader, Tabs, Tooltip} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useHotkeys} from "react-hotkeys-hook";

import {messengers} from "../../../schemas/frontend/effects";
import {dotenv, routes} from "../../../config/config";
import {notice} from "../../../library/notice";
import Sass from 'sass.js';

import {
    is,
    toNum,
    inArray,
    deepGet,
    ucFirst,
    objectLength,
    momentFromUnix,
    unixFromMoment,
    createObjectFromObjectsArray,
    downloadFileFromText,
    deepSetToCurrent,
    jsonParseOrDef,
    getItemValues,
    deepCopyObject,
    replace,
} from "../../../library/functions";

import Fa from "../../../components/Icon/Fa";
import {useTranslation} from "react-i18next";
import {handleBlockSections, formCreateOrUpdate} from "../../../library/containers";
import {
    FormOutlined,
    LaptopOutlined,
    SettingOutlined,
    FundProjectionScreenOutlined,
    ArrowLeftOutlined,
    CheckOutlined,
    ExportOutlined,
    LinkOutlined,
    CopyOutlined,
    EllipsisOutlined,
    DeleteOutlined,
    ExperimentOutlined,
    PlusCircleOutlined,
    LoadingOutlined,
    LayoutOutlined,
    CodeOutlined,
    EyeOutlined,
    SafetyCertificateOutlined,
    BgColorsOutlined,
    DownloadOutlined,
    FileOutlined,
} from "@ant-design/icons";
import Preloader from "../../System/Preloader";
import {FormSubmit} from "../../../components/Form/FormSubmit";
import {FormFields} from "../../../components/Form/Field/FormFields";
import {setLoadingStatus} from "../../../redux/reducers/StatusReducer";
import {setLayoutItem} from "../../../redux/reducers/LayoutReducer";
import {BlockSections} from "../Block/BlockSections";
import {setPageStoreParam} from "../../../redux/reducers/PageReducer";
import {dispatchTables, handleTemplateFields, removeDeprecatedField} from "../../../library/effects";
import {handleFieldSpecValues} from "../../../library/forms";
import {isTariffDeveloper} from "../../../library/access";
import {copyToClipboard} from "../../../library/clipboard";
import ImportModal from "../../../components/Modal/ImportModal";
import {NavigateLink} from "../../../components/Navigate/Navigate";

const {Content, Header} = Layout;

// let loading = false;  // is item data loading
let pageParams = {nextId: 1};
let sendingTimeout = null;

const PageSettings = ({advanced, onAddChild, listFilters}) => {
    /*
    * advanced: boolean => is with left menu
    * listFilters - filters for short list of items (left menu)
    */

    const {t} = useTranslation()
    const section = 'page'
    const categorySection = 'site'
    const Section = ucFirst(section)

    // data from URL params
    const params = useParams()
    const id = toNum(params.id)
    const project_id = toNum(params.project_id)

    // data from GET params
    const [searchParams, setSearchParams] = useSearchParams();
    const layoutNameParam = searchParams.get('layout_name')
    const siteIdParam = toNum(searchParams.get('site_id'))
    const parentIdParam = toNum(searchParams.get('parent_id'))
    // let site_id = siteIdParam

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const store = useSelector(store => store);
    const {app, admin, project, page, site, tag, layout, integration} = store
    const item = page.item
    const project_item = project.item;
    const isDeveloper = isTariffDeveloper(project_item);

    // init states
    const [siteId, setSiteId] = useState(0);
    const [layoutName, setLayoutName] = useState(layoutNameParam || '');
    const [avatarUrl, setAvatarUrl] = useState('');
    const [ogImageUrl, setOgImageUrl] = useState('');
    const [sending, setSending] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [fileContent, setFileContent] = useState(null);
    const [isVisibleModal, setVisibleModal] = useState(false);

    let defaultTab = "form";
    const tabParam = searchParams.get('tab');
    if (tabParam) defaultTab = tabParam;

    const [tab, setTab] = useState(defaultTab);
    const [hiddenTabs, setHiddenTabs] = useState([]);
    const [screens, setScreens] = useState([]);

    // for data tabs logic
    const [dataTables, setDataTables] = useState({})
    const [removeFieldName, setRemoveFieldName] = useState('')

    const status = app.status;
    const layouts = createObjectFromObjectsArray(layout.short.list, 'name');

    const siteList = site.list || []
    const sites = createObjectFromObjectsArray(siteList);

    const tagList = tag.list || []
    const tags = createObjectFromObjectsArray(tagList);

    const defaultStyleValues = {css: ''}
    const defaultFormValues = {
        // name: cryptoRandomString({length: 10}),
        name: '',
        title: '',
        description: '',
        description_code: '',
        css: '',
        is_on: true,
        is_main: false,
        is_free: false,
        is_example: false,
        layout_name: layoutNameParam || null,
        site_id: siteIdParam ? String(siteIdParam) : null,
        parent_id: parentIdParam ? String(parentIdParam) : null,
        access: null,
        tags: [],
        params: {
            layout: {
                disabled: null,
                html: null,
                raw: null,
            },
            style: {
                light: false,
            },
            page: {
                forbidden: null,
            },
        },
        type: 'unset',
        photo_url: null,
    }

    // itis form state
    const [formValues, setFormValues] = useState(defaultFormValues)
    const [compressImg, setCompressImg] = useState(true)

    const [disableLayout, setDisableLayout] = useState(false);
    const [htmlMode, setHtmlMode] = useState(false);

    let parentsList = page.short.list;
    const form_site_id = toNum(formValues.site_id);
    if (form_site_id) parentsList = parentsList.filter(item => item.site_id === form_site_id && item.id !== id);
    else parentsList = parentsList.filter(item => item.id !== id);
    // console.log('parentsList', formValues.site_id, parentsList);
    const parents = createObjectFromObjectsArray(parentsList);

    const addStyles = (value) => {
        // add new styles to form.css value end:
        let oldValue = styleValues.css;

        if (oldValue) oldValue += '\n\n';
        else oldValue = '';

        let newValue = value;
        newValue = newValue.replace(/```(scss|css)?/g, ''); // remove ```scss or ``` or any non CSS data
        newValue = oldValue + newValue;

        setStyleValues({...styleValues, css: newValue})
        styleForm.setFieldsValue({css: newValue})
    }

    const addText = (value) => {
        let oldValue = formValues.description;
        if (oldValue) oldValue += '\n\n';
        setFormValues({...formValues, description: oldValue + value})
        form.setFieldsValue({description: oldValue + value})
    }

    // title and description
    const [baseForm] = Form.useForm();
    const baseFields = [
        {
            name: "title",
            type: "text",
            placeholder: t('common.placeholder.text'),
            // required: true,
        },
        {
            name: "description_code",
            type: "code",
            form: baseForm,
            language: 'html',
            // lineNumbers: true,
            wrapperClassName: htmlMode ? '' : 'hide',
            placeholder: '<p>Example...</p>',
        },
        {
            name: "description",
            type: "editor",
            placeholder: true,
            form: baseForm,
            formValues: formValues,
            extended: true,
            wrapperClassName: htmlMode ? 'hide' : '',
            // TODO: fix server for that (libwebp-dev or PIL.WebPImagePlugin)
            // imgFormat: 'webp',
        },
        {
            name: "generate_text",
            type: "ai",
            section: section,
            admin: admin,
            form: baseForm,
            project_id: project_id,
            formValues: formValues,
            prompt: 'Always act as TinyMCE editor assistant. Answer ONLY in HTML markup format! Wrap paragraphs with `<p>` tag.',
            onFinish: addText,
        },
        {
            name: "photo_url",
            type: "avatar",
            form: baseForm,
            imageType: 'card',
            section: section,
            admin: admin,
            project_id: project_id,
            photo_url: avatarUrl,
            label: null,
            placeholder: t('common.form.photo_url.placeholder'),
            aspect: compressImg ? 1.3333 : 0,
            wrapperClassName: 'full-width',
        },

    ];

    // system forms
    const [dataForm] = Form.useForm()
    const [dataValues, setDataValues] = useState({})
    const [dataFields, setDataFields] = useState([]);

    // block sections list
    const [blockSections, setBlockSections] = useState([]);
    const [showExamples, setShowExamples] = useState(false);

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: "name",
            type: "text",
            placeholder: t('common.form.name.placeholder'),
            // required: true,
        },
        {
            name: 'parent_id',
            type: 'menu',
            data: 'page',
            values: parents,
            placeholder: t('common.placeholder.menu'),
            isVisible: !formValues.is_main
        },
        {
            name: 'tags',
            type: 'tags',
            data: 'tag',
            values: tags,
            features: ['creating'],
            placeholder: t('common.placeholder.tags'),
        },
        {
            name: "rules",
            type: "heading",
            // desc: '',
        },
        {
            name: 'access',
            type: 'menu',
            values: ['private', 'public'],
            localized: true,
        },
        {
            name: 'params.page.forbidden',
            type: 'menu',
            data: 'page',
            values: parents,
            placeholder: t('common.placeholder.menu'),
        },
        {
            name: "additional",
            type: "heading",
        },
        {
            name: 'layout_name',
            type: 'menu',
            data: 'layout',
            values: layouts,
            placeholder: t('common.placeholder.menu'),
        },
        {
            name: 'site_id',
            type: 'menu',
            data: 'site',
            values: sites,
            placeholder: t('common.placeholder.menu'),
            required: true,
            // isVisible: !formValues.parent_id
        },
        {
            name: "is_on",
            type: "switcher",
        },
        {
            name: "is_main",
            type: "switcher",
        },
        {
            name: "is_example",
            type: "switcher",
        },
        {
            name: "is_free",
            type: "switcher",
            isVisible: isDeveloper,
        },
        {
            name: "published_at",
            type: "datetime",
            format: 'DD MMMM YYYY HH:mm',
        },
        {
            name: "expire_at",
            type: "datetime",
            format: 'DD MMMM YYYY HH:mm',
        },
        {
            name: "developer",
            type: "heading",
        },
        {
            name: 'original_size',
            type: 'switcher',
            // section: 'common',
            onChange: (value) => {
                // console.log('original_size', !value)
                setCompressImg(!value)
            },
        },
        {
            name: "params.layout.light",
            type: "switcher",
        },
        {
            name: "params.layout.disable",
            type: "switcher",
            onChange: (value) => setDisableLayout(value),
        },
        {
            name: "params.layout.html",
            type: "switcher",
            onChange: (value) => {
                setHtmlMode(value);

                // not working :(
                // if (value) {
                //     const currentDescription = baseForm.getFieldValue('description')
                //     console.log('baseForm.getFieldValue', currentDescription)
                //     setTimeout(() => {
                //         baseForm.setFieldValue('description_code', currentDescription)
                //     }, 500)
                // }
                // else {
                //     // baseForm.setFieldValue('description', baseForm.getFieldValue('description_code'))
                // }
            },
        },
        {
            name: "params.layout.raw",
            type: "switcher",
            isVisible: disableLayout && htmlMode,
        },
    ]

    // get form and set values
    const [styleForm] = Form.useForm()
    const [styleValues, setStyleValues] = useState(defaultStyleValues)
    const styleFields = [
        {
            name: "css",
            type: "code",
            form: styleForm,
            language: 'scss',
            placeholder: 'body {font-size: 16px;}',
        },
        {
            name: "generate_css",
            type: "ai",
            section: section,
            admin: admin,
            form: form,
            project_id: project_id,
            formValues: formValues,
            prompt: 'You are React page form code assistant. Answer ONLY with SCSS code to add it to form field value! Any necessary explanation write as a comments! If I asking about something else - just skip it! Newer add any other text which is not SCSS code.',
            onFinish: addStyles,
        },
    ]

    const [seoForm] = Form.useForm()
    const [seoValues, setSeoValues] = useState({
        params: {
            seo: {
                title: '',
                description: '',
                index: true,
            },
            og: {
                title: '',
                description: '',
                type: 'website',
            }
        }
    })
    const seoFields = [
        {
            name: "params.seo.title",
            type: "text",
        },
        {
            name: "params.seo.description",
            type: "textarea",
        },

        {
            name: "params.seo.index",
            type: "switcher",
        },
        // {
        //     name: "generate_text",
        //     type: "ai",
        //     section: section,
        //     admin: admin,
        //     form: form,
        //     project_id: project_item.id,
        //     formValues: formValues,
        //     prompt: 'Please always act as TinyMCE editor assistant. Answer ONLY in HTML markup format! Wrap paragraphs with `<p>` tag.',
        //     onFinish: addText,
        // },
        {
            name: "params.og.heading",
            type: "heading",
        },

        {
            name: "params.og.title",
            type: "text",
        },
        {
            name: "params.og.description",
            type: "textarea",
        },
        {
            name: "params.og.type",
            type: "menu",
            placeholder: t('common.placeholder.menu'),
            values: [
                'website',
                'article',
                'business-business',
                'article-news',
                'event',
                'article-review',
                'education-course',
                'education-exam',
                'education-lecture',
                'profile',
                'community-group',
                'product-item',
                'product-group',
                'entertainment-game',
                'book',
                'food-recipe',
                'place-restaurant',
                'place-restaurant-menu',
                'place-restaurant-menu_item',
                'profile-teacher',
                'video-movie',
                'video-episode',
                'video-tv_show',
                'video-other',
                'music-song',
                'music-album',
                'music-playlist',
            ],
            localized: true,
        },
        {
            name: "params.og.image",
            type: "avatar",
            form: seoForm,
            imageType: 'pic',
            aspect: 1.9047619,
            section: section,
            admin: admin,
            project_id: project_id,
            photo_url: ogImageUrl,
            placeholder: t('common.form.photo_url.placeholder'),
        },
    ]

    const resetAllForms = () => {
        // console.log('Reset All Form Fields', 'id', id, 'item.id', item.id);
        // console.log('defaultFormValues', defaultFormValues)

        setAvatarUrl(null);
        setOgImageUrl(null);
        setTab(defaultTab);

        const defaultSeoValues = getItemValues({}, seoFields)
        const defaultBaseValues = getItemValues({}, baseFields)

        dataForm.setFieldsValue({});
        form.setFieldsValue(defaultFormValues);
        baseForm.setFieldsValue(defaultBaseValues);
        seoForm.setFieldsValue(defaultSeoValues);
        styleForm.setFieldsValue(defaultStyleValues);

        setFormValues(defaultFormValues);
        setSeoValues(defaultSeoValues);
        setStyleValues(defaultStyleValues);

        setDataValues({});
        setBlockSections([]);
        setDataFields([]);
    }

    const setPageValues = () => {
        // console.log('Set Page Values', 'id', id, 'item.id', item.id);

        let allValues = {...item}

        let values = getItemValues(allValues, formFields)
        let baseValues = getItemValues(allValues, baseFields)

        if (!item.category_ids) values.category_ids = []
        else values.category_ids = values.category_ids.map(item => String(item))

        if (item.published_at) values.published_at = momentFromUnix(item.published_at)
        else values.published_at = ''

        if (item.expire_at) values.expire_at = momentFromUnix(values.expire_at)
        else values.expire_at = ''

        if (!is(values['tags'])) values['tags'] = [];
        else values['tags'] = values['tags'].map(item => String(item))

        if (!values['access']) values['access'] = null;
        if (!is(item['layout_name'])) values['layout_name'] = null;

        if (item['parent_id']) values['parent_id'] = String(item['parent_id']);
        else values['parent_id'] = null;
        // if (!values.parent_id && parentIdParam) values.parent_id = String(parentIdParam)

        // site id must be string for menu field
        let site_id = Number(values.site_id)
        values.site_id = site_id ? String(values.site_id) : null;
        // if (!values.site_id && siteIdParam) values.site_id = String(siteIdParam)

        if (baseValues.description) baseValues['description_code'] = baseValues.description

        // console.log('baseValues', baseValues)
        baseForm.setFieldsValue(baseValues)

        // console.log('values', values)
        form.setFieldsValue(values)

        // store all values to state
        setFormValues({...defaultFormValues, ...baseValues, ...values})

        setAvatarUrl(item.photo_url) // for upload field
        setOgImageUrl(deepGet(item, ['params', 'og', 'image']))

        setDisableLayout(deepGet(item, ['params', 'layout', 'disable'], false))
        setHtmlMode(deepGet(item, ['params', 'layout', 'html'], false))

        dataForm.setFieldsValue(values.values || {})

        let itemSeoValues = getItemValues(allValues, seoFields)
        seoForm.setFieldsValue(itemSeoValues)
        setSeoValues(itemSeoValues)

        let itemStyleValues = getItemValues(allValues, styleFields)
        styleForm.setFieldsValue(itemStyleValues)
        setStyleValues(itemStyleValues)
        compileScss(itemStyleValues.css || '');

        return {values, site_id}
    }

    const onContainersAdd = (template) => {
        pageParams.nextId = deepGet(template.content, ['nextLocalId'], 1)
        // console.log('template.items', template.content.items)

        setBlockSections(template.content.items)
        // setShowExamples(false)

        setStyleValues({...styleValues, css: template.css})
        styleForm.setFieldsValue({css: template.css})
    }

    const setLayoutValues = (place = 'unset') => {
        // console.log(
        //     'setLayoutValues', place,
        //     'item.layout', item.layout_name,
        //     'layout.name', layout.item.name,
        //     'id', id,
        //     'item.id', item.id
        // );

        // data form fields:
        let fields = deepCopyObject(layout.item.form) ?? [];
        let values = getItemValues({}, fields);

        if (item.values && objectLength(item.values)) values = {...values, ...deepCopyObject(item.values)}
        handleTemplateFields(t, item, fields, values, false, setDataTables, setRemoveFieldName)

        dataForm.setFieldsValue(values)
        setDataFields(fields)
        setDataValues(values)

        // page blocks or layout sections:
        let pageSections = [];
        let itemLayout = (layout.item.id === -1) ? [{
            type: 'row',
            items: [{
                type: 'col',
                items: []
            }]
        }] : layout.item.layout;

        // console.log('itemLayout 1', layout.item.id, itemLayout)
        if (itemLayout && itemLayout.length) {
            let pageSectionsMap = {};

            if (objectLength(item.content)) {
                let pageSectionsList = deepGet(item.content, ['items'], []);
                if (pageSectionsList.length) {
                    for (const pageSection of pageSectionsList) {
                        pageSectionsMap[String(pageSection.id)] = pageSection;

                        // support removed layouts
                        if (layout.item.id === -1) {
                            itemLayout[0].items[0].items.push({
                                id: pageSection.id,
                                spec: {editable: true},
                                type: 'sec',
                                items: []
                            });
                        }
                    }
                }

                pageParams.nextId = deepGet(item.content, ['nextLocalId'], 1)
                // setBlockSections(deepGet(item.content, ['items'], [{id: 'none', items: []}]))
            }

            // console.log('itemLayout 2', itemLayout)

            for (const sectionRow of itemLayout) {
                for (const sectionCol of (sectionRow.items || [])) {
                    for (const sectionItem of (sectionCol.items || [])) {
                        if (deepGet(sectionItem, ['spec', 'editable'], false)) {

                            const sectionId = String(sectionItem.id);
                            let pageSectionItem = {...sectionItem, items: []};

                            if (pageSectionsMap[sectionId]) {
                                pageSectionItem.items = pageSectionsMap[sectionId].items;
                            }

                            pageSections.push(pageSectionItem);
                        }
                    }
                }
            }
        }

        const hidden_tabs = deepGet(layout.item, ['params', 'hide_tabs'], []);
        if (hidden_tabs && hidden_tabs.length) setHiddenTabs(hidden_tabs)
        else setHiddenTabs([])

        // show only allowed [screens] buttons
        const allowed_screens = deepGet(layout.item, ['params', 'screens'], []);
        if (allowed_screens && allowed_screens.length) setScreens(allowed_screens)
        else setScreens([])

        // do not compress by default
        const orig_image = deepGet(layout.item, ['params', 'original_image'], false);
        const compress_image = !orig_image;

        setCompressImg(compress_image);
        form.setFieldValue('original_size', orig_image);

        // save page sections to state
        setBlockSections(pageSections);
        if (!pageSections.length) setShowExamples(true);
        // console.log('pageSections', pageSections);

        if (!tabParam) {
            if (pageSections.length) {
                // console.log('Set Tab 0: content')
                setTabParam('content')
            } else if (tab === 'content') {
                // console.log('Set Tab 1: form')
                setTabParam('form')
            }
        }
    }

    const resetLayoutValues = (place = 'unset') => {
        // console.log(
        //     'resetLayoutValues', place,
        //     'item.layout', item.layout_name,
        //     'layout.name', layout.item.name,
        //     'layoutName', layoutName
        // );

        setHiddenTabs([]);
        setDataFields([]);
        setDataValues({});
        setBlockSections([]);

        if (tab === 'content') {
            // console.log('Set Tab 3: form')
            setTabParam('form')
        }
    }

    // get item layout by query param
    useLayoutEffect(() => {
        if (!id) {
            // console.log('Change layout state by query param -', layoutNameParam)
            setLayoutName(layoutNameParam || null);
        }
    }, [layoutNameParam])

    // get data from API first
    useLayoutEffect(() => {
        // console.log('Base effect - RUN',
        //     // admin.authorized, project_id,
        //     id
        // )

        // reset layout name to defaults
        setLayoutName(layoutNameParam || '');

        // Set Loading Status
        if ((id || layoutNameParam) && id !== item.id) {
            // console.log('Set Loading Status')
            dispatch(setLoadingStatus('loading'));
            setBlockSections([]);
        }

        // get sites, layouts, tags, etc.
        if (admin.authorized && project_id) {
            // Sites list for select menu
            if (siteList.length === 0 || siteList[0].project_id !== project_id) {
                dispatch({
                    type: 'get' + ucFirst(categorySection) + 'List',
                    admin,
                    filters: {project_id: project_id}
                });
            }

            // Tags list for select menu
            if (tagList.length === 0 || tagList[0].project_id !== project_id) {
                dispatch({
                    type: 'getTagList',
                    admin,
                    filters: {project_id: project_id, site_ids: [0, form_site_id]}
                });
            }

            // Layouts list for select menu
            if (layout.short.list.length === 0 || layout.short.list[0].project_id !== project_id) {
                dispatch({type: 'getLayoutShortList', admin, filters: {project_id: project_id, ordering: 'id'}});
            }

            // Integrations list for openInApp buttons
            if (integration.list.length === 0 || integration.list[0].project_id !== project_id) {
                dispatch({
                    type: 'getIntegrationList',
                    admin,
                    filters: {
                        project_id: project_id,
                        platforms: messengers
                    }
                })
            }
        }

        // get page item
        if (admin.authorized && project_id) {
            // console.log('Base effect - GET item', 'id', id, 'item.id', item.id, 'layoutName', layoutName)

            // also reset store if item ID is unset
            dispatch({
                type: 'get' + Section + 'AndLayout',
                admin, id,
                layout: {
                    name: layoutName,
                    project_id: project_id,
                    deleted_at: 0,
                }
            });
        }

        if (!siteId && siteIdParam) {
            setSiteId(siteIdParam);
        }

        return () => {
            console.info('Item effect Return')
            // dispatch(setLayoutItem({}));

            if (!id && !layoutNameParam) {
                // console.log('Set Ready Status 1: Item effect Return')
                dispatch(setLoadingStatus('ready'));
            }

            setShowExamples(false);
        }

    }, [admin.authorized, project_id, siteIdParam, id])

    useEffect(() => {
        if (admin.authorized && project_id && (id === 0 || (status === 'ready' && id === item.id))) {
           // console.log('Get new layout:', 'id', id, 'status', status, 'layoutName', layoutName)

            // do not run next line when page just open without layout param:  layoutName === ''
            if (layoutName === null) {
                // console.log('Base effect 2 - RESET layout', layoutName)
                dispatch(setLayoutItem({}));
            }

            if (layoutName && layoutName !== layout.item.name) {
                // console.log('Base effect 2 - GET layout', layoutName, 'id', id, 'item.id', item.id)
                dispatch({
                    type: 'getLayoutByFilters',
                    admin,
                    filters: {
                        name: layoutName,
                        project_id: project_id,
                        deleted_at: 0,
                    }
                });
            }
        }
    }, [admin.authorized && project_id, layoutName])

    // set values to FORM if correct data received
    useEffect(() => {
        let localId = id;
        console.log('Item effect RUN:', 'id', id, 'item.id', item.id, 'advanced', advanced, 'to end', page.addItemToEnd)

        if (advanced && page.addItemToEnd && item.id && (!id || id !== item.id)) {
            // console.log('Redirect to new item', id, item.id)

            // reset create mode to default
            dispatch(setPageStoreParam({addItemToEnd: false}));

            // replaceToItem(item.id);  // do it in the end of useEffect
            localId = item.id  // reset after first page change
        }

        if (item.id && item.id === localId) {
            // console.log('Item effect SET', localId, item.id);

            const {values, site_id} = setPageValues()

            // set page fields and blocks by layout data
            if (values.layout_name && values.layout_name === layout.item.name) setLayoutValues('page')
            else resetLayoutValues('page')

            // not necessary and not clear whether it's usefully
            setLayoutName(values.layout_name ?? null)

            // get pages by site if needed
            if (site_id && (parentsList.length === 0 || parentsList[0].site_id !== site_id)) {
                // console.log('PAGES - Get pages list - PAGE ITEM')
                setSiteId(site_id)
            }
        }
        // visual divider
        else if (!localId) {
            resetAllForms();

            if (layoutName === (layout.item.name || '')) setLayoutValues('notPage')
            else if (!layoutName) resetLayoutValues('notPage')
            // else ... wait for layout.item receiving & handle by useEffect
        }

        // console.log(
        //     'Item effect Finish',
        //     'id', id,
        //     'localId', localId,
        //     'pageId', pageId,
        //     'item.id', item.id,
        //     item.layout_name,
        //     layoutName
        // )

        if (localId !== id) replaceToItem(localId);

        // console.log('Set Ready Status 2 - Page handler Finish')
        dispatch(setLoadingStatus('ready'))

        setSending(false)
        if (sendingTimeout) clearTimeout(sendingTimeout);

    }, [`${item.id}-${item.updated_at}`])

    useEffect(() => {
        if (id && status === 'ready') {
            // console.log('reset form parent', item.parent_id)
            form.setFieldValue('parent_id', item.parent_id ? String(item.parent_id) : null)
        }
    }, [item.parent_id])

    useEffect(() => {
        // console.info('Layout effect RUN',
        //     '. State:', layoutName,
        //     '. Page:', item.layout_name,
        //     '. Layout:', layout.item.name
        // )

        // crutch for layout not found
        if (id && layout.item.id === -1) {
            // console.log('Set Ready Status 3 - Layout effect')
            setLayoutValues('layout')
            dispatch(setLoadingStatus('ready'));
        }

        // normal case
        else if (layoutName === (layout.item.name || '')) {
            if (!id || status === 'ready') {
                if (layout.item.name) setLayoutValues('layout')
                else resetLayoutValues('layout')
            }

            if (status === 'loading') {
                // console.log('Set Ready Status 3 - Layout effect')
                dispatch(setLoadingStatus('ready'));
            }
        }

        // do not run next line when page just open without layout param:  layoutName === ''
        else if (layoutName === null) {
            resetLayoutValues('notLayout')
        }

    }, [layout.item.name])

    // get pages list by site id
    useEffect(() => {
        // console.log('PAGES - get pages list by site id 1', siteId)
        if (!advanced && admin.authorized) {
            if (siteId && !page.short.list.length) {
                // console.log('PAGES - pages list by site id 2', siteId)
                dispatch({
                    type: 'get' + Section + 'ShortList', admin, filters: {
                        project_id: project_id,
                        site_id: siteId,
                        ...listFilters
                    }
                });
            }
        }
    }, [admin.authorized, siteId])

    // set data fields values lists when correct data received
    useEffect(() => {
        // console.log('Dispatch data tables:', dataTables, dataFields)
        dispatchTables(dispatch, admin, project_id, dataTables, siteId, store)
        //eslint-disable-next-line
    }, [store, siteId, objectLength(dataTables)])

    // handle form field deletion
    useEffect(() => {
        removeDeprecatedField(dataFields, setDataFields, dataValues, setDataValues, removeFieldName, setRemoveFieldName)
    }, [removeFieldName])

    const setTabParam = (tab) => {
        const currentSearchParams = Object.fromEntries(searchParams.entries());
        setSearchParams({...currentSearchParams, tab: tab})
    }

    // change tab on url change
    useEffect(() => {
        // open tab by history navigation:
        if (tabParam && tab !== tabParam) {
            // console.log('Set Tab State:', 'tab', tab, 'tabParam', tabParam)
            setTab(tabParam)
        }
        //eslint-disable-next-line
    }, [tabParam])

    // set page content css from settings field value
    const [pageCss, setPageCss] = useState('')
    useEffect(() => {
        removeCss();
        applyCss(pageCss);
        return () => {
            removeCss();
        }
        //eslint-disable-next-line
    }, [pageCss])

    useEffect(() => {
        setShowExamples(!!blockSections.length)
    }, [blockSections])

    useHotkeys(['ctrl+s', 'command+s'].join(','), e => {
        e.preventDefault();
        // console.log('useHotkeys', e.code);

        // run key code actions
        switch (e.code) {
            case 'KeyS':
                onFinish(false);
                break;

            default:
                break;
        }
    }, [item, form, dataForm, styleForm, seoForm, blockSections]);

    const onStyleChange = (values) => {
        compileScss(values.css);
    }

    const compileScss = (scss) => {
        const cssText = '.p-root { ' + String(scss) + ' }';
        Sass.compile(cssText, result => {
            if (result.status === 0) {
                setPageCss(result.text);
            } else {
                console.error(result.formatted); // Handle compilation error
            }
        });
    };

    const removeCss = () => {
        const styleElement = document.getElementById('page-style');
        if (styleElement) styleElement.remove();
    }

    const applyCss = (css) => {
        const styleElement = document.createElement('style');
        styleElement.setAttribute('id', 'page-style');
        styleElement.innerHTML = css;
        document.head.appendChild(styleElement);
    };

    // const onBaseChange = (changedValues) => {
    //     // not working :(
    //     if (changedValues.description !== undefined) {
    //         const description = changedValues.description;
    //         console.log('description', description)
    //         baseForm.setFieldValue('description_code', description);
    //         console.log('baseForm', baseForm.getFieldValue('description_code'))
    //     } else if (changedValues.description_code !== undefined) {
    //         const description_code = changedValues.description_code;
    //         baseForm.setFieldValue('description', description_code);
    //     }
    // }

    const onValuesChange = (changedValues) => {
        const changedKeys = Object.keys(changedValues);
        if (inArray('layout_name', changedKeys)) {
            // console.log('changedValues.layout_name', changedValues.layout_name)
            setLayoutName(changedValues.layout_name ?? null);
        }

        // console.log('layout_name', layout.item.name, changedValues.layout_name);
        // console.log('onValuesChange', changedValues.layout_name, changedValues);
    }

    // compose form functions
    const backToSites = () => {
        navigate(`${routes.project_list}/${project_id}/site?folder=auto`);
    }

    const addSiteIdToUrl = (url, extended = true) => {
        if (layoutNameParam) url += `&layout_name=${layoutNameParam}`
        if (siteIdParam) url += `&site_id=${siteIdParam}`
        else if (extended) {
            if (page.short.list.length > 0) url += `&site_id=${page.short.list[0].site_id}`
            else if (item.site_id) url += `&site_id=${item.site_id}`
        }
        return url
    }

    const backToList = () => {
        let url = `${routes.project_list}/${project_id}/${section}?`

        url = addSiteIdToUrl(url, false)
        navigate(url);
    }

    const replaceToItem = (itemId) => {
        // console.log('replaceToItem', itemId, siteId)
        let url = `${routes.project_list}/${project_id}/site/${section}/${itemId}?`

        url = addSiteIdToUrl(url)
        replace(url);
    }

    const goToItem = (itemId = '-1', parentId = 0) => {
        // console.log('goToItem', itemId, siteId)

        if (typeof itemId !== 'string' && typeof itemId !== 'number') itemId = '-1';
        let url = `${routes.project_list}/${project_id}/site/${section}/${itemId}?`

        if (!itemId || itemId === '0') url += 'tab=form'
        if (parentId) url += `&parent_id=${parentId}`

        url = addSiteIdToUrl(url)
        navigate(url);
    }

    const composeItem = () => {
        let values = {...baseForm.getFieldsValue(), ...form.getFieldsValue()}

        if (htmlMode) {
            values['description'] = values['description_code']
            delete values['description_code']
        }

        if (values.published_at) values.published_at = unixFromMoment(values.published_at)
        else values.published_at = null

        if (values.expire_at) values.expire_at = unixFromMoment(values.expire_at)
        else values.expire_at = 0

        if (!is(values.parent_id)) values.parent_id = null
        if (!is(values.layout_name)) values.layout_name = null

        values.site_id = toNum(values.site_id);
        values['access'] = values['access'] || "";
        values['values'] = dataForm.getFieldsValue();

        const seoValues = seoForm.getFieldsValue();
        if (objectLength(seoValues)) values['params'] = {...values.params, ...seoValues.params};

        if (deepGet(values, 'params.page.forbidden') === undefined) {
            deepSetToCurrent(values, 'params.page.forbidden', null)
        }

        // console.log('params.page.forbidden', deepGet(values, 'params.page.forbidden'))

        const styleValues = styleForm.getFieldsValue();
        if (objectLength(styleValues)) values = {...values, ...styleValues};

        values['content'] = {
            'nextLocalId': pageParams.nextId,
            'items': handleBlockSections(blockSections)
        }

        return values;
    }

    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (back = true) => {
        setSending(true);

        // reset sending if no response in 30 seconds
        if (sendingTimeout) clearTimeout(sendingTimeout);
        sendingTimeout = setTimeout(() => setSending(false), 30000);

        const values = composeItem();

        // if (!values.title) {
        //     notice.error(t('error.validation.fill_required'));
        //     baseForm.setFields([{name: 'title', errors: [t('error.validation.required')]}]);
        //     if (tab !== 'form') setTabParam('form');
        //     return;
        // }

        if (!values.site_id) {
            notice.error(t('error.validation.fill_required'));
            form.setFields([{name: 'site_id', errors: [t('error.validation.required')]}]);
            if (tab !== 'settings') setTabParam('settings');
            return;
        }

        if (advanced && !id) {
            dispatch(setPageStoreParam({addItemToEnd: true}));
        }

        // console.log('onFinish values', values);
        formCreateOrUpdate(Section, values, formFields, id, project_item, admin, dispatch, true, !back)
        if (back) backToList();
        else console.log('Page Values', values.content)
    }

    const downloadItem = () => {
        const values = composeItem();
        const exportFields = [
            // 'id',
            // 'name',
            // 'site_id',
            // 'expire_at',
            // 'parent_id',
            // 'project_id',
            // 'is_main',
            // 'published_at',
            'layout_name',
            'title',
            'description',
            'content',
            'values',
            'css',
            'is_example',
            'is_on',
            'params',
            'access',
            'photo_url',
            'tags',
        ]

        let itemData = {};
        for (const field of exportFields) {
            itemData[field] = values[field];
        }

        const itemDataJson = JSON.stringify(itemData);
        const fileName = dotenv.app_name + '-' + section + '-' + item.id + '-' + Date.now();
        downloadFileFromText(itemDataJson, fileName, 'json');
    }

    const renderFormFields = () => {
        let dataFieldsResult = [];
        for (const fieldInit of dataFields) {
            dataFieldsResult.push(handleFieldSpecValues(t, store, fieldInit));
        }

        return (<>
            <Form
                key={'base'}
                form={baseForm}
                // onValuesChange={onBaseChange}
                onFinish={() => onFinish(!advanced)}
                onFinishFailed={onFailed}
                name={section + '_base'}
                className="margin-top-pm"
                layout="vertical"
            >
                <FormFields t={t} admin={admin} section={section} fields={baseFields} formValues={formValues}/>
            </Form>
            <Form
                key={'fields'}
                form={dataForm}
                onFinish={onFinish}
                onFinishFailed={onFailed}
                name={section + '_fields'}
                className=""
                layout="vertical"
            >
                <FormFields
                    t={t}
                    admin={admin}
                    section={section}
                    project_id={project_id}
                    fields={dataFieldsResult}
                    formValues={dataValues}
                />
            </Form>
        </>);
    }

    const getNewBlockId = () => {
        const resultId = pageParams.nextId;
        pageParams.nextId = resultId + 1;
        return resultId;
    }

    const renderContent = () => {
        return <BlockSections
            id={id}
            pageItem={item}
            list={blockSections}
            setList={setBlockSections}
            layoutName={layoutName}
            showExamples={showExamples && blockSections.length === 1 && !blockSections[0].items.length}
            getNewId={getNewBlockId}
            onContainersAdd={onContainersAdd}
            savePage={() => onFinish(false)}
            isOn={tab === 'content'}  // for calculate width for block controls in cols component
            screens={screens}
        />;
    }

    const renderSettings = () => {
        return (<Form
            form={form}
            initialValues={formValues}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            onValuesChange={onValuesChange}
            name={section + '_settings'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} section={section} fields={formFields} formValues={formValues}/>
        </Form>);
    }

    const renderSeo = () => {
        return (<Form
            form={seoForm}
            initialValues={seoValues}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            name={section + '_seo'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} section={section} fields={seoFields} formValues={seoValues}/>
        </Form>);
    }

    const renderStyles = () => {
        return (<Form
            form={styleForm}
            initialValues={styleValues}
            onValuesChange={onStyleChange}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            name={section + '_style'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} section={section} fields={styleFields} formValues={styleValues}/>
        </Form>);
    }

    const renderOpenButton = () => {
        if (!item.id) return null;

        const siteItem = form_site_id ? siteList.find(item => item.id === form_site_id) : null;
        const pagePath = is(item.path) ? '/' + item.path.join('/') : '/'
        const domain = deepGet(siteItem, 'domain', false);
        const itemUrl = domain ? dotenv.sites_proto + domain + pagePath : null;

        const items = [
            {
                key: 'path',
                icon: <LinkOutlined className="margin-left-xs margin-right-xp"/>,
                label: t(section + '.object.menu.path'),
                onClick: () => copyToClipboard(pagePath)
            },
            {
                key: 'url',
                icon: <CopyOutlined className="margin-left-xs margin-right-xp"/>,
                label: t(section + '.object.menu.url'),
                onClick: () => copyToClipboard(itemUrl)
            },
            {
                key: 'id',
                icon: <CodeOutlined className="margin-left-xs margin-right-xp"/>,
                label: t(section + '.object.menu.id'),
                onClick: () => copyToClipboard(id)
            },
        ];

        if (itemUrl && integration.list && integration.list.length) {
            const set = [];
            for (const integrationItem of integration.list) {
                const integration = deepGet(integrationItem, 'integration');
                const platform = deepGet(integration, 'platform');
                if (platform === 'tg' && !set.includes('tg')) {
                    set.push('tg');
                    items.push({
                        key: 'tg',
                        icon: <Fa
                            icon="telegram"
                            set="brands"
                            antd={true}
                            color="#0088cc"
                            className="margin-left-xs margin-right-xp"
                        />,
                        label: t(section + '.object.menu.tg'),
                        onClick: () => {
                            const intId = String(deepGet(integration, 'id', '0'));
                            return copyToClipboard(itemUrl + `?tg-mini-app={var:graph.integration.id|${intId}}`);
                        }
                    })
                }
                if (platform === 'vk' && !set.includes('vk')) {
                    const itemParams = jsonParseOrDef(deepGet(integration, 'params', '{}'), {});
                    if (itemParams.site_id && toNum(itemParams.site_id) === form_site_id) {
                        let botId = deepGet(integrationItem, 'integration.unique_hash');
                        if (botId) botId = '_-' + botId;

                        let pagePathInApp = pagePath.substring(1);
                        if (pagePathInApp) pagePathInApp = '#' + pagePathInApp;

                        const vkUrl = `https://vk.com/app51842111${botId}${pagePathInApp}`;

                        set.push('vk');
                        items.push({
                            key: 'vk',
                            icon: <Fa
                                icon="vk"
                                set="brands"
                                antd={true}
                                color="#4a76a8"
                                className="margin-left-xs margin-right-xp"
                            />,
                            label: <>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={vkUrl}
                                    className="allow-extra-icon"
                                >{t(section + '.object.menu.vk')}</a>
                                <span
                                    children={<CopyOutlined/>}
                                    className="float-right text-secondary cursor-pointer margin-right-xxs"
                                    onClick={() => copyToClipboard(vkUrl)}
                                    title={t('common.action.copy')}
                                />
                            </>
                        })
                    }
                }

                // all platforms were added
                if (set.length === 2) break;
            }
        }

        items.push({
            key: 'open',
            icon: <ExportOutlined className="margin-left-xs margin-right-xp"/>,
            label: <a
                target="_blank"
                rel="noopener noreferrer"
                href={itemUrl}
            ><span className="visible-lg">{t(section + '.object.menu.open')}</span></a>
        })

        if (!domain) {
            return <Tooltip key="pob1" title={t(section + '.list.icon.path')} placement="bottomRight">
                <Button
                    key="page-open-button-1"
                    className="margin-right-xs"
                    icon={<LinkOutlined/>}
                    onClick={() => copyToClipboard(pagePath)}
                    size="large"
                    type="text"
                />
            </Tooltip>
        } else if (items) {
            return <>

                <Dropdown
                    key="pob2"
                    menu={{items}}
                    trigger="click"
                    placement="bottomRight"
                    // className="visible-lg"
                    getPopupContainer={() => document.getElementById('app-page-wrapper')}
                >
                    <Button
                        title={t(section + '.object.menu.tooltip')}
                        key="page-open-button-2"
                        icon={<ExportOutlined/>}
                        className="margin-right-xs padding-right-none"
                        href={itemUrl}
                        onClick={(e) => e.preventDefault()}
                        target="_blank"
                        type="text"
                    ><span className="hidden-sm-important">{t('common.action.share')}</span></Button>
                </Dropdown>

                <Button
                    key="preview-button"
                    className="padding-left-none"
                    type="text"
                    icon={<EyeOutlined/>}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={itemUrl}
                >
                    <span className="hidden-sm-important">{t(section + '.object.menu.preview')}</span>
                </Button>
            </>
        } else {
            return <Tooltip key="pob3" title={t(section + '.list.icon.open')} placement="bottomRight">
                <Button
                    key="page-open-button-3"
                    icon={<ExportOutlined/>}
                    className="margin-right-xs"
                    href={itemUrl}
                    target="_blank"
                    type="text"
                />
            </Tooltip>
        }
    }

    const recordCopy = (id) => {
        dispatch(setPageStoreParam({addItemToEnd: true}));
        dispatch({type: 'copy' + Section, admin, id})
    }

    const recordRestore = () => {
        // dispatch(setPageStoreParam({addItemToEnd: true}));
        const params = {id: id, project_id: project_id}
        dispatch({type: 'restore' + Section, admin, insertMode: true, data: params})
    }

    const recordDelete = () => {
        dispatch({type: 'delete' + Section, admin, id, project_id: project_id})
        goToItem('-1')
    }

    const recordCreate = (data = null) => {
        if (!siteId) {
            notice.error(t(section + '.error.import_site_id'));
            return;
        }

        if (id) {
            notice.error(t('common.import.error.import_form'));
            return;
        }

        if (!data || typeof data !== 'object') {
            notice.info(t('common.import.error.empty'));
            return;
        }

        data['project_id'] = project_item.id;
        data['site_id'] = siteId;
        data['name'] = null;  // allow import one item many times
        // data['is_on'] = false;

        if (advanced) dispatch(setPageStoreParam({addItemToEnd: true}));
        dispatch({type: 'create' + Section, admin, data});
    }

    const recordImport = () => {
        if (!fileContent) {
            notice.info(t('common.import.error.empty'));
            return;
        }
        try {
            const data = JSON.parse(fileContent);
            if (inArray(undefined, [data.layout_name, data.title, data.params])) {
                notice.error(t('common.import.error.content'));
                return;
            }

            // console.log('Import Content', data);
            recordCreate(data);
        } catch (e) {
            console.log('Import data parse error', e);
            notice.error(t('common.import.error.format'));
        }
    };

    const makeSiteLink = () => {
        return `${routes.project_list}/${project_id}/site/edit/${siteId}?back_to_page=${id}`;
    }

    const makeLayoutLink = () => {
        let url = `${routes.project_list}/${project_id}/layout/edit/${layout.item.id}?back_to_page=${id}`;
        if (siteId) url += `&data_site_id=${siteId}`;
        return url;
    }

    const makeThemesLink = () => {
        return `${routes.project_list}/${project_id}${routes.local.theme_list}?folder=auto`;
    }

    const makeDomainsLink = () => {
        return `${routes.project_list}/${project_id}${routes.local.domain_list}?folder=auto&site_id=${siteId}`
    }

    const onMenuClick = (e) => {
        if (e.key === 'delete') recordDelete()
        else if (e.key === 'restore') recordRestore()
        else if (e.key === 'copy') recordCopy(id)
        else if (e.key === 'child') onAddChild(0, id)
        // else if (e.key === 'layout') openLayout()
        // else if (e.key === 'themes') openThemes()
        // else if (e.key === 'site') openSite()
        // else if (e.key === 'domains') openDomainsList(siteId)
        else if (e.key === 'export') downloadItem()
        else if (e.key === 'import') {
            setVisibleModal(true);
            setFileList([]);
            setFileContent('');
            navigate(`${routes.project_list}/${project_id}${routes.local.site_list}/${section}/0?site_id=${siteId}`);
        }
    }

    const itemMenu = (<Menu
            onClick={onMenuClick}
            items={[
                item.id ? {
                    key: 'child',
                    label: t(section + '.item.menu.child'),
                    icon: <PlusCircleOutlined/>,
                } : null,
                layout.item.id ? {
                    key: 'layout',
                    label: <NavigateLink link={makeLayoutLink()}>{t(section + '.item.menu.layout')}</NavigateLink>,
                    icon: <LayoutOutlined/>,
                } : null,
                siteId ? {
                    key: 'site',
                    label: <NavigateLink link={makeSiteLink()}>{t(section + '.item.menu.site')}</NavigateLink>,
                    icon: <SettingOutlined/>,
                } : null,
                {
                    key: 'themes',
                    label: <NavigateLink link={makeThemesLink()}>{t(section + '.item.menu.themes')}</NavigateLink>,
                    icon: <BgColorsOutlined/>,
                },
                siteId ? {
                    key: 'domains',
                    label: <NavigateLink link={makeDomainsLink()}>{t(section + '.item.menu.domains')}</NavigateLink>,
                    icon: <SafetyCertificateOutlined/>,
                } : null,
                {
                    key: 'export',
                    label: t(section + '.item.menu.export'),
                    icon: <DownloadOutlined/>,
                },
                {
                    key: 'import',
                    label: t('common.action.import'),
                    icon: <FileOutlined />,
                },

                item.id ? {
                    key: 'copy',
                    label: t('common.action.duplicate'),
                    icon: <CopyOutlined/>,
                } : null,

                item.id ? {
                    key: 'divider',
                    type: 'divider',
                    render: (record) => <Divider className="margin-none-vertical" type="horizontal"/>,
                    action: (record) => null
                } : null,
                item.id ? (item && item.deleted_at ? {
                    key: 'restore',
                    label: t(section + '.item.menu.restore'),
                    icon: <ExperimentOutlined/>,
                } : {
                    key: 'delete',
                    label: t(section + '.item.menu.delete'),
                    icon: <DeleteOutlined/>,
                }) : null
            ]}
        />
    );

    return (
        <>
            <Header className="site-layout-background page-container-horizontal-padding has-banner">
                <PageHeader
                    key="page-settings-header"
                    title={status === 'loading' ? <LoadingOutlined/> : <>
                        <span className="visible-sm">{item.id ? '#' + item.id : t('common.action.create')}</span>
                        <span className="hidden-sm">
                            {(item.id ? t('common.action.edit') : t('common.action.add')) + ' ' + t(section + '.object.title')}
                        </span>
                    </>}
                    className="padding-none-horizontal"
                    extra={[
                        // back
                        <Button
                            key="return-button"
                            className={`${advanced ? 'hidden-sm' : ''}${id ? ' padding-right-none padding-left-none' : ''}`}
                            type="text"
                            icon={<ArrowLeftOutlined/>}
                            onClick={advanced ? backToSites : backToList}
                        >
                            {advanced ? t(section + '.item.menu.back') : t('common.action.back')}
                        </Button>,
                        advanced ? <Button
                            key="return-button-adv"
                            className={`visible-sm${id ? ' padding-right-none padding-left-none' : ''}`}
                            type="text"
                            icon={<ArrowLeftOutlined/>}
                            onClick={goToItem}
                        >
                            {t('common.action.back')}
                        </Button> : null,

                        renderOpenButton(),

                        advanced ?
                            <Button
                                key="primary-button-adv"
                                type="primary"
                                htmlType="submit"
                                loading={sending}
                                icon={<CheckOutlined/>}
                                onClick={() => {
                                    onFinish(false);
                                }}
                            >
                                <span className="hidden-sm-important">{t('common.action.save')}</span>
                            </Button> :
                            <Button.Group key="primary-set" className="rounded-items-sm">
                                {id ? <Button
                                    key="save-button"
                                    type="primary"
                                    loading={sending}
                                    icon={<CheckOutlined/>}
                                    onClick={() => {
                                        onFinish(false);
                                    }}
                                /> : null}

                                <Button
                                    key="primary-button"
                                    type="primary"
                                    htmlType="submit"
                                    className="hidden-sm ant-btn-hide-loading-icon"
                                    loading={sending}
                                    onClick={onFinish}
                                >
                                    <span className="hidden-sm-important">{t('common.action.save')}</span>
                                </Button>
                            </Button.Group>,
                        advanced ? <Dropdown
                            key="header-primary-menu"
                            className="btn-default-gray"
                            trigger="click"
                            // type="primary"
                            overlay={itemMenu}><Button icon={<EllipsisOutlined/>}/></Dropdown> : null,
                    ]}
                />
            </Header>

            <Content
                id="app-page-wrapper"
                key="content"
                className="page-container form-container site-layout-background padding-top-none padding-bottom-ps"
            >
                {status === 'loading' ? <Preloader/> : null}
                <div className={status === 'loading' ? 'hide' : 'item-content'}>
                    <Tabs
                        activeKey={tab}
                        onChange={setTabParam}
                        size="medium"
                        // className="margin-bottom-sm"
                        items={[
                            blockSections.length === 0 ? null : {
                                label: <span>
                                        <LaptopOutlined/>
                                        <span className="hidden-xs">{t(section + '.tab.content')}</span>
                                    </span>,
                                key: 'content',
                            },
                            {
                                label: <span>
                                        <FormOutlined/>
                                        <span className="hidden-xs">{t(section + '.tab.form')}</span>
                                    </span>,
                                key: 'form',
                            },
                            {
                                label: <span>
                                        <SettingOutlined/>
                                        <span className="hidden-xs">{t(section + '.tab.settings')}</span>
                                    </span>,
                                key: 'settings',
                            },
                            inArray('seo', hiddenTabs) ? null : {
                                label: <span>
                                        <FundProjectionScreenOutlined/>
                                        <span className="hidden-xs">{t(section + '.tab.seo')}</span>
                                    </span>,
                                key: 'seo',
                            },
                            inArray('style', hiddenTabs) ? null : {
                                label: <span>
                                        <BgColorsOutlined/>
                                        <span className="hidden-xs">{t(section + '.tab.style')}</span>
                                    </span>,
                                key: 'style',
                            },
                        ]}
                    />

                    <div className={tab === 'form' ? null : 'hide'}>{renderFormFields()}</div>
                    <div className={tab === 'content' ? null : 'hide'}>{renderContent()}</div>
                    <div className={tab === 'settings' ? null : 'hide'}>{renderSettings()}</div>
                    <div className={tab === 'seo' ? null : 'hide'}>{renderSeo()}</div>
                    <div className={tab === 'style' ? null : 'hide'}>{renderStyles()}</div>

                    <FormSubmit
                        loading={sending}
                        onSubmit={() => onFinish(!advanced)}
                        onCancel={advanced ? backToSites : backToList}
                        className="margin-top-pm"
                    />
                </div>

                <ImportModal
                    t={t}
                    onOk={recordImport}
                    section="common"
                    isVisibleModal={isVisibleModal}
                    setVisibleModal={setVisibleModal}
                    fileList={fileList}
                    setFileList={setFileList}
                    fileContent={fileContent}
                    setFileContent={setFileContent}
                    fileFormat="json"
                />
            </Content>
        </>
    )
}

export default PageSettings