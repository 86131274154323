import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {l} from "../../library/locale";
import {field_values, routes} from "../../config/config";
import {getItemValues} from "../../library/functions";
import cryptoRandomString from "crypto-random-string";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate, dispatchItemWithFolders} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";

const FieldEdit = () => {
    const section = 'field'
    const adminSection = 'field'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // data from GET params
    const [searchParams] = useSearchParams();
    let folder_id = Number(searchParams.get('folder')) // for fast create in folder

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, field, folder} = useSelector(store => store)
    const item = field.item
    const project_item = project.item

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: "title",
            type: "text",
            required: true,
        },
        {
            name: "name",
            type: "text",
            required: true,
            placeholder: true,
        },
        {
            type: "text",
            name: "default_value",
            placeholder: true,
        },
        {
            name: "data_type_admin",
            type: "menu",
            values: field_values.data_type,
        },
        {
            name: "read_access",
            type: "menu",
            values: field_values.read_access,
        },
        {
            name: "system_field_type",
            type: "menu",
            values: [
                "crm",
                "form"
            ],
        },
        {
            name: "icon_name",
            placeholder: '',
            type: "icon"
        },
        {
            name: "folder_id",
            type: "folder",
            folder_list: folder.list,
            desc: l('common.form.folder.desc'),
        },
        // {name: "photo_url", type: "avatar"},
    ]

    // itis form state
    const [folderId, setFolderId] = useState(0) // for backToList url
    const [formValues, setFormValues] = useState({
        name: cryptoRandomString({length: 15}),
        data_type_admin: 'text',
        system_field_type: 'crm',
        read_access: field_values.read_access[0],
        icon_name: 'user-plus',
        folder_id: folder_id,
    })

    // get data from API first
    useLayoutEffect(() => {
        dispatchItemWithFolders(dispatch, admin, project_item, section, adminSection, id)
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id])

    // set field values to STATE if correct data received
    useEffect(() => {
        if (item) {
            const formValuesNew = getItemValues(item, formFields)
            setFormValues(formValuesNew)

            if (formValuesNew.folder_id !== undefined) {
                setFolderId(formValuesNew.folder_id) // for backToList url
            }
        }
        //eslint-disable-next-line
    }, [item])

    // set field values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            form.setFieldsValue(formValues)
            setFolderId(formValues.folder_id) // for backToList url
        }
        //eslint-disable-next-line
    }, [item, formValues])

    // compose form functions
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        if (values.data_type_admin === 'float' && values.default_value === '') values.default_value = 0
        formCreateOrUpdate('Field', values, formFields, id, project_item, admin, dispatch)
        backToList(parseInt(values.folder_id))
    }
    const backToList = (folder_id = false) => {
        if (folder_id === false || typeof folder_id !== 'number') folder_id = folderId
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}?folder=${folder_id}`);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={adminSection} // for labels generation
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default FieldEdit