import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button} from 'antd';
import {useDispatch} from "react-redux";
import {routes} from "../../config/config";
import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import {DeleteOutlined} from "@ant-design/icons";
import ListTable from "../../components/List/Table/ListTable";
import {TableAvatar} from "../../components/List/Table/TableAvatar";
import {useTranslation} from "react-i18next";

export const IntegrationListTable = (
    {
        admin,
        project,
        list,
        filters,
    }
) => {
    const {t} = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // get state
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)

    // navigate
    const recordEdit = (record) => navigate(`${routes.project_list}/${project.id}${routes.local.integration_list}/edit/${record.id}?platform=${record.platform}`)
    const recordOpen = (record) => recordEdit(record)
    // saga
    // const recordCopy = (id) => dispatch({type: 'copyGraph', admin, id})
    const recordDelete = (id) => dispatch({type: 'deleteIntegration', admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restoreIntegration', admin, data: params})
    }

    const columns = [
        {
            title: t('common.form.status'),
            dataIndex: 'icon',
            className: 'cursor-pointer avatar',
            width: 70,
            render: (icon, record) => <TableAvatar icon={record.status?'plug':'plug-circle-xmark'} isOn={record.status}/>,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title',
            title: t('common.form.name.label'),
            className: 'cursor-pointer table-row-title title-link',
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuEdit',
            label: t('table.menu.edit'),
            action: (record) => recordEdit(record)
        },
        {
            key: 'tableMenuDelete',
            label: t('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: t('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        data.push({
            id: record.integration.id,
            platform: record.integration.platform,
            key: record.integration.id + '-listRow',
            title: record.title,
            status: record.is_on,
            deleted: record.deleted_at > 0,
        });
    }

    // ==== Selection ====

    const deleteSelected = () => {
        for (const projectKey of selectedRowKeys) recordDelete(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const restoreSelected = () => {
        for (const projectKey of selectedRowKeys) recordRestore(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // ==== Render ====

    return (
        <div>
            <ListTable
                showHeader={true}
                pagination={false}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
            />
            <div className={isCheckboxButtons ? "button-flying" : "hide"}>
                <Button
                    className={filters.is_deleted ? "hide" : ""}
                    onClick={deleteSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.delete')}</Button>
                <Button
                    className={filters.is_deleted ? "" : "hide"}
                    onClick={restoreSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.restore')}</Button>
            </div>
        </div>
    )
}

